
//        INVOICE DEFAULT VALUES
export const CONST_INVOICE_NUMBER = "INV";
export const CONST_ESTIMATE_NUMBER = "EST";

//        DOCUMENT TYPES
export const DOCUMENT_INVOICE = "INVOICE";
export const DOCUMENT_ESTIMATE = "ESTIMATE";

//        USER
export const TABLE_NAME_USER = "uTbl";
export const USER_ID = "uId";
export const USER_DOCUMENT_TYPE = "uDoTy";
export const USER_DOCUMENT_ID = "uDoId";
export const USER_ITEM_ID = "uItId";
export const USER_PAYMENTITEM_ID = "uPaItId";
export const USER_PHOTO_ID = "uPhId";
export const USER_CUSTOM_ITEM_ID = "uCuItId";
export const USER_CUSTOM_ITEM_TRUE_FALSE = "uIsCuIt";
export const USER_CUSTOM_CONTACT_ID = "uCuCtId";
export const USER_CUSTOM_CONTACT_TRUE_FALSE = "uIsCuCt";

//        SETTINGS
export const TABLE_NAME_SETTINGS = "seTbl";
export const COLUMN_NAME_SETTINGS_COPYEMAILS = "seCoEm";
export const COLUMN_NAME_SETTINGS_LIVESEARCH = "seLiSe";
export const COLUMN_NAME_SETTINGS_AUTOSETDUEDATE = "seAuDa";
export const COLUMN_NAME_SETTINGS_AUTOSETDUEDATEVALUE = "seAuDaVa";
export const COLUMN_NAME_SETTINGS_AUTOSENDREMINDEREMAIL = "seAuSeReEm";
export const COLUMN_NAME_SETTINGS_CUSTOMINVOICETAGVALUE = "seCuInTgVa";
export const COLUMN_NAME_SETTINGS_CUSTOMESTIMATETAGVALUE = "seCuEsTgVa";
export const COLUMN_NAME_SETTINGS_CURRENTINVOICECOUNT = "seInCo";
export const COLUMN_NAME_SETTINGS_CURRENTESTIMATECOUNT = "seEsCo";

export const COLUMN_NAME_SETTINGS_ACCOUNTDETAILS_EMAIL = "seAcEm";
export const COLUMN_NAME_SETTINGS_ACCOUNTDETAILS_CURRENTAPPVER = "seAcApVe";
export const COLUMN_NAME_SETTINGS_ACCOUNTDETAILS_ACCOUNTTYPE = "seAcAcTy";
export const COLUMN_NAME_SETTINGS_ACCOUNTDETAILS_SUBSCRIPTIONTYPE = "seAcSuTy";
export const COLUMN_NAME_SETTINGS_ACCOUNTDETAILS_PURCHASESTATE = "seAcPuSt";
export const COLUMN_NAME_SETTINGS_FONTSELECT = "seFoSe";

//        LOCAL DATA
export const TABLE_NAME_LOCALDATA = "loTbl";

export const COLUMN_NAME_LOCALDATA_FILTERINVOICESFROM = "loInFr";
export const COLUMN_NAME_LOCALDATA_FILTERINVOICESTO = "loIvTo";
export const COLUMN_NAME_LOCALDATA_FILTERINVOICESPREFIX = "loIvPr";
export const COLUMN_NAME_LOCALDATA_FILTERINVOICESPAID = "loIvPa";
export const COLUMN_NAME_LOCALDATA_FILTERINVOICESUNPAID = "loIvUn";

export const COLUMN_NAME_LOCALDATA_FILTERESTIMATESFROM = "loEsFr";
export const COLUMN_NAME_LOCALDATA_FILTERESTIMATESTO = "loEsTo";
export const COLUMN_NAME_LOCALDATA_FILTERESTIMATESPREFIX = "loEsPr";
export const COLUMN_NAME_LOCALDATA_FILTERESTIMATESPAID = "loEsPa";
export const COLUMN_NAME_LOCALDATA_FILTERESTIMATESUNPAID = "loEsUn";

export const COLUMN_NAME_LOCALDATA_FILTERCUSTOMITEMSFROM = "loCuItFr";
export const COLUMN_NAME_LOCALDATA_FILTERCUSTOMITEMSTO = "loCuItTo";
export const COLUMN_NAME_LOCALDATA_FILTERCUSTOMITEMSPREFIX = "loCuItPr";

export const COLUMN_NAME_LOCALDATA_FILTERCUSTOMCONTACTSFROM = "loCuCoFr";
export const COLUMN_NAME_LOCALDATA_FILTERCUSTOMCONTACTSTO = "loCuCoTo";
export const COLUMN_NAME_LOCALDATA_FILTERCUSTOMCONTACTSPREFIX = "loCuCoPr";


//        HISTORY_REFERENCE TABLE
export const TABLE_NAME_HISTORY_REFERENCE = "hiReTbl";
export const TABLE_NAME_HISTORY_REFERENCEREQUEST = "hiReReTbl";
export const COLUMN_NAME_HISTORY_REFERENCE_DOCUMENTOWNER = "hiReDoOw";
export const COLUMN_NAME_HISTORY_REFERENCE_EVENTTYPE = "hiReEvTy";
export const COLUMN_NAME_HISTORY_REFERENCE_EVENTTITLE = "hiReEvTi";
export const COLUMN_NAME_HISTORY_REFERENCE_EVENTDESCRIPTION = "hiReEvDe";
export const COLUMN_NAME_HISTORY_REFERENCE_DATE = "hiReDa";
export const COLUMN_NAME_HISTORY_COUNT = "hiCo";



//        HISTORY
export const TABLE_NAME_HISTORY = "hiTbl";
export const COLUMN_NAME_HISTORY_DOCUMENTOWNER = "hiDoOw";
export const COLUMN_NAME_HISTORY_EVENTTYPE = "hiEvTy";
export const COLUMN_NAME_HISTORY_EVENTTITLE = "hiEvTi";
export const COLUMN_NAME_HISTORY_EVENTDESCRIPTION = "hiEvDe";
export const COLUMN_NAME_HISTORY_DATE = "hiDa";

//        BUSINESS INFO
export const TABLE_NAME_BUSINESS_INFO = "buTbl";
export const COLUMN_NAME_BUSINESS_INFO_USERID = "buUsId";
export const COLUMN_NAME_BUSINESS_INFO_IMAGE = "buIm";
export const COLUMN_NAME_BUSINESS_INFO_IMAGEPATH = "buImPa";
export const COLUMN_NAME_BUSINESS_INFO_NAME = "buNa";
export const COLUMN_NAME_BUSINESS_INFO_OWNERNAME = "buOwNa";
export const COLUMN_NAME_BUSINESS_INFO_BUSINESSNUMBER = "buBuNu";
export const COLUMN_NAME_BUSINESS_INFO_ADDRESSONE = "buAdOn";
export const COLUMN_NAME_BUSINESS_INFO_ADDRESSTWO = "buAdTw";
export const COLUMN_NAME_BUSINESS_INFO_ADDRESSTHREE = "buAdTh";
export const COLUMN_NAME_BUSINESS_INFO_EMAIL = "buEm";
export const COLUMN_NAME_BUSINESS_INFO_PHONE = "buPh";
export const COLUMN_NAME_BUSINESS_INFO_MOBILE = "buMo";
export const COLUMN_NAME_BUSINESS_INFO_WEBSITE = "buWe";

//        INVOICES
export const TABLE_NAME_INVOICES = "inTbl";
export const COLUMN_NAME_INVOICE_FIRSTCREATEDDATE = "inFiCrDa";
export const COLUMN_NAME_INVOICE_DOCUMENTTYPE = "inDoTy";
export const COLUMN_NAME_INVOICE_PONUMBER = "inPoNu";
export const COLUMN_NAME_INVOICE_BUSINESSINFO = "inBuIn";
export const COLUMN_NAME_INVOICE_CLIENT = "inCl";
export const COLUMN_NAME_INVOICE_CREATEDDATE = "inCrDa";
export const COLUMN_NAME_INVOICE_DOCUMENTID = "inDoId";
export const COLUMN_NAME_INVOICE_DUEDATE = "inDuDa";
export const COLUMN_NAME_INVOICE_NUMBER = "inNu";
export const COLUMN_NAME_INVOICE_STATUS = "inSt";
export const COLUMN_NAME_INVOICE_TERMS = "inTe";
export const COLUMN_NAME_INVOICE_USERID = "inUsId";
export const COLUMN_NAME_INVOICE_TOTAL = "inTot";

export const COLUMN_NAME_INVOICE_CLIENTNAME = "inClNa";
export const COLUMN_NAME_INVOICE_CLIENTEMAIL = "inClEm";
export const COLUMN_NAME_INVOICE_CLIENTPHONE = "inClPh";
export const COLUMN_NAME_INVOICE_CLIENTMOBILE = "inClMo";
export const COLUMN_NAME_INVOICE_CLIENTFAX = "inClFa";
export const COLUMN_NAME_INVOICE_CLIENTCONTACT = "inClCo";
export const COLUMN_NAME_INVOICE_CLIENTADDRESSONE = "inClAdOn";
export const COLUMN_NAME_INVOICE_CLIENTADDRESSTWO = "inClAdTw";
export const COLUMN_NAME_INVOICE_CLIENTADDRESSTHREE    = "inClAdTh";

export const COLUMN_NAME_INVOICE_ITEMSSUBTOTAL    = "inItSu";
export const COLUMN_NAME_INVOICE_ITEMSTAXTOTAL    = "inItTaTo";

export const COLUMN_NAME_INVOICE_DISCOUNTTYPE = "inDiTy";
export const COLUMN_NAME_INVOICE_DISCOUNTVALUE = "inDiVa";
export const COLUMN_NAME_INVOICE_DISCOUNTVALUETOTAL = "inDiVaTo";
export const COLUMN_NAME_INVOICE_AFTERDISCOUNTTOTAL = "inAfDiTo";

export const COLUMN_NAME_INVOICE_TAXTYPE = "inTaTy";
export const COLUMN_NAME_INVOICE_TAXLABEL = "inTaLa";
export const COLUMN_NAME_INVOICE_TAXRATE = "inTaRa";
export const COLUMN_NAME_INVOICE_TAXTOTAL = "inTaTo";

export const COLUMN_NAME_INVOICE_PAYMENTLISTTOTAL= "inPaLiTo";

export const COLUMN_NAME_INVOICE_BALANCEDUE= "inBaDu";

export const COLUMN_NAME_INVOICE_PAYMENTINSTRUCTIONSEMAIL = "inPaInEm";
export const COLUMN_NAME_INVOICE_PAYMENTINSTRUCTIONSECHEQUESPAYABLE= "inPaInChPa";
export const COLUMN_NAME_INVOICE_PAYMENTINSTRUCTIONSBANKTRANSFER = "inPaInBaTr";
export const COLUMN_NAME_INVOICE_PAYMENTINSTRUCTIONSOTHER = "inPaInOt";

export const COLUMN_NAME_INVOICE_SIGNATUREFILENAME = "inSiFiNa";
export const COLUMN_NAME_INVOICE_SIGNATUREFILEPATH = "inSiFiPa";

export const COLUMN_NAME_INVOICE_SIGNATUREDOCUMENTOWNER= "inSiDoOw";
export const COLUMN_NAME_INVOICE_SIGNATURECREATED = "inSiCr";

export const COLUMN_NAME_INVOICE_OTHERNOTES = "inOtNo";

export const COLUMN_NAME_INVOICE_MARKASPAID = "inMaPa";

export const COLUMN_NAME_INVOICE_LINK = "unLi";
export const COLUMN_NAME_INVOICE_LINKTWO = "unLiTw";

export const COLUMN_NAME_INVOICE_REMINDEREMAILSENT = "inReEmSe";

//        PHOTOS
export const TABLE_NAME_PHOTOS = "phTbl";
export const COLUMN_NAME_INVOICE_PHOTOS_DESCRIPTION = "phDe";
export const COLUMN_NAME_INVOICE_PHOTOS_ADDITIONALDETAILS = "phAdDe";
export const COLUMN_NAME_INVOICE_PHOTOS_FILENAME = "phFiNa";
export const COLUMN_NAME_INVOICE_PHOTOS_FILEPATH = "phFiPa";
export const COLUMN_NAME_INVOICE_INVOICE_PHOTOS_DOCUMENTOWNER= "phDoOw";
export const COLUMN_NAME_INVOICE_INVOICE_PHOTOS_DOCUMENTID= "phDoId";
export const COLUMN_NAME_INVOICE_PHOTOS_CREATED = "phCr";
export const COLUMN_NAME_INVOICE_PHOTOS_SEQUENCE = "phSe";


//      INVOICE_ITEM_LIST
export const TABLE_NAME_INVOICE_ITEMLIST = "itTbl";
export const COLUMN_NAME_INVOICE_ITEMLIST_DOCUMENTOWNER= "itDoOw";
export const COLUMN_NAME_INVOICE_ITEMLIST_DOCUMENTID= "itDoId";
export const COLUMN_NAME_INVOICE_ITEMLIST_DESCRIPTION = "itDe";
export const COLUMN_NAME_INVOICE_ITEMLIST_UNITCOST = "itUnCo";
export const COLUMN_NAME_INVOICE_ITEMLIST_QUANTITY = "itQu";
export const COLUMN_NAME_INVOICE_ITEMLIST_UNIT = "itUn";
export const COLUMN_NAME_INVOICE_ITEMLIST_SUBTOTAL = "itSuTo";
export const COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTTYPE = "itDiTy";
export const COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTAMOUNT = "itDiAm";
export const COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTVALUETOTAL = "itDiVaTo";
export const COLUMN_NAME_INVOICE_ITEMLIST_AFTERDISCOUNTAMOUNT = "itAfDiAm";
export const COLUMN_NAME_INVOICE_ITEMLIST_TAXABLE = "itTa";
export const COLUMN_NAME_INVOICE_ITEMLIST_TAXRATE = "itTaRa";
export const COLUMN_NAME_INVOICE_ITEMLIST_AFTERTAXAMOUNT = "itAfTaAm";
export const COLUMN_NAME_INVOICE_ITEMLIST_ADDITIONALDETAILS = "itAdDe";
export const COLUMN_NAME_INVOICE_ITEMLIST_DATECREATED = "itDaCr";
export const COLUMN_NAME_INVOICE_ITEMLIST_SEQUENCE= "itSe";


//      INVOICE_CUSTOM_ITEMS
export const TABLE_NAME_CUSTOM_ITEMS = "cuItTbl";
export const COLUMN_NAME_CUSTOM_ITEMS_DOCUMENTOWNER= "cusItDoOw";
export const COLUMN_NAME_CUSTOM_ITEMS_DOCUMENTID= "cuItDoId";
export const COLUMN_NAME_CUSTOM_ITEMS_DESCRIPTION = "cuItDe";
export const COLUMN_NAME_CUSTOM_ITEMS_UNITCOST = "cuItUnCo";
export const COLUMN_NAME_CUSTOM_ITEMS_QUANTITY = "cuItQu";
export const COLUMN_NAME_CUSTOM_ITEMS_UNIT = "cuItUn";
export const COLUMN_NAME_CUSTOM_ITEMS_SUBTOTAL = "cuItSuTo";
export const COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTTYPE = "cuItDiTy";
export const COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTAMOUNT = "cuItDiAm";
export const COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTVALUETOTAL = "cuItDiVaTo";
export const COLUMN_NAME_CUSTOM_ITEMS_AFTERDISCOUNTAMOUNT = "cuItAfDiAm";
export const COLUMN_NAME_CUSTOM_ITEMS_TAXABLE = "cuItTa";
export const COLUMN_NAME_CUSTOM_ITEMS_TAXRATE = "cuItTaRa";
export const COLUMN_NAME_CUSTOM_ITEMS_AFTERTAXAMOUNT = "cuItAfTaAm";
export const COLUMN_NAME_CUSTOM_ITEMS_ADDITIONALDETAILS = "cuItAdDe";
export const COLUMN_NAME_CUSTOM_ITEMS_DATECREATED = "cuItDaCr";

//      INVOICE_CUSTOM_CONTACTS
export const TABLE_NAME_CUSTOM_CONTACTS = "cuCoTbl";
export const COLUMN_NAME_CUSTOM_CONTACTS_DOCUMENTID = "cuCoDoId";
export const COLUMN_NAME_CUSTOM_CONTACTS_NAME = "cuCoNa";
export const COLUMN_NAME_CUSTOM_CONTACTS_EMAIL = "cuCoEm";
export const COLUMN_NAME_CUSTOM_CONTACTS_PHONE = "cuCoPh";
export const COLUMN_NAME_CUSTOM_CONTACTS_MOBILE = "cuCoMo";
export const COLUMN_NAME_CUSTOM_CONTACTS_FAX = "cuCoFa";
export const COLUMN_NAME_CUSTOM_CONTACTS_CONTACT = "cuCoCo";
export const COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSONE = "cuCoAdOn";
export const COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSTWO = "cuCoAdTw";
export const COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSTHREE    = "cuCoAdTh";
export const COLUMN_NAME_CUSTOM_CONTACTS_DATECREATED    = "cuCoDaCr";

//      INVOICE_PAYMENT_ITEM_LIST
export const TABLE_NAME_INVOICE_PAYMENTLIST = "paTbl";
export const COLUMN_NAME_INVOICE_PAYMENTLIST_DOCUMENTOWNER= "paDoOw";
export const COLUMN_NAME_INVOICE_PAYMENTLIST_DOCUMENTID= "paDoId";
export const COLUMN_NAME_INVOICE_PAYMENTLIST_METHOD= "paMe";
export const COLUMN_NAME_INVOICE_PAYMENTLIST_AMOUNT= "paAm";
export const COLUMN_NAME_INVOICE_PAYMENTLIST_DATE= "paDa";
export const COLUMN_NAME_INVOICE_PAYMENTLIST_NOTES= "paNo";
export const COLUMN_NAME_INVOICE_PAYMENTLIST_CREATEDDATE= "paCrDa";

//      COLLECTIONS
export const COLLECTION_PURCHASES = "puToCol";
export const COLLECTION_USERS = "usCol";
export const COLLECTION_ADMIN = "adCol";
export const COLLECTION_UNIQUELINKS = "unLiCol";
export const COLLECTION_REFERENCES_UNIQUELINKS = "unLiReCol";
export const COLLECTION_REFERENCESREQUEST_UNIQUELINKS = "unLiReReCol";
export const COLLECTION_REFERENCE = "reCol";
// export const COLLECTION_REFERENCEREQUEST = "reReCol";
export const COLLECTION_EMAILVERIFCATION = "emVeCol";
export const COLLECTION_PRIVATE = "prCol";

//      UNIQUE_LINKS
export const UNIQUELINK_LINK = "unLi";
export const UNIQUELINK_LINKTWO = "unLiTw";
export const UNIQUELINK_CREATED = "unCr";
export const UNIQUELINK_DOCREF = "unDoRe";
export const UNIQUELINK_USER = "unUs";
export const UNIQUELINK_EMAILVIEWCOUNT = "unEmViCo";
export const UNIQUELINK_LINKVIEWCOUNT = "unLiViCo";
export const UNIQUELINK_LASTLINKVIEW = "unLaLiVi";
export const UNIQUELINK_LASTEMAILVIEW = "unLaEmVi";
export const UNIQUELINK_VIEWEDRECENTEMAIL = "unViReEm";
export const UNIQUELINK_VIEWEDRECENTLINK = "unViReLi";
export const UNIQUELINK_FAILEDVIEWATTEMPTS = "unFaViAt";
export const UNIQUELINK_SYSTEMLINKSTATUS = "unSyLiSt";
export const UNIQUELINK_USERLINKSTATUS = "unUsLiSt";
export const UNIQUELINK_LINKURL = "unLiUr";

//     PURCHASE_TOKEN
export const PURCHASE_TOKEN_USER = "user";
export const PURCHASE_TOKEN_PURCHASETIME = "puPuTi";
export const PURCHASE_TOKEN_PURCHASESTATE = "puPuSt";
export const PURCHASE_TOKEN_SUBSCRIPTIONSTATE = "puSuSt";
export const PURCHASE_TOKEN_ISTOKENTRASH = "puIsToTr";
export const PURCHASE_TOKEN_ORDERID = "puOrId";
export const PURCHASE_TOKEN_PRODUCTSKU = "puPrSk";
export const PURCHASE_TOKEN_PURCHASETOKEN = "puPuTo";

//     EMAIL_VERICATION

export const EMAIL_VERIFICATION = "emVe";
export const EMAIL_VERIFICATION_NOTVERIFIEDTIME = "emVeNoVeTi";
export const EMAIL_VERIFICATION_TEMPVERIFIED = "emVeTeVe";

//     ADMIN
export const ADMIN_EMAILS = "emails";

//     USER
export const USER_FCMTOKEN = "usFcTo";


//     REFERENCES
export const TABLE_NAME_REFERENCES= "reTbl";
export const REFERENCE_DOCUMENTID= "reDoId";
export const REFERENCE_NAME= "reNa";
export const REFERENCE_PROJECTTITLE= "rePrTi";  
export const REFERENCE_TITLE = "reTi";
export const REFERENCE_ADDRESSONE = "reAdOn";
export const REFERENCE_ADDRESSTWO = "reAdTw";
export const REFERENCE_ADDRESSTHREE = "reAdTh";
export const REFERENCE_ORGANIZATION = "reOr";
export const REFERENCE_COMMENT = "reCo";
export const REFERENCE_PHONE = "rePh";
export const REFERENCE_EMAIL = "reEm";
export const REFERENCE_VISIBLE = "reVi";
export const REFERENCE_SEQUENCE = "reSe";
export const REFERENCE_CREATEDDATE = "reCrDa";
export const REFERENCE_SUBMITTEDDATE = "reSuDa";
export const REFERENCE_AGREETOTERMS = "reAgToTe";


export const REFERENCE_LINK = "unLi";
export const REFERENCE_LINKTWO = "unLiTw";


// link: uniqueLink,
// linkTwo: myRandomIdTwo,
// created: secondsSinceEpoch,
// docref: recdocRef,
// user: userRef,
// emailViewCount: "0",
// linkViewCount: "0",
// lastLinkView: 0,
// lastEmailView: 0,
// viewedRecentEmail: false,
// viewedRecentLink: false,
// failedViewAttempts: "0",
// systemLinkStatus: true,
// userLinkStatus: true



