import React, { useState, useEffect } from "react"
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';

import {CONST_ERROR, CONST_SUCCESS, CONST_ACCOUNT} from '../../constants/string'
import * as database from '../../constants/database'

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';
import CheckMaxLength from '../../validate/CheckMaxLength';
import Hidden from '@material-ui/core/Hidden';
import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  }

  
}));



function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}

var checkedBefore = [];
var checkedLive = [];

var daysFutureBefore = "";
var daysFutureLive = "";

var invoiceTagBefore = "";
var invoiceTagLive = "";

var estimateTagBefore = "";
var estimateTagLive = "";


export default function Account(props) {



  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var settingsRef = userRef.collection(database.TABLE_NAME_SETTINGS).doc("1");
  var businessRef = userRef.collection(database.TABLE_NAME_BUSINESS_INFO).doc("1");

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    
  const [autoSetDateVisible, setAutoSetDateVisible] = useState(false)

  var [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState([]);

  const [email, setEmail] = useState("");

  const [daysFuture, setDaysFuture] = useState("");


  const [invoiceTag, setInvoiceTag] = useState("");


  const [estimateTag, setEstimateTag] = useState("");


  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  async function writeUserData() {
    setLoading(true)
    return settingsRef.set({
      settingsCopyEmails: (checkedLive.includes('emailcopy')).toString(),
      settingsLiveSearch: (checkedLive.includes('livefieldsearch')).toString(),
      settingsAutoSetDate : (checkedLive.includes('autosetduedate')).toString(),
      settingsAutoSetDateValue : daysFutureLive,
      settingsCustomInvoiceTagValue : invoiceTagLive,
      settingsCustomEstimateTagValue : estimateTagLive,
    },{ merge: true })
    .then(() => {
      setLoading(false)
      setError("")

      
      props.setSaveState(false);

      // setSnackBar({show: true, time: 2000, message: "Saved", severit: "success"})
      props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)

      checkedBefore = checkedLive;
      daysFutureBefore = daysFutureLive;
      invoiceTagBefore = invoiceTagLive;
      estimateTagBefore = estimateTagLive;
  })
  .catch((error) => {
      // setSnackBarError()
      props.startSnackBar(true, 20000,+CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)

      setLoading(false)
  });
  }


  async function updateProfileEmail(email) {

    return businessRef.set({
      businessInfoEmail: email
    },{ merge: true });
  }

  const fetchData=async()=>{
  return settingsRef.get().then((doc) => {
    if (doc.exists) {
 
        const newChecked = [];

        if(doc.data()[database.COLUMN_NAME_SETTINGS_COPYEMAILS] === "true")
        {
          newChecked.push('emailcopy');
        }
        if(doc.data()[database.COLUMN_NAME_SETTINGS_LIVESEARCH] === "true")
        {
          newChecked.push('livefieldsearch');
        }
        if(doc.data()[database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATE] === "true")
        {
          newChecked.push('autosetduedate');
        }
        if(doc.data()[database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATEVALUE])
        {
          setDaysFuture(doc.data()[database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATEVALUE]);
          daysFutureBefore = doc.data()[database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATEVALUE];
          daysFutureLive = doc.data()[database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATEVALUE];
        }
        if(doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMINVOICETAGVALUE])
        {
          setInvoiceTag(doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMINVOICETAGVALUE]);
          invoiceTagBefore = doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMINVOICETAGVALUE];
          invoiceTagLive = doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMINVOICETAGVALUE];
        }
        if(doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMESTIMATETAGVALUE])
        {
          setEstimateTag(doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMESTIMATETAGVALUE]);
          estimateTagBefore = doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMESTIMATETAGVALUE];
          estimateTagLive = doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMESTIMATETAGVALUE];
        }

        setChecked(newChecked);
        checkedBefore = newChecked;
        checkedLive = newChecked;
    } else {
      // setSnackBarError("No such data")
      props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)

    }
}).catch((error) => {
  console.log("Error getting document:", error);
});

}



const fetchBusinessData=async()=>{

  return businessRef.get().then((doc) => {
    if (doc.exists) {
   
        if(doc.data()[database.COLUMN_NAME_BUSINESS_INFO_EMAIL])
        {
          setEmail(doc.data()[database.COLUMN_NAME_BUSINESS_INFO_EMAIL])
        }
    } else {
      // setSnackBarError("No such data")
      props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)

    }
});
}




useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleOnClick()
  }

}, [props.activateSave])


useEffect(async () => {
  // Update the document title using the browser API

  props.pageDisplayStatus((pageState) => ({...pageState, backButton:true, mainTab:false, invoiceEstimateTab:"", edPrHiTab: false}))


  setLoading(true)
  // props.setSaveState(true);
  
try{
  setError("")
  await fetchBusinessData();
  fetchData();
  props.setSaveState(false);
}
catch(error){
  props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)
}
  setLoading(false)
}, [])

  function checkForChanges(){

    if((checkedLive.includes("emailcopy")!==checkedBefore.includes("emailcopy"))||(checkedLive.includes("livefieldsearch")!==checkedBefore.includes("livefieldsearch"))||(checkedLive.includes("autosetduedate")!==checkedBefore.includes("autosetduedate"))||(daysFutureLive!==daysFutureBefore)||(invoiceTagLive!==invoiceTagBefore)||(estimateTagLive!==estimateTagBefore))
    {
      props.setSaveState(true);
    }
    else
    {
      props.setSaveState(false);
    }
  }


  const classes = useStyles();

  const [error, setError] = useState("")

  function handleOnClick(){
    if(!CheckMaxLength(invoiceTagLive,5))
    {
      setFieldError({
        invoiceTag: true,
        invoiceTagMsg: "Must be under 5 characters in length"
      })
      return
    }
    if(!CheckMaxLength(estimateTagLive,5))
    {
      setFieldError({
        estimateTag: true,
        estimateTagMsg: "Must be under 5 characters in length"
      })
      return
    }
    writeUserData()
        setFieldError({
      invoiceTag: false,
      invoiceTagMsg: "",
      estimateTag: false,
      estimateTagMsg: ""
    })
  }


  async function handleToggle(e) {
    e.preventDefault();
    var val = e.currentTarget.value;
    if(val==="emailcopy")
    {
      if(notEmpty(email) || !e.currentTarget.checked)
      {
        processSwitchToggle(val);
      }
      else{
        // Show Dialog Box Indicating Email not set
        openDialog(true);
      }
    }
    else
    {
      if(val==="autosetduedate")
      {
        if(e.currentTarget.checked)
        {
          setAutoSetDateVisible(true)
        }
        else
        {
          setAutoSetDateVisible(false)
        }
      }
      processSwitchToggle(val);
    }

  };

  function processSwitchToggle(value){
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    checkedLive = newChecked;
    checkForChanges();
  }

async function handleSave(email) {
  setOpen(false);
  setLoading(true);

  try{
    await updateProfileEmail(email)
    setEmail(email)
    processSwitchToggle("emailcopy")
  }
  catch(error){
    // setSnackBarError("Failed to save data. "+error.code)
    props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)

  }
  setLoading(false);
}


  const updateText = (e)=> {

    switch(e.target.id) {
      case "daysFuture":
        setDaysFuture(e.target.value);
        daysFutureLive = e.target.value;

      break;
      case "invoiceTag":
        setInvoiceTag(e.target.value);
        invoiceTagLive = e.target.value;

        break;
      case "estimateTag":
        setEstimateTag(e.target.value);
        estimateTagLive = e.target.value;
        break;
    }

    checkForChanges();
  };


  return (
    <>

<GeneralViewContainer maxWidth="lg">

      <Grid container spacing={1}>

        <Grid item xs={12}>
        <Typography variant="h5" component="h5">{CONST_ACCOUNT.TITLE}</Typography>
        </Grid>

        <Grid item xs={12}>
        <Box borderTop={1} borderColor="primary.main">
        </Box>
        </Grid>


      <Grid item xs={8} sm={5} md={2}>
      <Tooltip title={CONST_ACCOUNT.ACCOUNT_TYPE_TOOLTIP}>
      <Typography>{CONST_ACCOUNT.ACCOUNT_TYPE}</Typography>
      </Tooltip>
    
        </Grid>
        <Grid item xs={4} sm={7} md={10}>
        <Typography>{CONST_ACCOUNT.TRIAL}</Typography>

        </Grid>

      <Grid item xs={8} sm={5} md={2}>
      <Tooltip title={CONST_ACCOUNT.SUBSCRIPTION_TOOLTIP}>
      <Typography>{CONST_ACCOUNT.SUBSCRIPTION}</Typography>
      </Tooltip>
        </Grid>
        <Grid item xs={4} sm={7} md={10}>
        <Typography>{CONST_ACCOUNT.NONE}</Typography>

        </Grid>

    <Grid item xs={12} sm={6} md={4}>
    <Button variant="contained" fullWidth={true} color="primary">
        {CONST_ACCOUNT.UPGRADE_ACCOUNT_BUTTON}
      </Button>
        </Grid>
        <Hidden only="xs">
        <Grid item xs={0} sm={6} md={8}>
        </Grid>
        </Hidden>


        <Grid item xs={12} sm={6} md={4}>
    <Button variant="contained" fullWidth={true} color="primary">
    {CONST_ACCOUNT.SUBSCRIPTIONS_BUTTON}
      </Button>
        </Grid>
        <Hidden only="xs">
        <Grid item xs={0} sm={6} md={8}>
        </Grid>
        </Hidden>



        <Grid item xs={8} sm={5} md={2}>
      <Tooltip title={CONST_ACCOUNT.APP_VERSION_TOOLTIP}>
      <Typography>{CONST_ACCOUNT.APP_VERSION}</Typography>
      </Tooltip>
        </Grid>
        <Grid item xs={4} sm={7} md={10}>
        <Typography>{CONST_ACCOUNT.NA}</Typography>

        </Grid>

        <Grid item xs={8} sm={5} md={2}>
      <Tooltip title={CONST_ACCOUNT.INVOICES_CREATED_TOOLTIP}>
      <Typography>{CONST_ACCOUNT.INVOICES_CREATED}</Typography>
      </Tooltip>
        </Grid>
        <Grid item xs={4} sm={7} md={10}>
        <Typography>{CONST_ACCOUNT.INVOICES_CREATED_VALUE}</Typography>

        </Grid>



        <Grid item xs={8} sm={5} md={2}>
      <Tooltip title={CONST_ACCOUNT.ESTIMATES_CREATED_TOOLTIP}>
      <Typography>{CONST_ACCOUNT.ESTIMATES_CREATED}</Typography>
      </Tooltip>
        </Grid>
        <Grid item xs={4} sm={7} md={10}>
        <Typography>{CONST_ACCOUNT.ESTIMATES_CREATED_VALUE}</Typography>

        </Grid>




        <Grid item xs={12} sm={6} md={4}>
    <Button variant="contained" fullWidth={true} color="primary">
        {CONST_ACCOUNT.CHANGE_EMAIL_BUTTON}
      </Button>
        </Grid>
        <Hidden only="xs">
        <Grid item xs={0} sm={6} md={8}>
        </Grid>
        </Hidden>



        <Grid item xs={12} sm={6} md={4}>
    <Button variant="contained" fullWidth={true} color="primary">
    {CONST_ACCOUNT.CHANGE_PASSWORD_BUTTON}
      </Button>
        </Grid>

        <Hidden only="xs">
        <Grid item xs={0} sm={6} md={8}>
        </Grid>
        </Hidden>



      
    </Grid>
    </GeneralViewContainer>
{open &&
    <FormDialog handleCancel={closeDialog} handleSave={handleSave}/>
}
    </>
  )
}
