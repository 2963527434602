import TextField from '@material-ui/core/TextField';


export default function CustomTextField(props){


    const handleSpecificKeyDown = (event) => {

        if (event.key === 'Enter') {
            console.log('Inside')

        props.enterKeyDown(true);
        }
      }



      

return (
<>
{/* {props.fullWidth && 
    <TextField fullWidth placeholder={props.placeholder} onChange={props.updateText} id={props.id} label={props.label} value={props.value}  onKeyDown={handleSpecificKeyDown} />
}
{!props.fullWidth && 

    <TextField placeholder={props.placeholder} onChange={props.updateText} id={props.id} label={props.label} value={props.value}  onKeyDown={handleSpecificKeyDown} />
} */}

<TextField {...props} placeholder={props.placeholder} onChange={props.updateText} id={props.id} label={props.label} value={props.value}  onKeyDown={handleSpecificKeyDown} />

</>
)




}



