import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CustomTextField from './CustomTextField';

export default function SimpleDiscountMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null)
    props.returnSelected(e.currentTarget.id)
  };

  return (
    <div>
        <CustomTextField {...props} onClick={handleClick}/>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id="No Discount" onClick={handleClose}>No Discount</MenuItem>
        <MenuItem id="Percentage" onClick={handleClose}>Percentage</MenuItem>
        <MenuItem id="Flat Amount" onClick={handleClose}>Flat Amount</MenuItem>
      </Menu>
    </div>
  );
}
