import React, { useRef, useState, useEffect } from "react"
// import { Form, Button, Card, Alert } from "react-bootstrap"
// import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CustomLoading from '../StandardComponents/CustomLoading';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import IconButton from '@material-ui/core/IconButton';

import {CONST_ERROR, CONST_SUCCESS, CONST_PROFILE} from '../../constants/string'
import * as database from '../../constants/database'

import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"

import { makeStyles } from '@material-ui/core/styles';


var businessInfoImageBefore = "";
var businessInfoImageLive = "";

var businessNameBefore = "";
var businessNameLive = "";

var businessOwnerBefore = "";
var businessOwnerLive = "";

var businesNumberBefore = "";
var businesNumberLive = "";

var addressOneBefore = "";
var addressOneLive = "";

var addressTwoBefore = "";
var addressTwoLive = "";

var addressThreeBefore = "";
var addressThreeLive = "";

var emailBefore = "";
var emailLive = "";

var phoneBefore = "";
var phoneLive = "";

var mobileBefore = "";
var mobileLive = "";

var websiteBefore = "";
var websiteLive = "";








export default function Profile(props) {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
//   const { signup } = useAuth()
  const [error, setError] = useState("")
  const [timer, setTimer] = useState(false);
  const [dataSave, setDataSave] = useState("")


  const [loading, setLoading] = useState(false)
  const history = useHistory()

  
  const [businessImagePath, setBusImagePath] = useState("");
  const [businessName, setBusName] = useState("");
  const [businessOwner, setBusOwner] = useState("");
  const [businesNumber, setBusNumber] = useState("");

  const [addressOne, setAddreOne] = useState("");
  const [addressTwo, setAddreTwo] = useState("");
  const [addressThree, setAddreThree] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [website, setWebsite] = useState("");


  // async function showNotificationTimer(){
  //   setTimer(true);
  //   setTimeout(function(){ 
  //     setTimer(false);
  //     setDataSave("");
  //  }, 3000);
  // }
  

  function handleOnClick(){
    saveData();
  }


  useEffect(async () => {
  
    if(props.activateSave)
    {
      handleOnClick()
    }
  
  }, [props.activateSave])


  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      spacing: 4
    },
    large: {
      width: theme.spacing(25),
      height: theme.spacing(25),
      
    },
    input: {
      display: 'none',
    },
  
  }));

  const classes = useStyles();

  const updateText = (e)=> {

    switch(e.target.id) {

      case "businessInfoImagePath":
        setBusImagePath(e.target.value);
        businessInfoImageLive = e.target.value;
      break;
      
      case "businessName":
        setBusName(e.target.value);
        businessNameLive = e.target.value;
      break;

      case "businessOwner":
        setBusOwner(e.target.value);
        businessOwnerLive = e.target.value;
        break;

      case "businesNumber":
        setBusNumber(e.target.value);
        businesNumberLive = e.target.value;
        break;

      case "addressOne":
        setAddreOne(e.target.value);
        addressOneLive = e.target.value;
        break;
   
      case "addressTwo":
        setAddreTwo(e.target.value);
        addressTwoLive = e.target.value;
        break;

      case "addressThree":
        setAddreThree(e.target.value);
        addressThreeLive = e.target.value;
        break;

      case "email":
        setEmail(e.target.value);
        emailLive = e.target.value;
        break;
    
      case "phone":
        setPhone(e.target.value);
        phoneLive = e.target.value;
        break;

      case "mobile":
        setMobile(e.target.value);
        mobileLive = e.target.value;
        break;

      case "website":
        setWebsite(e.target.value);
        websiteLive = e.target.value;
        break;
    }
    checkForChanges();

  };



  async function writeUserData() {

    return businessRef.set({
      [database.COLUMN_NAME_BUSINESS_INFO_NAME]: businessNameLive,
      [database.COLUMN_NAME_BUSINESS_INFO_OWNERNAME]: businessOwnerLive,
      [database.COLUMN_NAME_BUSINESS_INFO_BUSINESSNUMBER] : businesNumberLive,
      [database.COLUMN_NAME_BUSINESS_INFO_ADDRESSONE] : addressOneLive,
      [database.COLUMN_NAME_BUSINESS_INFO_ADDRESSTWO] : addressTwoLive,
      [database.COLUMN_NAME_BUSINESS_INFO_ADDRESSTHREE] : addressThreeLive,
      [database.COLUMN_NAME_BUSINESS_INFO_EMAIL] : emailLive,
      [database.COLUMN_NAME_BUSINESS_INFO_PHONE] : phoneLive,
      [database.COLUMN_NAME_BUSINESS_INFO_MOBILE] : mobileLive,
      [database.COLUMN_NAME_BUSINESS_INFO_WEBSITE] : websiteLive
    },{ merge: true });
  }


  async function saveData() {
    // Update the document title using the browser API
    setLoading(true)

  try{
    setError("")
    await writeUserData();
    // setSaveButton(false);
    props.setSaveState(false);

    // setDataSave("Data Saved");
    props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)

    // showNotificationTimer();
  
    businessInfoImageBefore = businessInfoImageLive;
    businessNameBefore = businessNameLive;
    businessOwnerBefore = businessOwnerLive;
    businesNumberBefore = businesNumberLive;
    addressOneBefore = addressOneLive;
    addressTwoBefore = addressTwoLive;
    addressThreeBefore = addressThreeLive;
    emailBefore = emailLive;
    phoneBefore = phoneLive;
    mobileBefore = mobileLive;
    websiteBefore = websiteLive;

  }
  catch(error){
    setError("Failed to save data")
    props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_SAVE_DATA+" "+error.code,"error",true)

  }
     
    setLoading(false)
  
  }



  useEffect(async () => {
    props.handleLoadingState(loading)
  }, [loading])


  function checkForChanges(){

    if((businessInfoImageBefore!==businessInfoImageLive)||(businessNameBefore!==businessNameLive)||(businessOwnerBefore!==businessOwnerLive)||(businesNumberBefore!==businesNumberLive)||(addressOneBefore!==addressOneLive)||(addressTwoBefore!==addressTwoLive)||(addressThreeBefore!==addressThreeLive)||(emailBefore!==emailLive)||(phoneBefore!==phoneLive)||(mobileBefore!==mobileLive)||(websiteBefore!==websiteLive))
    {
      // setSaveButton(true);
      props.setSaveState(true);

    }
    else
    {
      // setSaveButton(false);
      props.setSaveState(false);

    }
  }





  const { currentUser } = useAuth()

  const [save, setSaveButton] = useState(false)


  var userRef = db.collection("users").doc(currentUser.uid);
  var businessRef = userRef.collection(database.TABLE_NAME_BUSINESS_INFO).doc("1");



  const fetchData=async()=>{
    return businessRef.get().then((doc) => {
      if (doc.exists) {

        var businessInfoImagePath = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_IMAGEPATH]
        var businessInfoName = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_NAME]
        var businessInfoOwnerName = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_OWNERNAME]
        var businessInfoBusinessNumber = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_BUSINESSNUMBER]
        var businessInfoAddressOne = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_ADDRESSONE]
        var businessInfoAddressTwo = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_ADDRESSTWO]
        var businessInfoAddressThree = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_ADDRESSTHREE]
        var businessInfoEmail = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_EMAIL]
        var businessInfoPhone = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_PHONE]
        var businessInfoMobile = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_MOBILE]
        var businessInfoWebsite = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_WEBSITE]



          if(businessInfoImagePath)
          {
            setBusImagePath(businessInfoImagePath);
            businessInfoImageBefore = businessInfoImagePath;
            businessInfoImageLive = businessInfoImageBefore;
          }
          if(businessInfoName)
          {
            setBusName(businessInfoName);
            businessNameBefore = businessInfoName;
            businessNameLive = businessNameBefore;
          }
          if(businessInfoOwnerName)
          {
            setBusOwner(businessInfoOwnerName);
            businessOwnerBefore = businessInfoOwnerName;
            businessOwnerLive = businessOwnerBefore;
          }
          if(businessInfoBusinessNumber)
          {
            setBusNumber(businessInfoBusinessNumber);
            businesNumberBefore = businessInfoBusinessNumber;
            businesNumberLive = businesNumberBefore;
          }
          if(businessInfoAddressOne)
          {
            setAddreOne(businessInfoAddressOne);
            addressOneBefore = businessInfoAddressOne;
            addressOneLive = addressOneBefore;
          }
          if(businessInfoAddressTwo)
          {
            setAddreTwo(businessInfoAddressTwo);
            addressTwoBefore = businessInfoAddressTwo;
            addressTwoLive = addressTwoBefore;
          }
          if(businessInfoAddressThree)
          {
            setAddreThree(businessInfoAddressThree);
            addressThreeBefore = businessInfoAddressThree;
            addressThreeLive = addressThreeBefore;
          }
          if(businessInfoEmail)
          {
            setEmail(businessInfoEmail);
            emailBefore = businessInfoEmail;
            emailLive = emailBefore;
          }
           if(businessInfoPhone)
          {
            setPhone(businessInfoPhone);
            phoneBefore = businessInfoPhone;
            phoneLive = phoneBefore;
          }                     
          if(businessInfoMobile)
          {
            setMobile(businessInfoMobile);
            mobileBefore = businessInfoMobile;
            mobileLive = mobileBefore;
          }
          if(businessInfoWebsite)
          {
            setWebsite(businessInfoWebsite);
            websiteBefore = businessInfoWebsite;
            websiteLive = websiteBefore;
          }
  
          // setChecked(newChecked);
      } else {
          setError(CONST_ERROR.NO_SUCH_DATA)
          props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)

      }
      setLoading(false)
  }).catch((error) => {
    setError("Failed to get data")
    props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)
    setLoading(false)
  });
  
  
  }





  useEffect(async () => {
    // Update the document title using the browser API
    props.pageDisplayStatus((pageState) => ({...pageState,backButton:true, mainTab:false, invoiceEstimateTab:false, edPrHiTab: false}))


    setLoading(true)
    setError("")
    // setSaveButton(false);
    props.setSaveState(false);

    fetchData(); 
  
  }, [])
  





  return (
    <>
{/* <CustomLoading visible={loading}/>

{error && <Alert severity="error">{error}</Alert>}
<Container>
{save &&  <Button  onClick={handleOnClick} variant="outlined" color="primary">Save</Button>}
{timer && dataSave && <Alert severity="success">{dataSave}</Alert>}
</Container> */}





<GeneralViewContainer maxWidth="lg">



<Grid container spacing={1}>

<Grid item xs={12}>
<Typography variant="h5" component="h5">{CONST_PROFILE.TITLE}</Typography>
</Grid>

<Grid item xs={12}>
<Box borderTop={1} borderColor="primary.main">
</Box>
</Grid>

{/* https://cdn.logo.com/hotlink-ok/logo-social-sq.png */}
<Grid item xs={12}>
<Box button display="flex" justifyContent="flex-start" p={1}>



<input accept="image/*" className={classes.input} id="icon-button-file" type="file" />
      <label htmlFor="icon-button-file">
        <IconButton color="primary" aria-label="upload picture" component="span">
        <Avatar alt="Remy Sharp" variant="rounded"  src="12" className={classes.large} >
<AddPhotoAlternateIcon />
</Avatar>
        </IconButton>
      </label>



</Box>
</Grid>


<Card variant="outlined">


<List >
      <ListItem>
    <TextField  fullWidth={true} onChange={updateText} id="businessName" label={CONST_PROFILE.BUSINESS_NAME} value={businessName}/>
        </ListItem>
  
        <ListItem>
    <TextField  onChange={updateText}  id="businessOwner" label={CONST_PROFILE.BUSINESS_OWNER} value={businessOwner} />
        </ListItem>

        <ListItem>
    <TextField onChange={updateText} id="businesNumber" label={CONST_PROFILE.BUSINESS_NUMBER} value={businesNumber}/>
        </ListItem>
</List>
</Card>
</Grid>

<Card variant="outlined">
<List>
      <ListItem>
    <TextField  onChange={updateText} id="addressOne" label={CONST_PROFILE.ADDRESS_LINE_ONE} value={addressOne}/>
        </ListItem>
  
        <ListItem>
    <TextField  onChange={updateText}  id="addressTwo" label={CONST_PROFILE.ADDRESS_LINE_TWO} value={addressTwo} />
        </ListItem>

        <ListItem>
    <TextField onChange={updateText} id="addressThree" label={CONST_PROFILE.ADDRESS_LINE_THREE} value={addressThree}/>
        </ListItem>
</List>
</Card>

<Card variant="outlined">
<List>
      <ListItem>
    <TextField  type="email" onChange={updateText} id="email" label={CONST_PROFILE.EMAIL} value={email}/>
        </ListItem>
  
        <ListItem>
    <TextField type="tel" onChange={updateText}  id="phone" label={CONST_PROFILE.PHONE} value={phone} />
        </ListItem>

        <ListItem>
    <TextField type="tel" onChange={updateText} id="mobile" label={CONST_PROFILE.MOBILE} value={mobile}/>
        </ListItem>

        <ListItem>
    <TextField onChange={updateText} id="website" label={CONST_PROFILE.WEBSITE} value={website}/>
        </ListItem>
</List>
</Card>


</GeneralViewContainer>




    </>
  )
}
