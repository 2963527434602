
export const CONST_ERROR = {
NO_CONTACT_PROVIDED: "No Contact Provided",
FAILED_TO_GET_DATA: "Failed to get data",
FAILED_TO_SAVE_DATA: "Failed to save data",
NO_SUCH_DATA: "No such data",
PROBLEM_GETTING_DATA: "Problem getting data",
INVALID_CONTACT_ID: "Invalid Contact Id"
};


//        SUCCESS STRINGS
export const CONST_SUCCESS = {
    SAVED: "Saved"
    }


//        PAYMENT INSTRUCTIONS STRINGS
export const CONST_PAYMENT_INSTRUCTIONS = {
TITLE: "Payment Instructions",

EMAIL_TITLE: "Paypal Email",
EMAIL_LABEL: "Paypal Email",

CHEQUES_PAYABLE_TITLE: "Cheques Payable",
CHEQUES_PAYABLE_LABEL: "Cheques Payable",

BANK_TRANSFER_TITLE: "Bank Transfer",
BANK_TRANSFER_LABEL: "Bank Transfer",
BANK_TRANSFER_PLACEHOLDER: "Enter bank account # and other details here",

OTHER_TITLE: "Other",
OTHER_LABEL: "Other",
}


//        DETAILS STRINGS
export const CONST_DETAILS = {
TITLE: "Details",

INVOICE_NUMBER_TITLE: "Paypal Email",
INVOICE_NUMBER_LABEL: "Paypal Email",

CREATED_DATE_TITLE: "Created Date",

TERMS_TITLE: "Terms",
TERMS_LABEL: "Terms",

DUE_DATE_TITLE: "Due Date",

PO_NUMBER_TITLE: "PO Number",
PO_NUMBER_LABEL: "PO Number"
}

//        DETAILS STRINGS
export const CONST_SUPPORT = {
TITLE: "Support",

HAVING_ISSUES: "Having Issues?",

SEND_US_EMAIL: "Send us an email describing the problem and we will get back to you",

EMAIL_NOW: "Email Now",

EMAIL_MESSAGE: "Hi,\n\nI am reporting the following problem.\nAccount Email:\nProblem Description:",

EMAIL_SUBJECT: "InvoiceGo - Reporting a Problem",

EMAIL_TO: "mailto:contact@invoicego.app?subject=",

}

    //        DETAILS STRINGS
export const CONST_SIGNATURE = {
TITLE: "Signature",


}


//        PROFILE STRINGS
export const CONST_PROFILE = {
    TITLE: "Profile",
    
    BUSINESS_NAME: "Business Name",

    BUSINESS_OWNER: "Business Owner",

    BUSINESS_NUMBER: "Business Number",

    ADDRESS_LINE_ONE: "Address Line 1",

    ADDRESS_LINE_TWO: "Address Line 2",

    ADDRESS_LINE_THREE: "Address Line 3",

    EMAIL: "Email",

    PHONE: "Phone",

    MOBILE: "Mobile",

    WEBSITE: "Website"
    }


//        CONTACT STRINGS
export const CONST_CONTACT = {
TITLE: "Contact",

NAME_TITLE: "Name",
NAME_LABEL: "Name",
NAME_PLACEHOLDER: "Name",

EMAIL_TITLE: "Email",
EMAIL_LABEL: "Email",
EMAIL_PLACEHOLDER: "Email",

PHONE_TITLE: "Phone",
PHONE_LABEL: "Phone",
PHONE_PLACEHOLDER: "Phone",

MOBILE_TITLE: "Mobile",
MOBILE_LABEL: "Mobile",
MOBILE_PLACEHOLDER: "Mobile",

FAX_TITLE: "Fax",
FAX_LABEL: "Fax",
FAX_PLACEHOLDER: "Fax",

CONTACT_TITLE: "Contact",
CONTACT_LABEL: "Contact",
CONTACT_PLACEHOLDER: "Contact",

ADDRESS_ONE_TITLE: "Address 1",
ADDRESS_ONE_LABEL: "Address 1",
ADDRESS_ONE_PLACEHOLDER: "Address 1",

ADDRESS_TWO_TITLE: "Address 2",
ADDRESS_TWO_LABEL: "Address 2",
ADDRESS_TWO_PLACEHOLDER: "Address 2",

ADDRESS_THREE_TITLE: "Address 3",
ADDRESS_THREE_LABEL: "Address 3",
ADDRESS_THREE_PLACEHOLDER: "Address 3",
}


//        SETTINGS STRINGS
export const CONST_SETTINGS = {
TITLE: "Settings",

RECEIVE_COPY_EMAILS: "Receive Copy of Emails",
RECEIVE_COPY_EMAILS_TOOLTIP: "Receive a copy of any emails sent out on the account",

ENABLE_TEXT_SEARCH: "Enable Live Text Field Searches",
ENABLE_TEXT_SEARCH_TOOLTIP: "This feature is used to copy already created contacts or items into a documents contact, or items list",
        
AUTO_SET_DUE_DATE: "Auto Set Due Date",
AUTO_SET_DUE_DATE_TOOLTIP: "On document creation, automatically set the due date for the document x days in advance",

AUTO_SET_DUE_DATE: "Auto Set Due Date",
AUTO_SET_DUE_DATE_TOOLTIP: "On document creation, automatically set the due date for the document x days in advance",

INVOICE_TAG_TOOLTIP: "The tag attached to each invoice ID. Ex ID: 'INV0001'",
INVOICE_TAG_LABEL: "Invoice Tag",

ESTIMATE_TAG_TOOLTIP: "The tag attached to each invoice ID. Ex ID: 'EST0001'",
ESTIMATE_TAG_LABEL: "Estimate Tag",


}


export const CONST_VALIDATE = {

LONGER_THAN_FIVE_CHARACTERS: "Must be under 5 characters in length" 
}


//        ACCOUNT STRINGS
export const CONST_ACCOUNT = {
TITLE: "Account",

ACCOUNT_TYPE: "Account Type:",
ACCOUNT_TYPE_TOOLTIP: "Current status of Account",

TRIAL: "TRIAL",

SUBSCRIPTION: "Subscription:",
SUBSCRIPTION_TOOLTIP: "Chosen subscritpion",

NONE: "None",

UPGRADE_ACCOUNT_BUTTON: "Upgrade Account",

SUBSCRIPTIONS_BUTTON: "Subscriptions",

APP_VERSION: "App Version:",
APP_VERSION_TOOLTIP: "Current application version",

NA: "NA",

INVOICES_CREATED: "Invoices Created:",
INVOICES_CREATED_TOOLTIP: "Total number of invoices created since account creation",
INVOICES_CREATED_VALUE: "0",

ESTIMATES_CREATED: "Estiamtes Created:",
ESTIMATES_CREATED_TOOLTIP: "Total number of estimates created since account creation",
ESTIMATES_CREATED_VALUE: "0",

CHANGE_EMAIL_BUTTON: "Change Email",

CHANGE_PASSWORD_BUTTON: "Change Password",

ENABLE_TEXT_SEARCH: "Enable Live Text Field Searches",
ENABLE_TEXT_SEARCH_TOOLTIP: "This feature is used to copy already created contacts or items into a documents contact, or items list",
    
AUTO_SET_DUE_DATE: "Auto Set Due Date",
AUTO_SET_DUE_DATE_TOOLTIP: "On document creation, automatically set the due date for the document x days in advance",

AUTO_SET_DUE_DATE: "Auto Set Due Date",
AUTO_SET_DUE_DATE_TOOLTIP: "On document creation, automatically set the due date for the document x days in advance",

INVOICE_TAG_TOOLTIP: "The tag attached to each invoice ID. Ex ID: 'INV0001'",
INVOICE_TAG_LABEL: "Invoice Tag",

ESTIMATE_TAG_TOOLTIP: "The tag attached to each invoice ID. Ex ID: 'EST0001'",
ESTIMATE_TAG_LABEL: "Estimate Tag",
}




//        ITEM STRINGS
export const CONST_ITEM = {
    TITLE: "Item",
    
    DESCRIPTION_LABEL: "Description",
    DESCRIPTION_PLACEHOLDER: "Description",

        
    UNITCOST_LABEL: "Unit Cost",
    UNITCOST_PLACEHOLDER: "Description",

    QUANTITY_LABEL: "Quantity",
    QUANTITY_PLACEHOLDER: "Quantity",
    
    UNIT_LABEL: "Unit",
    UNIT_PLACEHOLDER: "Unit",

    UNITS: {
        NONE: {
            TITLE: "None",
            OPTIONS: []
        } ,
        TIME: {
            TITLE: "Time",
            OPTIONS: ["min", "hr", "day"]
        } ,
        LENGTH: {
            TITLE: "Length",
            OPTIONS: ["cm", "m", "inch", "ft"]
        } ,
        AREA: {
            TITLE: "Area",
            OPTIONS: ["cm", "m", "inch", "ft"],
            SYMBOL: ['\u00B2','\u00B2','\u00B2','\u00B2']
        } ,
        VOLUME: {
            TITLE: "Volume",
            OPTIONS: ["cm", "m", "inch", "ft"],
            SYMBOL: ['\u00B3','\u00B3','\u00B3','\u00B3']
        },
        WEIGHT: {
            TITLE: "Weight",
            OPTIONS: ["lbs", "T", "gm", "kg"]
                } 
    },

    SUBTOTAL_LABEL: "Sub Total",
    SUBTOTAL_PLACEHOLDER: "Sub Total",

    DISCOUNT_TYPE_LABEL: "Discount Type",
    DISCOUNT_TYPE_PLACEHOLDER: "Discount Type",
    DISCOUNT_TYPE_HELPER_TEXT: "Select Discount Type",

    DISCOUNT_AMOUNT_LABEL: "Discount Amount",
    DISCOUNT_AMOUNT_PLACEHOLDER: "Discount Amount",

    DISCOUNT_VALUE_TOTAL_LABEL: "Discount Value Total",
    DISCOUNT_VALUE_TOTAL_PLACEHOLDER: "Discount Value Total",

    AFTER_DISCOUNT_AMOUNT_LABEL: "After Discount Amount",
    AFTER_DISCOUNT_AMOUNT_PLACEHOLDER: "After Discount Amount",
    
    TAXABLE_LABEL: "Taxable",
    TAXABLE_PLACEHOLDER: "Taxable",
        
    TAX_RATE_LABEL: "Tax Rate",
    TAX_RATE_PLACEHOLDER: "Tax Rate",

    AFTER_TAX_AMOUNT_LABEL: "After Tax Amount",
    AFTER_TAX_AMOUNT_PLACEHOLDER: "After Tax Amount",
    
    ADDITIONAL_DETAILS_LABEL: "Additional Details",
    ADDITIONAL_DETAILS_PLACEHOLDER: "Additional Details",

    DATE_CREATED_LABEL: "Date Created",
    DATE_CREATED_PLACEHOLDER: "Date Created",
    
       }


