import React, { useState, useEffect } from "react"
import CustomTextField from '../../StandardComponents/CustomTextField';
import BasicDatePicker from '../../StandardComponents/BasicDatePicker';
import Collapse from '@material-ui/core/Collapse';

import SimpleDiscountMenu from '../../StandardComponents/SimpleDiscountMenu';


import { makeStyles } from '@material-ui/core/styles';
import { db } from "../../../firebase"
import { useAuth } from "../../../contexts/AuthContext"

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { Typography } from "@material-ui/core";
import GeneralViewContainer from "../../StandardComponents/GeneralViewContainer";
import * as database from '../../../constants/database'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  }

  
}));



function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}



var invoiceDiscountTypeBefore = "";
var invoiceDiscountTypeLive = "";

var invoiceDiscountValueBefore = "";
var invoiceDiscountValueLive = "";



export default function Settings(props) {



  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var documentsRecordsRef = userRef.collection(database.TABLE_NAME_INVOICES);


  var specificItemRef;

  function setQuery(){

    var docId = localStorage.getItem('doId');
    console.log("WILL NOW SEARCH ID: " +localStorage.getItem('itId'));
    if(!docId)
    {
       props.startSnackBar(true, 20000,"No Contact Provided","error",true)
        return;
    }
    specificItemRef = documentsRecordsRef.doc(docId);
    
  }


  var [loading, setLoading] = useState(false)


  const [invoiceDiscountType, setInvoiceDiscountType] = useState("")

  const [invoiceDiscountValue, setInvoiceDiscountValue] = useState("")
  const [showDiscountValue, setshowDiscountValue] = useState(false)



  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  async function writeUserData() {
    setLoading(true)
    return specificItemRef.set({
      invoiceDiscountType: invoiceDiscountType,
      invoiceDiscountValue: invoiceDiscountValue,
    },{ merge: true })
    .then(() => {
      setLoading(false)
      setError("")

      
      props.setSaveState(false);

      props.startSnackBar(true, 2000,"Saved","success",true)

      invoiceDiscountTypeBefore = invoiceDiscountTypeLive
      invoiceDiscountValueBefore = invoiceDiscountValueLive

  })
  .catch((error) => {
      props.startSnackBar(true, 20000,"Failed to get data. "+error.code,"error",true)

      setLoading(false)
  });
  }





  const fetchData=async()=>{
  return specificItemRef.get().then((doc) => {
    if (doc.exists) {
 
        
          setInvoiceDiscountType(doc.data().invoiceDiscountType);
          invoiceDiscountTypeBefore = doc.data().invoiceDiscountType;
          invoiceDiscountTypeLive = doc.data().invoiceDiscountType;
          handleSelected(doc.data().invoiceDiscountType)
        
        if(doc.data().invoiceDiscountValue)
        {
          setInvoiceDiscountValue(doc.data().invoiceDiscountValue);
          invoiceDiscountValueBefore = doc.data().invoiceDiscountValue;
          invoiceDiscountValueLive = doc.data().invoiceDiscountValue;
        }
      
      

    } else {
      props.startSnackBar(true, 20000,"No such data","error",true)

    }
}).catch((error) => {
  props.startSnackBar(true, 20000,"Problem getting data. "+error.code,"error",true)

});

}



useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleSaveData()
  }

}, [props.activateSave])


useEffect(async () => {
  // Update the document title using the browser API
  props.pageDisplayStatus((pageState) => ({...pageState, backButton:true, mainTab:false, invoiceEstimateTab:false, edPrHiTab: false}))

  setLoading(true)
  
try{
  setError("")
  setQuery()
  fetchData();
  props.setSaveState(false);
}
catch(error){
  props.startSnackBar(true, 20000,"Failed to get data. "+error.code,"error",true)
}
  setLoading(false)
}, [])

  function checkForChanges(){

    if((invoiceDiscountTypeLive!==invoiceDiscountTypeBefore)||
    (invoiceDiscountValueLive!==invoiceDiscountValueBefore))
    {
      props.setSaveState(true);
    }
    else
    {
      props.setSaveState(false);
    }
  }


  const classes = useStyles();

  const [error, setError] = useState("")

  function handleSaveData(){
 
    setQuery()
    writeUserData()
 
  }


function enterKeyDown(val){
  if (val && props.currentSaveState) {
    handleSaveData();
    }
}

  const updateText = (e)=> {

    switch(e.target.id) {
      case "invoiceDiscountType":
        setInvoiceDiscountType(e.target.value);
        invoiceDiscountTypeLive = e.target.value;
      break;
      case "invoiceDiscountValue":
        setInvoiceDiscountValue(e.target.value);
        invoiceDiscountValueLive = e.target.value;
        break;

    }

    checkForChanges();
  };

  function handleSelected (val) {

    switch(val) {
      case "No Discount":
        setInvoiceDiscountType(val);
        setInvoiceDiscountValue("")
        invoiceDiscountTypeLive = val;
        setshowDiscountValue(false)
      break;
      case "Percentage":
        setInvoiceDiscountType(val);
        invoiceDiscountTypeLive = val;
        setshowDiscountValue(true)
        break;
      case "Flat Amount":
        setInvoiceDiscountType(val);
        invoiceDiscountTypeLive = val;
        setshowDiscountValue(true)
        break;
      default:
        setInvoiceDiscountType("No Discount");
        setInvoiceDiscountValue("")
        setshowDiscountValue(false)
    }

    checkForChanges();
  };


  return (
    <>

<GeneralViewContainer maxWidth="lg">
      <Grid container spacing={1}>

        <Grid item xs={12}>
        <Typography variant="h5" component="h5">Discount</Typography>
        </Grid>

        <Grid item xs={12}>
        <Box borderTop={1} borderColor="primary.main">
        </Box>
        </Grid>


  <Grid item xs={12}>
      <Tooltip title="Discount">
  {/* <CustomTextField disabled={notEmpty(error)} updateText={updateText}  id="invoiceDiscountType" label="Discount" value={invoiceDiscountType} enterKeyDown={enterKeyDown}/> */}
  <SimpleDiscountMenu value={invoiceDiscountType} returnSelected={handleSelected}/>

  </Tooltip>
  </Grid>





  <Grid item xs={12}>
  <Collapse in={showDiscountValue}>
      <Tooltip title="Terms">
  <CustomTextField disabled={notEmpty(error)} updateText={updateText}  id="invoiceDiscountValue" label={invoiceDiscountType+" Value"} value={invoiceDiscountValue} enterKeyDown={enterKeyDown}/>
  </Tooltip>
  </Collapse>
  </Grid>


    </Grid>
    </GeneralViewContainer>

    </>
  )
}
