import React, {useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import { positions } from '@material-ui/system';
import {  useHistory } from "react-router-dom"

import Folder from '@material-ui/icons/Folder';
import EmojiObjects from '@material-ui/icons/EmojiObjects';
import Contacts from '@material-ui/icons/Contacts';

const useStyles = makeStyles({
  width: 500,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function MainTab(props) {
  const history = useHistory()

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch(event.currentTarget.id) {
      case "1":
        history.push("/dashboard/documents")
        break;
      case "2":
        history.push("/dashboard/items")
        break;
      case "3":
        history.push("/dashboard/contacts")
        break;
    }

  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };




  
  useEffect(async () => {

    console.log("DATA HERE-->: "+props.chosenTab)
    setValue(props.chosenTab)
 
  
  }, [props.chosenTab])




  return (

    
    <div className={classes.root}>


    {/* <Box display="flex" justifyContent="center" zIndex="modal" >
    <Paper square elevation={4} > */}
              {props.children}
              {/* <AppBar position="static" color="default">
      <Tabs
        value={value}
        variant="fullWidth"
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab icon={<Folder />} label="DOCUMENTS" {...a11yProps(0)} />
        <Tab icon={<EmojiObjects />} label="ITEMS" {...a11yProps(1)} />
        <Tab icon={<Contacts />} label="CONTACTS" {...a11yProps(2)}/>
      </Tabs>
      </AppBar> */}
<Hidden smDown>
    <Paper className={classes.root}>
      <Tabs
        value={value}
        // variant="fullWidth"
        onChange={handleChange}
        centered
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab id="1" icon={<Folder />} label="DOCUMENTS"  />
        <Tab id="2" icon={<EmojiObjects />} label="ITEMS"  />
        <Tab id="3" icon={<Contacts />} label="CONTACTS" />
      </Tabs>
      </Paper>
      </Hidden>


      <Hidden mdUp>
    <Paper className={classes.root}>
      <Tabs
        value={value}
        // variant="fullWidth"
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab id="1" icon={<Folder />} label="DOCUMENTS"  />
        <Tab id="2" icon={<EmojiObjects />} label="ITEMS"  />
        <Tab id="3" icon={<Contacts />} label="CONTACTS" />
      </Tabs>
      </Paper>
      </Hidden>
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          Item One
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
      </SwipeableViews> */}

    {/* </Paper>

    </Box> */}
    </div>
  );
}
