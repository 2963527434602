import React, { useState, useEffect } from "react"
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import useWindowDimensions from '../util/useWindowDimensions';
import FormatDate from '../util/FormatDate';
import FormatCurrencyCustom from '../util/FormatCurrencyCustom';
import valueEmpty from '../util/valueEmpty';
import ShowNoData from '../StandardComponents/ShowNoData';
import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";
import { useHistory } from "react-router-dom"

import * as database from '../../constants/database';

import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Container from '@material-ui/core/Container';

import Collapse from '@material-ui/core/Collapse';
import CheckMaxLength from '../../validate/CheckMaxLength';



import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';
import Folder from '@material-ui/icons/Folder';


import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  },
  list_color: {
    backgroundColor: '#ffffff',
  }

  
}));





  


function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}


var documentRecordId = [];
var documentRecordClient = [];
var documentRecordNumber = [];
var documentRecordDate = [];
var documentRecordBalance = [];



export default function Documents(props) {

  const history = useHistory()


    const [documentRecords, updateDocumentRecords] = useState({
        documentCount: 0,
        documentRecordId: [],
        documentRecordClient: [],
        documentRecordNumber: [],
        documentRecordDate: [],
        documentRecordBalance: []
    });

    const { height, width } = useWindowDimensions();

 
  const { currentUser } = useAuth()

  console.log('userId: '+currentUser.uid)
  var userRef = db.collection("users").doc(currentUser.uid);
  var invoiceRecordsRef = userRef.collection(database.TABLE_NAME_INVOICES);
  var queryInvoices = invoiceRecordsRef.where("invoiceDocumentType", "==", "INVOICE").orderBy("invoiceFirstCreatedDate", "desc").limit(200);
  var queryEstimates = invoiceRecordsRef.where("invoiceDocumentType", "==", "ESTIMATE").orderBy("invoiceFirstCreatedDate", "desc").limit(200);


  var businessRef = userRef.collection(database.TABLE_NAME_BUSINESS_INFO).doc("1");

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    

  var [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  async function updateProfileEmail(email) {

    return businessRef.set({
      businessInfoEmail: email
    },{ merge: true });
  }



  const fetchData=async()=>{
    setLoading(true)
 
    var queryToUse = "";
    console.log("FETCH DATA"+props.chosenInvoicesEstimates)


    if(props.chosenInvoicesEstimates==="estimates")
    {
      queryToUse = queryEstimates;
    }
    else
    {
      queryToUse = queryInvoices;
    }
 


    queryToUse.onSnapshot((querySnapshot) => {
    var counter = 0; 
    documentRecordId = [];
    documentRecordClient = [];
    documentRecordNumber = [];
    documentRecordDate = [];
    documentRecordBalance = [];

    querySnapshot.forEach((doc) => {
      counter += 1;


        documentRecordId.push(doc.data()[database.COLUMN_NAME_INVOICE_DOCUMENTID]);
        documentRecordClient.push(doc.data()[database.COLUMN_NAME_INVOICE_CLIENTNAME]);
        documentRecordNumber.push(doc.data()[database.COLUMN_NAME_INVOICE_NUMBER]);
        documentRecordDate.push(FormatDate(valueEmpty(doc.data()[database.COLUMN_NAME_INVOICE_CREATEDDATE])));
        documentRecordBalance.push(FormatCurrencyCustom(valueEmpty(doc.data()[database.COLUMN_NAME_INVOICE_BALANCEDUE])));
        
     

    });

    updateDocumentRecords({
      documentCount: counter,
      documentRecordId: documentRecordId,
      documentRecordClient: documentRecordClient,
      documentRecordNumber: documentRecordNumber,
      documentRecordDate: documentRecordDate,
      documentRecordBalance: documentRecordBalance
  });
  setLoading(false)

}, (error) => {
  console.log(error)

    props.startSnackBar(true, 20000,"Problem getting data. "+error.code,"error",true)
    setLoading(false)
});
}


useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleOnClick()
  }

}, [props.activateSave])



useEffect(async () => {

  console.log("START DOCUMENTS: "+props.chosenInvoicesEstimates)

  documentRecordId = [];
  documentRecordClient = [];
  documentRecordNumber = [];
  documentRecordDate = [];
  documentRecordBalance = [];  
  fetchData()

}, [props.chosenInvoicesEstimates])


useEffect(async () => {
  // Update the document title using the browser API  // props.setSaveState(true);


  props.pageDisplayStatus((pageState) => ({...pageState, backButton:false, mainTab:true, chosenMainTab:"documents", invoiceEstimateTab:true, edPrHiTab: false}))


  setError("")
  fetchData();

}, [])

  const classes = useStyles();

  const [error, setError] = useState("")

  function handleOnClick(e){
    e.preventDefault();
    var clickedId = e.currentTarget.id;
    if(clickedId)
    {
        localStorage.setItem('doId', clickedId);
        history.push("/dashboard/document")
    }
    else
    {
        props.startSnackBar(true, 20000,"Invalid Contact Id","error",true)
    }
  }



  
  function renderRow(props) {
    const { index, style } = props;
  
    return (
        <Fade timeout={700} in={true}>
      <ListItem button style={style} key={index} id={documentRecordId[index]} onClick={handleOnClick} > 
    {/* <Paper elevation={5} square={false}> */}
    <Box width="100%"  boxShadow={1} >

    <div style={{ padding: 5 }} className={classes.list_color}>
    <Grid container spacing={0}>
        <Grid item xs={8}>
        <ListItemText primary={documentRecordClient[index]} />
        </Grid>
        <Grid item xs={4}>
        <ListItemText primary={documentRecordBalance[index]}/>
        </Grid>
            <Grid item xs={8}>
            <ListItemText secondary={documentRecordNumber[index]}/>
            </Grid>
            <Grid item xs={4}>
            <ListItemText secondary={documentRecordDate[index]}/>
            </Grid>
        </Grid>
        </div>
        </Box>
      </ListItem>
      </Fade>
    );
  }
  
  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };
  

  return (
    <>
      <GeneralViewContainer maxWidth="md" disableGutters={true}>
{(documentRecords.documentCount!=0) &&
   
        <FixedSizeList height={documentRecords.documentCount*80} width={'100%'} itemSize={80} itemCount={documentRecords.documentCount} >
          {renderRow}
        </FixedSizeList>

}

<ShowNoData height={height} chosenImage="Folder" in={documentRecords.documentCount==0}/>
       
       </GeneralViewContainer>
    </>
  )
}
