import toFloat from './toFloat';


export default function FormatCurrencyCustom(dollars) {
  // deal with strings
  if (!Number.isInteger(dollars))
  {
    // dollarsLong = par(dollars);
    dollars = toFloat(dollars);
  }
  if(dollars<0)
  {
    return "-$" + (Math.abs(dollars)).toFixed(2);
  }
  else{
    return "$" + (dollars).toFixed(2);
  }

}