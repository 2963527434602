import React, { useRef, useState, useEffect } from "react"
// import { Form, Button, Card, Alert } from "react-bootstrap"
// import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Alert from '@material-ui/lab/Alert';

import {CONST_ERROR, CONST_SUCCESS, CONST_SUPPORT} from '../../constants/string'
import * as database from '../../constants/database'


import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";




import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"

import { makeStyles } from '@material-ui/core/styles';
import Email from '@material-ui/icons/Email';



export default function Support(props) {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
//   const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    button: {
        width: '100%',
        maxWidth: 360,
        spacing: 8,
    }
  }));

  const classes = useStyles();

  const { currentUser } = useAuth()


  
  


function mail(){

    var yourMessage = CONST_SUPPORT.EMAIL_MESSAGE
    var subject = CONST_SUPPORT.EMAIL_SUBJECT
    document.location.href = CONST_SUPPORT.EMAIL_TO
        + encodeURIComponent(subject)
        + "&body=" + encodeURIComponent(yourMessage);
}


  useEffect(async () => {
    // Update the document title using the browser API
  
    props.pageDisplayStatus((pageState) => ({...pageState,backButton:true, mainTab:false, invoiceEstimateTab:false, edPrHiTab: false}))

    
     
    setLoading(false)
  
  }, [])
  

  return (
    <>

<GeneralViewContainer maxWidth="lg">

<Grid container spacing={1}>

<Grid item xs={12}>
<Typography variant="h5" component="h5">{CONST_SUPPORT.TITLE}</Typography>
</Grid>

<Grid item xs={12}>
<Box borderTop={1} borderColor="primary.main">
</Box>
</Grid>


<Grid item xs={12}>
<Typography variant="h6" component="h6">{CONST_SUPPORT.HAVING_ISSUES}</Typography>
</Grid>



<Grid item xs={12}>
<Typography variant="body1" component="h2">{CONST_SUPPORT.SEND_US_EMAIL}</Typography>
</Grid>

<Grid item xs={12} md={4}>
<Button variant="contained" color="primary" className={classes.button} onClick={mail}>
  {CONST_SUPPORT.EMAIL_NOW}
</Button>
</Grid>


</Grid>
</GeneralViewContainer>

    </>
  )
}
