import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomLoading(props) {
  const classes = useStyles();


  return (

    <Fade timeout={1000} in={props.setLoading}>
    <div className={classes.root}>

{/* Show on XL and LG */}
      <Hidden mdDown implementation="css">
      <Box
        bgcolor="transparent"
        color="text.primary"
        p={2}
        position="absolute"
        top="50%"
        left="45%"
        zIndex="modal"
        width="10%"
      >
        <Container>
          
        <LinearProgress />
        </Container>
      </Box>
      </Hidden>

{/* Show on Md */}
      <Hidden xsDown lgUp implementation="css">
      <Box
        bgcolor="transparent"
        color="text.primary"
        p={2}
        position="absolute"
        top="50%"
        left="40%"
        zIndex="modal"
        width="20%"
      >
        <Container>
          
        <LinearProgress />
        </Container>
      </Box>
      </Hidden>

{/* Show on xs */}
      <Hidden smUp implementation="css">
      <Box
        bgcolor="transparent"
        color="text.primary"
        p={2}
        position="absolute"
        top="50%"
        left="32.5%"
        zIndex="modal"
        width="35%"
      >
        <Container>
          
        <LinearProgress />
        </Container>
      </Box>
      </Hidden>
    </div>
    </Fade>
  );
}
