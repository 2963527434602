import toFloat from './toFloat';


export default function AddComma(string) {
  // deal with strings
  if (string)
  {
    string += ", "
  }
  return string

}