import EditDocument from "./EditDocument";
import Preview from "./Preview";
import History from "./History";



export default function Document(props){

return (



    <EditDocument {...props}/>



)


}