import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CustomTextField from './CustomTextField';

export default function SimpleTaxMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null)
    props.returnSelected(e.currentTarget.id)
  };

  return (
    <div>
        <CustomTextField {...props} onClick={handleClick}/>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id="None" onClick={handleClose}>None</MenuItem>
        <MenuItem id="On The Total" onClick={handleClose}>On The Total</MenuItem>
        <MenuItem id="Deducted" onClick={handleClose}>Deducted</MenuItem>
        <MenuItem id="Per Item" onClick={handleClose}>Per Item</MenuItem>

      </Menu>
    </div>
  );
}
