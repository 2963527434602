import React, { useEffect, useState } from "react"
import Signup_1 from "./Signup_1"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./Dashboard/Dashboard"
import Login from "./Login"
// import Home from "./Home/Home"

import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./ForgotPassword"
import UpdateProfile from "./UpdateProfile"
import Signup from "./Signup"
import Profile from './Dashboard/Profile';
import Settings from './Dashboard/Settings';
import Account from './Dashboard/Account';
import Documents from './Dashboard/Documents';
import Items from './Dashboard/Items';
import Contacts from './Dashboard/Contacts';
import EditContact from './Dashboard/EditContact';
import EditItem from './Dashboard/EditItem';
import Document from './Dashboard/Document';
import Details from './Dashboard/Document/Details';
import Discount from './Dashboard/Document/Discount';
import Tax from './Dashboard/Document/Tax';
import PaymentInstructions from './Dashboard/Document/PaymentInstructions';
import Notes from './Dashboard/Document/Notes';
import Signature from './Dashboard/Document/Signature';




import { green, orange, blue } from '@material-ui/core/colors';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';






import Support from './Dashboard/Support';

import CustomDrawer from './Dashboard/CustomDrawer';




function App() {

  const [saveState, updateSaveState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [pageDisplayStatus, updatePageDisplayStatus] = useState({
    backButton: false,
    mainTab: false,
    chosenMainTab: "",
    invoiceEstimateTab: false,
    chosenInvoicesEstimatesTab: "",
    edPrHiTab: false,
    chosenEdPrHiTab: ""
  });


  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#16568e',
      },
      secondary: {
        main: '#607d8b',
      },
    },
  });




  // <color name="colorPrimary">#16568e</color>
  // <color name="colorPrimaryLighter">#a2ccf1</color>

  // <color name="colorPrimaryDark">#002e60</color>
  // <color name="colorAccent">#5282bf</color>

  // <color name="colorSecondary">#607d8b</color>
  


  // useEffect(async () => {

  //   console.log("-----HERE: "+pageDisplayStatus.chosenInvoicesEstimatesTab)
  
  
  // }, [pageDisplayStatus.chosenInvoicesEstimatesTab])

  // SnackBar Control
  const [snackBar, setSnackBar] = useState({
    snackBarTime: 0,
    snackBarSeverity: "success",
    snackBarMessage: "",
    snackBarShow: false,
    bottomTabState: false
  })


  const [activateSave, activateStartSave] = useState(false);
  const [customContactsUpdateFlag, setCustomContactsUpdateFlag] = useState(false);

    
  async function setSaveState(state)
  {
    updateSaveState(state);
    activateStartSave(false);

    console.log("UPDATED");
  }

  // async function chosenTabSelect(tab)
  // {
  //   updateChosenTab(tab);
  //   console.log("TAB: "+tab)
  // }


  async function startSave()
  {
    activateStartSave(true);
  }

  async function handleSnackBar(show,time,message,severity,tab)
  {
    setSnackBar({
      snackBarTime: time,
      snackBarSeverity: severity,
      snackBarMessage: message,
      snackBarShow: show,
      bottomTabState: tab
    });
  }

  async function snackBarFinished()
  {
    setSnackBar({
      snackBarTime: 0,
      snackBarSeverity: "success",
      snackBarMessage: "",
      snackBarShow: false,
      bottomTabState: false
    });
  }


  async function snackBarFinished()
  {
    setSnackBar({
      snackBarTime: 0,
      snackBarSeverity: "success",
      snackBarMessage: "",
      snackBarShow: false,
      bottomTabState: false
    });
  }


  async function handleLoadingState(state)
  {
    setLoadingState(state);
  }

  async function setCustomContactsFlag(state)
  {
    console.log("WAS HERE "+state)
    setCustomContactsUpdateFlag(state);
  }



  return (
    <ThemeProvider theme={theme}>

      
        <Router>
          <AuthProvider>
            <Switch>

            <Route path="/Signup_1" component={Signup_1} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/" component={Login} />

              {/* <Route exact path="/" component={Home} /> */}

              {/* <Route exact path="/test" component={Settings} /> */}

              <PrivateRoute path="/dashboard" >
              <Route render={(props) => <Dashboard {...props} 
              currentSaveState={saveState} 
              startSave={startSave} 
              snackBarShow={snackBar.snackBarShow} 
              snackBarBottomTabState={snackBar.bottomTabState} 
              snackBarTime={snackBar.snackBarTime} 
              snackBarSeverity={snackBar.snackBarSeverity} 
              snackBarMessage={snackBar.snackBarMessage} 
              snackBarFinished={snackBarFinished}
              loading={loadingState}
              pageDisplayStatus={pageDisplayStatus}
              updatePageDisplayStatus={updatePageDisplayStatus}
              
              
              >
              {/* <PrivateRoute path="/dashboard/profile" >
                <Route path="/dashboard/profile" component={Profile} />
                </PrivateRoute> */}

                
                <PrivateRoute path="/dashboard/profile" >
                  <Profile setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/settings" >
                  <Settings setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/account" >
                  <Account setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/documents" >
                  <Documents setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus} chosenInvoicesEstimates={pageDisplayStatus.chosenInvoicesEstimatesTab}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/items" >
                  <Items setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageState={pageDisplayStatus} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                
                <PrivateRoute path="/dashboard/contacts" >
                  <Contacts setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/editcontact" >
                  <EditContact setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/edititem" >
                  <EditItem setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/document" >
                  <Document setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/details" >
                  <Details setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/client" >
                  <EditContact setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                
                <PrivateRoute path="/dashboard/discount" >
                  <Discount setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/tax" >
                  <Tax setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/paymentinstructions" >
                  <PaymentInstructions setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/notes" >
                  <Notes setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/signature" >
                  <Signature setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                <PrivateRoute path="/dashboard/support" >
                  <Support setSaveState={setSaveState} currentSaveState={saveState} activateSave={activateSave} startSnackBar={handleSnackBar} handleLoadingState={handleLoadingState} pageDisplayStatus={updatePageDisplayStatus}/>
                </PrivateRoute>

                </Dashboard>} />
              </PrivateRoute>

     
              </Switch>
          </AuthProvider>
        </Router>
</ThemeProvider>
   
  )
}

export default App
