import React, {useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, withStyles } from '@material-ui/core/styles';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  const bottom1 = { bottom: 20 };
const bottom3 = { bottom: 40 };
const center = {
  left: '70%',
  right: 'auto',
  transform: 'translateX(-30%)',
};

  // const useStyles = withStlyles((theme) => ({
  //   customZIndex: {
  //     zindex: 2000
    
  
  //   },
  //   overrides: {

  //     anchorOriginBottomCenter: {
  //       ...bottom1,
  //       [theme.breakpoints.up('sm')]: {
  //         ...bottom3,
  //         ...center,
  //       },
  //     },
  //   }
  // }));



  const StyledSnackbar = withStyles({
    root: {
      bottom: '12%',
    },
  })(Snackbar);

 



export default function CustomSnackBar(props) {

  // const classes = useStyles();

  function handleClose() {
 
    props.onStatusChange()
  };




  return (
    <div>
 
    <StyledSnackbar open={props.show && props.withTab} autoHideDuration={props.time} onClose={handleClose}>
    <Alert severity={props.severity}>
      {props.message}
    </Alert>
  </StyledSnackbar>



<Snackbar bottom={100} open={props.show && !props.withTab} autoHideDuration={props.time} onClose={handleClose}>
<Alert severity={props.severity}>
  {props.message}
</Alert>
</Snackbar>
</div>
  );
}
