import React, { useState, useEffect } from "react"
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import CustomTextField from '../StandardComponents/CustomTextField';
import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";

import {CONST_ERROR, CONST_SUCCESS, CONST_ITEM} from '../../constants/string'
import * as database from '../../constants/database'
import * as localstorage from '../../constants/localstorage'

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';
import CheckMaxLength from '../../validate/CheckMaxLength';

import Menu from '@material-ui/core/Menu';

import MenuItem from '@material-ui/core/MenuItem';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';


import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
    paper: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },


  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  }

  
}));



function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}



var customItemListDocumentIdBefore = "";
var customItemsDescriptionBefore  = "";
var customItemsQuantityBefore  = "";
var customItemsDiscountTypeBefore  = "";
var customItemsDiscountAmountBefore  = "";
var customItemsDiscountValueTotalBefore  = "";
var customItemsTaxableBefore  = "";
var customItemsTaxRateBefore = "";
var customItemsUnitCostBefore = "";
var customItemsUnitBefore = "";
var customItemsSubTotalBefore = "";
var customItemsAfterDiscountAmountBefore = "";
var customItemsAfterTaxAmountBefore = "";
var customItemsAdditionalDetailsBefore = "";
var customItemsDateCreatedBefore = "";

var customItemListDocumentIdLive= "";
var customItemsDescriptionLive = "";
var customItemsQuantityLive = "";
var customItemsDiscountTypeLive = "";
var customItemsDiscountAmountLive = "";
var customItemsDiscountValueTotalLive = "";
var customItemsTaxableLive = "";
var customItemsTaxRateLive = "";
var customItemsUnitCostLive = "";
var customItemsUnitLive = "";
var customItemsSubTotalLive = "";
var customItemsAfterDiscountAmountLive = "";
var customItemsAfterTaxAmountLive = "";
var customItemsAdditionalDetailsLive = "";
var customItemsDateCreatedLive = "";





var itemId;
var isCus;

export default function EditItem(props) {

  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var customItemsRecordsRef = userRef.collection(database.TABLE_NAME_CUSTOM_ITEMS);
  var invoiceRecordsRef = userRef.collection(database.TABLE_NAME_INVOICE_ITEMLIST);

  var specificItemRef;

  itemId = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_ITEMID);
  isCus = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_ITEMISCUSTOM);

  function setQuery(){


    itemId = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_ITEMID);
    isCus = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_ITEMISCUSTOM);


    if(!itemId)
    {
       props.startSnackBar(true, 20000,CONST_ERROR.NO_CONTACT_PROVIDED,"error",true)
        return;
    }

    if(isCus === "true")
    {
      specificItemRef = customItemsRecordsRef.doc(itemId);
    }
    else
    {
      specificItemRef = invoiceRecordsRef.doc(itemId);      
    }

  }

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    
  const [autoSetDateVisible, setAutoSetDateVisible] = useState(false)

  var [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState([]);





 




  const [customItemListDocumentId, setCustomDocumentId] = useState("");
  const [customItemsDescription, setCustomDescription] = useState("");
  const [customItemsQuantity, setCustomQuantity] = useState("");
  const [customItemsDiscountType, setCustomDiscountType] = useState("");
  const [customItemsDiscountAmount, setCustomDiscountAmount] = useState("");
  const [customItemsDiscountValueTotal, setCustomDiscountValueTotal] = useState("");
  const [customItemsTaxable, setCustomTaxable] = useState("");
  const [customItemsTaxRate, setCustomTaxRate] = useState("");
  const [customItemsUnitCost, setCustomUnitCost] = useState("");
  const [customItemsUnit, setCustomUnit] = useState("");
  const [customItemsSubTotal, setCustomSubTotal] = useState("");
  const [customItemsAfterDiscountAmount, setCustomAfterDiscountAmount] = useState("");
  const [customItemsAfterTaxAmount, setCustomAfterTaxAmount] = useState("");
  const [customItemsAdditionalDetails, setCustomAdditionalDetails] = useState("");
  const [customItemsDateCreated, setCustomDateCreated] = useState("");




  function enterKeyDown(val)
  {
    if (val && props.currentSaveState) {
      handleSaveData();
      }
  }


  
  

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  async function writeUserData() {
    setLoading(true)


    if(isCus === "true")
    {
    return specificItemRef.set({
      [database.COLUMN_NAME_CUSTOM_ITEMS_DOCUMENTID]: customItemListDocumentId,
      [database.COLUMN_NAME_CUSTOM_ITEMS_DESCRIPTION]: customItemsDescription,
      [database.COLUMN_NAME_CUSTOM_ITEMS_QUANTITY]: customItemsQuantity,
      [database.COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTTYPE]: customItemsDiscountType,
      [database.COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTAMOUNT]: customItemsDiscountAmount,
      [database.COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTVALUETOTAL]: customItemsDiscountValueTotal,
      [database.COLUMN_NAME_CUSTOM_ITEMS_TAXABLE]: customItemsTaxable,
      [database.COLUMN_NAME_CUSTOM_ITEMS_TAXRATE]: customItemsTaxRate,
      [database.COLUMN_NAME_CUSTOM_ITEMS_UNITCOST]: customItemsUnitCost,
      [database.COLUMN_NAME_CUSTOM_ITEMS_UNIT]: customItemsUnit,
      [database.COLUMN_NAME_CUSTOM_ITEMS_SUBTOTAL]: customItemsSubTotal,
      [database.COLUMN_NAME_CUSTOM_ITEMS_AFTERDISCOUNTAMOUNT]: customItemsAfterDiscountAmount,
      [database.COLUMN_NAME_CUSTOM_ITEMS_AFTERTAXAMOUNT]: customItemsAfterTaxAmount,
      [database.COLUMN_NAME_CUSTOM_ITEMS_ADDITIONALDETAILS]:customItemsAdditionalDetails,
      [database.COLUMN_NAME_CUSTOM_ITEMS_DATECREATED]: customItemsDateCreated

    },{ merge: true })
    .then(() => {
      setLoading(false)
      setError("")
      
      props.setSaveState(false);
      // setSnackBar({show: true, time: 2000, message: "Saved", severit: "success"})
      props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)

      customItemListDocumentIdBefore = customItemListDocumentIdLive;
      customItemsDescriptionBefore = customItemsDescriptionLive;
      customItemsQuantityBefore = customItemsQuantityLive;
      customItemsDiscountTypeBefore = customItemsDiscountTypeLive;
      customItemsDiscountAmountBefore = customItemsDiscountAmountLive;
      customItemsDiscountValueTotalBefore = customItemsDiscountValueTotalLive;
      customItemsTaxableBefore = customItemsTaxableLive;
      customItemsTaxRateBefore = customItemsTaxRateLive;
      customItemsUnitCostBefore = customItemsUnitCostLive;
      customItemsUnitBefore = customItemsUnitLive;
      customItemsSubTotalBefore = customItemsSubTotalLive;
      customItemsAfterDiscountAmountBefore = customItemsAfterDiscountAmountLive;
      customItemsAfterTaxAmountBefore = customItemsAfterTaxAmountLive;
      customItemsAdditionalDetailsBefore =customItemsAdditionalDetailsLive;
      customItemsDateCreatedBefore = customItemsDateCreatedLive;

  })
  .catch((error) => {
      // setSnackBarError()
      props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)

      setLoading(false)
  });
    }
    else
    {
// Not Custom Item


      return specificItemRef.set({
        [database.COLUMN_NAME_INVOICE_ITEMLIST_DOCUMENTID]: customItemListDocumentId,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_DESCRIPTION]: customItemsDescription,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_QUANTITY]: customItemsQuantity,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTTYPE]: customItemsDiscountType,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTAMOUNT]: customItemsDiscountAmount,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTVALUETOTAL]: customItemsDiscountValueTotal,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_TAXABLE]: customItemsTaxable,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_TAXRATE]: customItemsTaxRate,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_UNITCOST]: customItemsUnitCost,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_UNIT]: customItemsUnit,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_SUBTOTAL]: customItemsSubTotal,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_AFTERDISCOUNTAMOUNT]: customItemsAfterDiscountAmount,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_AFTERTAXAMOUNT]: customItemsAfterTaxAmount,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_ADDITIONALDETAILS]:customItemsAdditionalDetails,
        [database.COLUMN_NAME_INVOICE_ITEMLIST_DATECREATED]: customItemsDateCreated
  
      },{ merge: true })
      .then(() => {
        setLoading(false)
        setError("")
        
        props.setSaveState(false);
        // setSnackBar({show: true, time: 2000, message: "Saved", severit: "success"})
        props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)
  
        customItemListDocumentIdBefore = customItemListDocumentIdLive;
        customItemsDescriptionBefore = customItemsDescriptionLive;
        customItemsQuantityBefore = customItemsQuantityLive;
        customItemsDiscountTypeBefore = customItemsDiscountTypeLive;
        customItemsDiscountAmountBefore = customItemsDiscountAmountLive;
        customItemsDiscountValueTotalBefore = customItemsDiscountValueTotalLive;
        customItemsTaxableBefore = customItemsTaxableLive;
        customItemsTaxRateBefore = customItemsTaxRateLive;
        customItemsUnitCostBefore = customItemsUnitCostLive;
        customItemsUnitBefore = customItemsUnitLive;
        customItemsSubTotalBefore = customItemsSubTotalLive;
        customItemsAfterDiscountAmountBefore = customItemsAfterDiscountAmountLive;
        customItemsAfterTaxAmountBefore = customItemsAfterTaxAmountLive;
        customItemsAdditionalDetailsBefore =customItemsAdditionalDetailsLive;
        customItemsDateCreatedBefore = customItemsDateCreatedLive;
  
    })
    .catch((error) => {
        // setSnackBarError()
        props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)
  
        setLoading(false)
    });







    }

  }






  


  const fetchData=async()=>{
  return specificItemRef.get().then((doc) => {
    if (doc.exists) {
 
      if(isCus === "true")
      {


        var customItemListDocumentId = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_DOCUMENTID]
        var customItemsDescription = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_DESCRIPTION]
        var customItemsUnitCost = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_UNITCOST]
        var customItemsQuantity = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_QUANTITY]
        var customItemsUnit = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_UNIT]
        var customItemsSubTotal = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_SUBTOTAL]
        var customItemsDiscountType = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTTYPE]
        var customItemsDiscountAmount = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTAMOUNT]
        var customItemsDiscountValueTotal = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_DISCOUNTVALUETOTAL]
        var customItemsAfterDiscountAmount = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_AFTERDISCOUNTAMOUNT]
        var customItemsTaxable = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_TAXABLE]
        var customItemsTaxRate = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_TAXRATE]
        var customItemsAfterTaxAmount = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_AFTERTAXAMOUNT]
        var customItemsAdditionalDetails = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_ADDITIONALDETAILS]
        var customItemsDateCreated = doc.data()[database.COLUMN_NAME_CUSTOM_ITEMS_DATECREATED]


        if(customItemListDocumentId)
        {
          setCustomDocumentId(customItemListDocumentId);
          customItemListDocumentIdBefore = customItemListDocumentId;
          customItemListDocumentIdLive = customItemListDocumentId;
        }
        if(customItemsDescription)
        {
          setCustomDescription(customItemsDescription);
          customItemsDescriptionBefore = customItemsDescription;
          customItemsDescriptionLive = customItemsDescription;
        }
        if(customItemsUnitCost)
        {
          setCustomUnitCost(customItemsUnitCost);
          customItemsUnitCostBefore = customItemsUnitCost;
          customItemsUnitCostLive = customItemsUnitCost;
        }
        if(customItemsQuantity)
        {
          setCustomQuantity(customItemsQuantity);
          customItemsQuantityBefore = customItemsQuantity;
          customItemsQuantityLive = customItemsQuantity;
        }
        if(customItemsUnit)
        {
          setCustomUnit(customItemsUnit);
          customItemsUnitBefore = customItemsUnit;
          customItemsUnitLive = customItemsUnit;
        }
        if(customItemsSubTotal)
        {
          setCustomSubTotal(customItemsSubTotal);
          customItemsSubTotalBefore = customItemsSubTotal;
          customItemsSubTotalLive = customItemsSubTotal;
        }
        if(customItemsDiscountType)
        {
            setCustomDiscountType(customItemsDiscountType);
            customItemsDiscountTypeBefore = customItemsDiscountType;
            customItemsDiscountTypeLive = customItemsDiscountType;
        }
        if(customItemsDiscountAmount)
        {
            setCustomDiscountAmount(customItemsDiscountAmount);
            customItemsDiscountAmountBefore = customItemsDiscountAmount;
            customItemsDiscountAmountLive = customItemsDiscountAmount;
        }
        if(customItemsDiscountValueTotal)
        {
          setCustomDiscountValueTotal(customItemsDiscountValueTotal);
          customItemsDiscountValueTotalBefore = customItemsDiscountValueTotal;
          customItemsDiscountValueTotalLive = customItemsDiscountValueTotal;
        }
        if(customItemsAfterDiscountAmount)
        {
          setCustomAfterDiscountAmount(customItemsAfterDiscountAmount);
          customItemsAfterDiscountAmountBefore = customItemsAfterDiscountAmount;
          customItemsAfterDiscountAmountLive = customItemsAfterDiscountAmount;
        }
        if(customItemsTaxable)
        {
          setCustomTaxable(customItemsTaxable);
          customItemsTaxableBefore = customItemsTaxable;
          customItemsTaxableLive = customItemsTaxable;
        }
        if(customItemsTaxRate)
        {
          setCustomTaxRate(customItemsTaxRate);
          customItemsTaxRateBefore = customItemsTaxRate;
          customItemsTaxRateLive = customItemsTaxRate;
        }
        if(customItemsAfterTaxAmount)
        {
          setCustomAfterTaxAmount(customItemsAfterTaxAmount);
          customItemsAfterTaxAmountBefore = customItemsAfterTaxAmount;
          customItemsAfterTaxAmountLive = customItemsAfterTaxAmount;
        }
        if(customItemsAdditionalDetails)
        {
          setCustomAdditionalDetails(customItemsAdditionalDetails);
          customItemsAdditionalDetailsBefore = customItemsAdditionalDetails;
          customItemsAdditionalDetailsLive = customItemsAdditionalDetails;
        }
        if(customItemsDateCreated)
        {
          setCustomDateCreated(customItemsDateCreated);
          customItemsDateCreatedBefore = customItemsDateCreated;
          customItemsDateCreatedLive = customItemsDateCreated;
        }
        setLoading(false)
      }








else{



  var invoiceItemListDocumentId = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_DOCUMENTID]
  var invoiceItemListDescription = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_DESCRIPTION]
  var invoiceItemListQuantity = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_QUANTITY]
  var invoiceItemListDiscountType = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTTYPE]
  var invoiceItemListDiscountAmount = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTAMOUNT]
  var invoiceItemListDiscountValueTotal = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_DISCOUNTVALUETOTAL]
  var invoiceItemListTaxable = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_TAXABLE]
  var invoiceItemListTaxRate = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_TAXRATE]
  var invoiceItemListUnitCost = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_UNITCOST]
  var invoiceItemListUnit = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_UNIT]
  var invoiceItemListSubTotal = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_SUBTOTAL]
  var invoiceItemListAfterDiscountAmount = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_AFTERDISCOUNTAMOUNT]
  var invoiceItemListAfterTaxAmount = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_AFTERTAXAMOUNT]
  var invoiceItemListAdditionalDetails = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_ADDITIONALDETAILS]
  var invoiceItemListDateCreated = doc.data()[database.COLUMN_NAME_INVOICE_ITEMLIST_DATECREATED]


        if(invoiceItemListDocumentId)
        {
          setCustomDocumentId(invoiceItemListDocumentId);
          customItemListDocumentIdBefore = invoiceItemListDocumentId;
          customItemListDocumentIdLive = invoiceItemListDocumentId;
        }
        if(invoiceItemListDescription)
        {
          setCustomDescription(invoiceItemListDescription);
          customItemsDescriptionBefore = invoiceItemListDescription;
          customItemsDescriptionLive = invoiceItemListDescription;
        }
        if(invoiceItemListUnitCost)
        {
          setCustomUnitCost(invoiceItemListUnitCost);
          customItemsUnitCostBefore = invoiceItemListUnitCost;
          customItemsUnitCostLive = invoiceItemListUnitCost;
        }
        if(invoiceItemListQuantity)
        {
          setCustomQuantity(invoiceItemListQuantity);
          customItemsQuantityBefore = invoiceItemListQuantity;
          customItemsQuantityLive = invoiceItemListQuantity;
        }
        if(invoiceItemListUnit)
        {
          setCustomUnit(invoiceItemListUnit);
          customItemsUnitBefore = invoiceItemListUnit;
          customItemsUnitLive = invoiceItemListUnit;
        }
        if(invoiceItemListSubTotal)
        {
          setCustomSubTotal(invoiceItemListSubTotal);
          customItemsSubTotalBefore = invoiceItemListSubTotal;
          customItemsSubTotalLive = invoiceItemListSubTotal;
        }
        if(invoiceItemListDiscountType)
        {
            setCustomDiscountType(invoiceItemListDiscountType);
            customItemsDiscountTypeBefore = invoiceItemListDiscountType;
            customItemsDiscountTypeLive = invoiceItemListDiscountType;
        }
        if(invoiceItemListDiscountAmount)
        {
            setCustomDiscountAmount(invoiceItemListDiscountAmount);
            customItemsDiscountAmountBefore = invoiceItemListDiscountAmount;
            customItemsDiscountAmountLive = invoiceItemListDiscountAmount;
        }
        if(invoiceItemListDiscountValueTotal)
        {
          setCustomDiscountValueTotal(invoiceItemListDiscountValueTotal);
          customItemsDiscountValueTotalBefore = invoiceItemListDiscountValueTotal;
          customItemsDiscountValueTotalLive = invoiceItemListDiscountValueTotal;
        }
        if(invoiceItemListAfterDiscountAmount)
        {
          setCustomAfterDiscountAmount(invoiceItemListAfterDiscountAmount);
          customItemsAfterDiscountAmountBefore = invoiceItemListAfterDiscountAmount;
          customItemsAfterDiscountAmountLive = invoiceItemListAfterDiscountAmount;
        }
        if(invoiceItemListTaxable)
        {
          setCustomTaxable(invoiceItemListTaxable);
          customItemsTaxableBefore = invoiceItemListTaxable;
          customItemsTaxableLive = invoiceItemListTaxable;
        }
        if(invoiceItemListTaxRate)
        {
          setCustomTaxRate(invoiceItemListTaxRate);
          customItemsTaxRateBefore = invoiceItemListTaxRate;
          customItemsTaxRateLive = invoiceItemListTaxRate;
        }
        if(invoiceItemListAfterTaxAmount)
        {
          setCustomAfterTaxAmount(invoiceItemListAfterTaxAmount);
          customItemsAfterTaxAmountBefore = invoiceItemListAfterTaxAmount;
          customItemsAfterTaxAmountLive = invoiceItemListAfterTaxAmount;
        }
        if(invoiceItemListAdditionalDetails)
        {
          setCustomAdditionalDetails(invoiceItemListAdditionalDetails);
          customItemsAdditionalDetailsBefore = invoiceItemListAdditionalDetails;
          customItemsAdditionalDetailsLive = invoiceItemListAdditionalDetails;
        }
        if(invoiceItemListDateCreated)
        {
          setCustomDateCreated(invoiceItemListDateCreated);
          customItemsDateCreatedBefore = invoiceItemListDateCreated;
          customItemsDateCreatedLive = invoiceItemListDateCreated;
        }
        setLoading(false)
      }


    } else {
      // setSnackBarError("No such data")
      props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)
      setLoading(false)
    }
}).catch((error) => {
  props.startSnackBar(true, 20000,CONST_ERROR.PROBLEM_GETTING_DATA+" "+error.code,"error",true)
  setLoading(false)
});

}



// const fetchBusinessData=async()=>{

//   return businessRef.get().then((doc) => {
//     if (doc.exists) {
   
//         if(doc.data().businessInfoEmail)
//         {
//           setEmail(doc.data().businessInfoEmail)
//         }
//     } else {
//       // setSnackBarError("No such data")
//       props.startSnackBar(true, 20000,"No such data","error",true)

//     }
// });
// }




useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleSaveData()
  }

}, [props.activateSave])


useEffect(async () => {
  // Update the document title using the browser API
  
  props.pageDisplayStatus((pageState) => ({...pageState, backButton:true, mainTab:false, invoiceEstimateTab:false,chosenMainTab:false, edPrHiTab: false}))


  setLoading(true)
  // props.setSaveState(true);


try{
  setError("")
  setQuery()
//   await fetchBusinessData();
  fetchData();
  props.setSaveState(false);
}
catch(error){
  props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)
  setLoading(false)
}

}, [])








  function checkForChanges(){

    if(
        (customItemListDocumentIdBefore!==customItemListDocumentIdLive) ||
        (customItemsDescriptionBefore!==customItemsDescriptionLive) ||
        (customItemsUnitCostBefore!==customItemsUnitCostLive) ||
        (customItemsQuantityBefore!==customItemsQuantityLive) ||
        (customItemsUnitBefore!==customItemsUnitLive) ||
        (customItemsSubTotalBefore!==customItemsSubTotalLive) ||
        (customItemsDiscountTypeBefore!==customItemsDiscountTypeLive) ||
        (customItemsDiscountAmountBefore!==customItemsDiscountAmountLive) ||
        (customItemsDiscountValueTotalBefore!==customItemsDiscountValueTotalLive) ||
        (customItemsAfterDiscountAmountBefore!==customItemsAfterDiscountAmountLive) ||
        (customItemsTaxableBefore!==customItemsTaxableLive) ||
        (customItemsTaxRateBefore!==customItemsTaxRateLive) ||
        (customItemsAfterTaxAmountBefore!==customItemsAfterTaxAmountLive) ||
        (customItemsAdditionalDetailsBefore!==customItemsAdditionalDetailsLive) ||
        (customItemsDateCreatedBefore!==customItemsDateCreatedLive)
        )
    {
      props.setSaveState(true);
    }
    else
    {
      props.setSaveState(false);
    }
  }


  const classes = useStyles();

  const [error, setError] = useState("")

  function handleSaveData(){
    // if(!CheckMaxLength(invoiceTagLive,5))
    // {
    //   setFieldError({
    //     invoiceTag: true,
    //     invoiceTagMsg: "Must be under 5 characters in length"
    //   })
    //   return
    // }
    // if(!CheckMaxLength(estimateTagLive,5))
    // {
    //   setFieldError({
    //     estimateTag: true,
    //     estimateTagMsg: "Must be under 5 characters in length"
    //   })
    //   return
    // }
    setQuery();
    writeUserData()
        setFieldError({
      invoiceTag: false,
      invoiceTagMsg: "",
      estimateTag: false,
      estimateTagMsg: ""
    })
  }


//   async function handleToggle(e) {
//     e.preventDefault();
//     var val = e.currentTarget.value;
//     if(val==="emailcopy")
//     {
//       if(notEmpty(email) || !e.currentTarget.checked)
//       {
//         processSwitchToggle(val);
//       }
//       else{
//         // Show Dialog Box Indicating Email not set
//         openDialog(true);
//       }
//     }
//     else
//     {
//       if(val==="autosetduedate")
//       {
//         if(e.currentTarget.checked)
//         {
//           setAutoSetDateVisible(true)
//         }
//         else
//         {
//           setAutoSetDateVisible(false)
//         }
//       }
//       processSwitchToggle(val);
//     }

//   };

//   function processSwitchToggle(value){
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//       newChecked.push(value);
//     } else {
//       newChecked.splice(currentIndex, 1);
//     }

//     setChecked(newChecked);
//     checkedLive = newChecked;
//     checkForChanges();
//   }

// async function handleSave(email) {
//   setOpen(false);
//   setLoading(true);

//   try{
//     // await updateProfileEmail(email)
//     // setEmail(email)
//     // processSwitchToggle("emailcopy")
//   }
//   catch(error){
//     // setSnackBarError("Failed to save data. "+error.code)
//     props.startSnackBar(true, 20000,"Failed to get data. "+error.code,"error",true)

//   }
//   setLoading(false);
// }




















  const updateText = (e)=> {



    switch(e.target.id) {
      case "itemId":
        setCustomDocumentId(e.target.value);
        customItemListDocumentIdLive = e.target.value;
      break;
      case "itemDescription":
        setCustomDescription(e.target.value);
        customItemsDescriptionLive = e.target.value;
        break;
      case "itemQuantity":
        setCustomQuantity(e.target.value);
        customItemsQuantityLive = e.target.value;
        break;
    case "itemDiscountType":
        setCustomDiscountType(e.target.value);
        customItemsDiscountTypeLive = e.target.value;
        break;
    case "itemDiscountAmount":
        setCustomDiscountAmount(e.target.value);
        customItemsDiscountAmountLive = e.target.value;
        break;
    case "itemDiscountValueTotal":
        setCustomDiscountValueTotal(e.target.value);
        customItemsDiscountValueTotalLive = e.target.value;
        break;
    case "itemTaxable":
        setCustomTaxable(e.target.value);
        customItemsTaxableLive = e.target.value;
        break;
    case "itemTaxRate":
        setCustomTaxRate(e.target.value);
        customItemsTaxRateLive = e.target.value;
        break;
    case "itemUnitCost":
        setCustomUnitCost(e.target.value);
        customItemsUnitCostLive = e.target.value;
        break;
    case "itemUnit":
        setCustomUnit(e.target.value);
        customItemsUnitLive = e.target.value;
        break;
    case "itemSubTotal":
        setCustomSubTotal(e.target.value);
        customItemsSubTotalLive = e.target.value;
        break;
    case "itemDiscountAmount":
        setCustomDiscountAmount(e.target.value);
        customItemsAfterDiscountAmountLive = e.target.value;
        break;
    case "itemTaxAmount":
        setCustomAfterTaxAmount(e.target.value);
        customItemsAfterTaxAmountLive = e.target.value;
        break;
    case "itemAdditionalDetails":
        setCustomAdditionalDetails(e.target.value);
        customItemsAdditionalDetailsLive = e.target.value;
        break;
    case "itemDateCreated":
        setCustomDateCreated(e.target.value);
        customItemsDateCreatedLive = e.target.value;
        break;
    }

    checkForChanges();
  };





  const discountTypeOptions = [
    {
      value: 'None',
      label: 'None',
    },
    {
      value: 'Percentage',
      label: 'Percentage',
    },
    {
      value: 'Flat Amount',
      label: 'Flat Amount',
    },

  ];

  const [discountType, setDiscountType] = useState('None');

  const handleChange = (e) => {
    setCustomDiscountType(e.target.value);
    customItemsDiscountTypeLive = e.target.value;
    checkForChanges();
  };





  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElTwo, setAnchorElTwo] = useState(null);
  const [anchorElThree, setAnchorElThree] = useState(null);
  const [anchorElFour, setAnchorElFour] = useState(null);
  const [anchorElFive, setAnchorElFive] = useState(null);
  const [anchorElSix, setAnchorElSix] = useState(null);




  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickTwo = (event) => {
    setAnchorElTwo(anchorEl);
  };

  const handleClickThree = (event) => {
    setAnchorElThree(anchorEl);
  };

  const handleClickFour = (event) => {
    setAnchorElFour(anchorEl);
  };

  const handleClickFive = (event) => {
    setAnchorElFive(anchorEl);
  };

  const handleClickSix = (event) => {
    setAnchorElSix(anchorEl);
  };

  const handleClose = (e) => {

    if(e.target.id)
    {
      setCustomUnit(unitValues[parseInt(e.target.id)])
    }

  
    setAnchorEl(null);
    setAnchorElTwo(null);
    setAnchorElThree(null);
    setAnchorElFour(null);
    setAnchorElFive(null);
    setAnchorElSix(null);

  };


const unitValues = [
  "",
  [CONST_ITEM.UNITS.TIME.OPTIONS[0]],
  [CONST_ITEM.UNITS.TIME.OPTIONS[1]],
  [CONST_ITEM.UNITS.TIME.OPTIONS[2]],
  [CONST_ITEM.UNITS.LENGTH.OPTIONS[0]],
  [CONST_ITEM.UNITS.LENGTH.OPTIONS[1]],
  [CONST_ITEM.UNITS.LENGTH.OPTIONS[2]],
  [CONST_ITEM.UNITS.LENGTH.OPTIONS[3]],
  [CONST_ITEM.UNITS.AREA.OPTIONS[0]]+[CONST_ITEM.UNITS.AREA.SYMBOL[0]],
  [CONST_ITEM.UNITS.AREA.OPTIONS[1]]+[CONST_ITEM.UNITS.AREA.SYMBOL[1]],
  [CONST_ITEM.UNITS.AREA.OPTIONS[2]]+[CONST_ITEM.UNITS.AREA.SYMBOL[2]],
  [CONST_ITEM.UNITS.AREA.OPTIONS[3]]+[CONST_ITEM.UNITS.AREA.SYMBOL[3]],
  [CONST_ITEM.UNITS.VOLUME.OPTIONS[0]]+[CONST_ITEM.UNITS.VOLUME.SYMBOL[0]],
  [CONST_ITEM.UNITS.VOLUME.OPTIONS[1]]+[CONST_ITEM.UNITS.VOLUME.SYMBOL[1]],
  [CONST_ITEM.UNITS.VOLUME.OPTIONS[2]]+[CONST_ITEM.UNITS.VOLUME.SYMBOL[2]],
  [CONST_ITEM.UNITS.VOLUME.OPTIONS[3]]+[CONST_ITEM.UNITS.VOLUME.SYMBOL[3]],
  [CONST_ITEM.UNITS.WEIGHT.OPTIONS[0]],
  [CONST_ITEM.UNITS.WEIGHT.OPTIONS[1]],
  [CONST_ITEM.UNITS.WEIGHT.OPTIONS[2]],
  [CONST_ITEM.UNITS.WEIGHT.OPTIONS[3]],
]




  return (
    <>
    <GeneralViewContainer maxWidth="lg">

      <Paper className={classes.paper}>

      <Grid container spacing={1}>

<Grid item xs={12} >
        <Typography variant="h5" component="h5">{CONST_ITEM.TITLE}</Typography>
        </Grid>
        </Grid>




      <Grid container spacing={1}>
        <Grid item xs={12} >
        <Box borderTop={1} borderColor="primary.main">
        </Box>
        </Grid>

{/* 
        <Grid item xs={12}>
        <CustomTextField placeholder="Item Id" updateText={updateText} id="itemId" label="Item Id" value={customItemListDocumentId}  enterKeyDown={enterKeyDown} />
        </Grid> */}






        <Grid item xs={12} >
        {/* <TextField placeholder="Name" onChange={updateText} id="contactName" label="Name" value={customName}  onKeyDown={handleKeyDown} /> */}
        <CustomTextField fullWidth placeholder={CONST_ITEM.DESCRIPTION_PLACEHOLDER} updateText={updateText} id="itemDescription" label={CONST_ITEM.DESCRIPTION_LABEL} value={customItemsDescription}  enterKeyDown={enterKeyDown} />

        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.UNITCOST_PLACEHOLDER} updateText={updateText} id="itemUnitCost" label={CONST_ITEM.UNITCOST_LABEL} value={customItemsUnitCost}  enterKeyDown={enterKeyDown}/>
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.QUANTITY_PLACEHOLDER}  updateText={updateText} id="itemQuantity" label={CONST_ITEM.QUANTITY_LABEL} value={customItemsQuantity}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
      
        </Grid>


        <Button aria-controls="simple-menu" aria-haspopup="true" id="itemUnitButton" onClick={handleClick}>
        <CustomTextField placeholder={CONST_ITEM.UNIT_PLACEHOLDER} id="itemUnit" label={CONST_ITEM.UNIT_LABEL} value={customItemsUnit}  enterKeyDown={enterKeyDown} />
      </Button>


      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

      <MenuItem id="0" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
        {CONST_ITEM.UNITS.NONE.TITLE}
      </MenuItem>


       <MenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickTwo}>
       {CONST_ITEM.UNITS.TIME.TITLE}
      </MenuItem>

        <Menu
        id="menu-time"
        anchorEl={anchorElTwo}
        keepMounted
        open={Boolean(anchorElTwo)}
        onClose={handleClose}
      >
        <MenuItem id="1" onClick={handleClose}>{CONST_ITEM.UNITS.TIME.OPTIONS[0]}</MenuItem>
        <MenuItem id="2" onClick={handleClose}>{CONST_ITEM.UNITS.TIME.OPTIONS[1]}</MenuItem>
        <MenuItem id="3" onClick={handleClose}>{CONST_ITEM.UNITS.TIME.OPTIONS[2]}</MenuItem>
        </Menu>

        


        <MenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickThree}>
        {CONST_ITEM.UNITS.LENGTH.TITLE}
      </MenuItem>
        <Menu
        id="menu-length"
        anchorEl={anchorElThree}
        keepMounted
        open={Boolean(anchorElThree)}
        onClose={handleClose}
      >
        <MenuItem id="4" onClick={handleClose}>{CONST_ITEM.UNITS.LENGTH.OPTIONS[0]}</MenuItem>
        <MenuItem id="5" onClick={handleClose}>{CONST_ITEM.UNITS.LENGTH.OPTIONS[1]}</MenuItem>
        <MenuItem id="6" onClick={handleClose}>{CONST_ITEM.UNITS.LENGTH.OPTIONS[2]}</MenuItem>
        <MenuItem id="7" onClick={handleClose}>{CONST_ITEM.UNITS.LENGTH.OPTIONS[3]}</MenuItem>
        </Menu>


        <MenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickFour}>
        {CONST_ITEM.UNITS.AREA.TITLE}
      </MenuItem>
        <Menu
        id="menu-area"
        anchorEl={anchorElFour}
        keepMounted
        open={Boolean(anchorElFour)}
        onClose={handleClose}
      >
        <MenuItem id="8" value="why" onClick={handleClose}>{CONST_ITEM.UNITS.AREA.OPTIONS[0]+CONST_ITEM.UNITS.AREA.SYMBOL[0]}</MenuItem>
        <MenuItem id="9" onClick={handleClose}>{CONST_ITEM.UNITS.AREA.OPTIONS[1]+CONST_ITEM.UNITS.AREA.SYMBOL[1]}</MenuItem>
        <MenuItem id="10" onClick={handleClose}>{CONST_ITEM.UNITS.AREA.OPTIONS[2]+CONST_ITEM.UNITS.AREA.SYMBOL[2]}</MenuItem>
        <MenuItem id="11" onClick={handleClose}>{CONST_ITEM.UNITS.AREA.OPTIONS[3]+CONST_ITEM.UNITS.AREA.SYMBOL[3]}</MenuItem>
        </Menu>


        
        <MenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickFive}>
        {CONST_ITEM.UNITS.VOLUME.TITLE}
      </MenuItem>
        <Menu
        id="menu-volume"
        anchorEl={anchorElFive}
        keepMounted
        open={Boolean(anchorElFive)}
        onClose={handleClose}
      >
        <MenuItem id="12" value="why" onClick={handleClose}>{CONST_ITEM.UNITS.VOLUME.OPTIONS[0]+CONST_ITEM.UNITS.VOLUME.SYMBOL[0]}</MenuItem>
        <MenuItem id="13" onClick={handleClose}>{CONST_ITEM.UNITS.VOLUME.OPTIONS[1]+CONST_ITEM.UNITS.VOLUME.SYMBOL[1]}</MenuItem>
        <MenuItem id="14" onClick={handleClose}>{CONST_ITEM.UNITS.VOLUME.OPTIONS[2]+CONST_ITEM.UNITS.VOLUME.SYMBOL[2]}</MenuItem>
        <MenuItem id="15" onClick={handleClose}>{CONST_ITEM.UNITS.VOLUME.OPTIONS[3]+CONST_ITEM.UNITS.VOLUME.SYMBOL[3]}</MenuItem>
        </Menu>


        <MenuItem aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickSix}>
        {CONST_ITEM.UNITS.WEIGHT.TITLE}
      </MenuItem>
        <Menu
        id="menu-volume"
        anchorEl={anchorElSix}
        keepMounted
        open={Boolean(anchorElSix)}
        onClose={handleClose}
      >
        <MenuItem id="16" onClick={handleClose}>{CONST_ITEM.UNITS.WEIGHT.OPTIONS[0]}</MenuItem>
        <MenuItem id="17" onClick={handleClose}>{CONST_ITEM.UNITS.WEIGHT.OPTIONS[1]}</MenuItem>
        <MenuItem id="18" onClick={handleClose}>{CONST_ITEM.UNITS.WEIGHT.OPTIONS[2]}</MenuItem>
        <MenuItem id="19" onClick={handleClose}>{CONST_ITEM.UNITS.WEIGHT.OPTIONS[3]}</MenuItem>
        </Menu>



      

      </Menu>


        <Grid item xs={12}>
        <CustomTextField size="small" variant="filled" placeholder={CONST_ITEM.SUBTOTAL_PLACEHOLDER} updateText={updateText} id="itemSubTotal" label={CONST_ITEM.SUBTOTAL_LABEL} value={customItemsSubTotal}  enterKeyDown={enterKeyDown}/>
        </Grid>


        <Grid item xs={12}>
        <TextField
          id="itemDiscountType"
          select
          label={CONST_ITEM.DISCOUNT_TYPE_LABEL}
          value={customItemsDiscountType}
          onChange={handleChange}
          helperText={CONST_ITEM.DISCOUNT_TYPE_HELPER_TEXT}
        >
          {discountTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Grid>


        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.DISCOUNT_AMOUNT_PLACEHOLDER} updateText={updateText} id="itemDiscountAmount" label={CONST_ITEM.DISCOUNT_AMOUNT_LABEL} value={customItemsDiscountAmount}  enterKeyDown={enterKeyDown} />
        </Grid>

        </Grid>


        <Grid container spacing={1}>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.DISCOUNT_VALUE_TOTAL_PLACEHOLDER} updateTextv={updateText} id="itemDiscountValueTotal" label={CONST_ITEM.DISCOUNT_VALUE_TOTAL_LABEL} value={customItemsDiscountValueTotal}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.AFTER_DISCOUNT_AMOUNT_PLACEHOLDER} updateText={updateText} id="itemAfterDiscountAmount" label={CONST_ITEM.AFTER_DISCOUNT_AMOUNT_LABEL} value={customItemsAfterDiscountAmount}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.TAXABLE_PLACEHOLDER} updateText={updateText} id="itemTaxable" label={CONST_ITEM.TAXABLE_LABEL} value={customItemsTaxable}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.TAX_RATE_PLACEHOLDER} updateText={updateText} id="itemTaxRate" label={CONST_ITEM.TAX_RATE_LABEL} value={customItemsTaxRate}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.AFTER_TAX_AMOUNT_PLACEHOLDER} updateText={updateText} id="itemAfterTaxAmount" label={CONST_ITEM.AFTER_TAX_AMOUNT_LABEL} value={customItemsAfterTaxAmount}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.ADDITIONAL_DETAILS_PLACEHOLDER} updateText={updateText} id="itemAdditionalDetails" label={CONST_ITEM.ADDITIONAL_DETAILS_LABEL}value={customItemsAdditionalDetails}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_ITEM.DATE_CREATED_PLACEHOLDER} updateText={updateText} id="itemDateCreated" label={CONST_ITEM.DATE_CREATED_LABEL} value={customItemsDateCreated}  enterKeyDown={enterKeyDown} />
        </Grid>



        </Grid>
        </Paper>
        </GeneralViewContainer>

    </>
  )
}
