import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';



const useStyles = makeStyles((theme) => ({
  fab: {
    transform: 'translateZ(0px)',
    position: 'absolute',
    bottom: '12%',
    right: theme.spacing(2),
  },

}));

export default function CustomAddFab() {
  const classes = useStyles();

  return (
    <div>
      <Fab color="primary" aria-label="add" className={classes.fab}>
        <Add />
      </Fab>
    </div>
  );
}
