import React, { Fragment, useEffect, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

function convertToEpoch(date){
  var epoch = 0;
  epoch = new Date(date).getTime()
  return epoch
}



export default function BasicDatePicker(props) {
  const [selectedDate, setDateChange] = useState(new Date());

  const handleDateChange = (val) => {
    var epoch = convertToEpoch(val)
    setDateChange(epoch)
    props.updateDate(props.id,epoch)
  };


  useEffect(async () => {
    setDateChange(props.date)
  }, [props.date])
  

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Fragment>
      <DatePicker
        disabled={props.disabled}
        id={props.id}
        label={props.title}
        value={selectedDate}
        onChange={handleDateChange}
        animateYearScrolling
        format="MMMM dd yyyy"
      />

    </Fragment>
    </MuiPickersUtilsProvider>
  );
}

