import React, { useState, useEffect } from "react"
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import CustomTextField from '../StandardComponents/CustomTextField';

import {CONST_ERROR, CONST_SUCCESS, CONST_SETTINGS, CONST_VALIDATE} from '../../constants/string'
import * as database from '../../constants/database'

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';

import CheckMaxLength from '../../validate/CheckMaxLength';


import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';

import { Typography } from "@material-ui/core";
import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  }

  
}));



function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}

var checkedBefore = [];
var checkedLive = [];

var daysFutureBefore = "";
var daysFutureLive = "";

var invoiceTagBefore = "";
var invoiceTagLive = "";

var estimateTagBefore = "";
var estimateTagLive = "";


export default function Settings(props) {



  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  console.log("USER"+currentUser.uid)

  var settingsRef = userRef.collection(database.TABLE_NAME_SETTINGS).doc("1");
  var businessRef = userRef.collection(database.TABLE_NAME_BUSINESS_INFO).doc("1");



    // // const [timer, setTimer] = useState(false);
    // const [custSnackBar, setSnackBar] = useState({
    //   show: false,
    //   time: 0,
    //   message: "",
    //   severity: "success"
    // })

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    
  const [autoSetDateVisible, setAutoSetDateVisible] = useState(false)

  var [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState([]);

  const [email, setEmail] = useState("");

  const [daysFuture, setDaysFuture] = useState("");


  const [invoiceTag, setInvoiceTag] = useState("");


  const [estimateTag, setEstimateTag] = useState("");


  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  async function writeUserData() {
    setLoading(true)
    return settingsRef.set({
      [database.COLUMN_NAME_SETTINGS_COPYEMAILS]: (checkedLive.includes('emailcopy')).toString(),
      [database.COLUMN_NAME_SETTINGS_LIVESEARCH]: (checkedLive.includes('livefieldsearch')).toString(),
      [database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATE] : (checkedLive.includes('autosetduedate')).toString(),
      [database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATEVALUE] : daysFutureLive,
      [database.COLUMN_NAME_SETTINGS_CUSTOMINVOICETAGVALUE] : invoiceTagLive,
      [database.COLUMN_NAME_SETTINGS_CUSTOMESTIMATETAGVALUE] : estimateTagLive,
    },{ merge: true })
    .then(() => {
      setLoading(false)
      setError("")

      
      props.setSaveState(false);

      // setSnackBar({show: true, time: 2000, message: "Saved", severit: "success"})
      props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)

      checkedBefore = checkedLive;
      daysFutureBefore = daysFutureLive;
      invoiceTagBefore = invoiceTagLive;
      estimateTagBefore = estimateTagLive;
  })
  .catch((error) => {
      // setSnackBarError()
      props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)

      setLoading(false)
  });
  }


  async function updateProfileEmail(email) {

    return businessRef.set({
      businessInfoEmail: email
    },{ merge: true });
  }

  const fetchData=async()=>{
  return settingsRef.get().then((doc) => {
    if (doc.exists) {
 
        const newChecked = [];



        var settingsCopyEmails = doc.data()[database.COLUMN_NAME_SETTINGS_COPYEMAILS]
        var settingsLiveSearch = doc.data()[database.COLUMN_NAME_SETTINGS_LIVESEARCH]
        var settingsAutoSetDate = doc.data()[database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATE] 
        var settingsAutoSetDateValue = doc.data()[database.COLUMN_NAME_SETTINGS_AUTOSETDUEDATEVALUE] 
        var settingsCustomInvoiceTagValue = doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMINVOICETAGVALUE] 
        var settingsCustomEstimateTagValue = doc.data()[database.COLUMN_NAME_SETTINGS_CUSTOMESTIMATETAGVALUE]


        if(settingsCopyEmails === "true")
        {
          newChecked.push('emailcopy');
        }
        if(settingsLiveSearch === "true")
        {
          newChecked.push('livefieldsearch');
        }
        if(settingsAutoSetDate === "true")
        {
          newChecked.push('autosetduedate');
        }
        if(settingsAutoSetDateValue)
        {
          setDaysFuture(settingsAutoSetDateValue);
          daysFutureBefore = settingsAutoSetDateValue;
          daysFutureLive = settingsAutoSetDateValue;
        }
        if(settingsCustomInvoiceTagValue)
        {
          setInvoiceTag(settingsCustomInvoiceTagValue);
          invoiceTagBefore = settingsCustomInvoiceTagValue;
          invoiceTagLive = settingsCustomInvoiceTagValue;
        }
        if(settingsCustomEstimateTagValue)
        {
          setEstimateTag(settingsCustomEstimateTagValue);
          estimateTagBefore = settingsCustomEstimateTagValue;
          estimateTagLive = settingsCustomEstimateTagValue;
        }

        setChecked(newChecked);
        checkedBefore = newChecked;
        checkedLive = newChecked;
    } else {
      // setSnackBarError("No such data")
      props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)

    }
}).catch((error) => {
  
  props.startSnackBar(true, 20000,CONST_ERROR.PROBLEM_GETTING_DATA+" "+error.code,"error",true)

});

}



const fetchBusinessData=async()=>{

  return businessRef.get().then((doc) => {
    if (doc.exists) {

      var businessInfoEmail = doc.data()[database.COLUMN_NAME_BUSINESS_INFO_EMAIL]

        if(businessInfoEmail)
        {
          setEmail(businessInfoEmail)
        }
    } else {
      // setSnackBarError("No such data")
      props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)

    }
});
}




useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleSaveData()
  }

}, [props.activateSave])


useEffect(async () => {
  // Update the document title using the browser API

  props.pageDisplayStatus((pageState) => ({...pageState, backButton:true, mainTab:false, invoiceEstimateTab:false, edPrHiTab: false}))



  setLoading(true)
  // props.setSaveState(true);
  
try{
  setError("")
  await fetchBusinessData();
  fetchData();
  props.setSaveState(false);
}
catch(error){
 console.log(error)

  // props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)
}
  setLoading(false)
}, [])

  function checkForChanges(){

    if((checkedLive.includes("emailcopy")!==checkedBefore.includes("emailcopy"))||(checkedLive.includes("livefieldsearch")!==checkedBefore.includes("livefieldsearch"))||(checkedLive.includes("autosetduedate")!==checkedBefore.includes("autosetduedate"))||(daysFutureLive!==daysFutureBefore)||(invoiceTagLive!==invoiceTagBefore)||(estimateTagLive!==estimateTagBefore))
    {
      props.setSaveState(true);
    }
    else
    {
      props.setSaveState(false);
    }
  }


  const classes = useStyles();

  const [error, setError] = useState("")

  function handleSaveData(){
    if(!CheckMaxLength(invoiceTagLive,5))
    {
      setFieldError({
        invoiceTag: true,
        invoiceTagMsg: CONST_VALIDATE.LONGER_THAN_FIVE_CHARACTERS
      })
      return
    }
    if(!CheckMaxLength(estimateTagLive,5))
    {
      setFieldError({
        estimateTag: true,
        estimateTagMsg: CONST_VALIDATE.LONGER_THAN_FIVE_CHARACTERS
      })
      return
    }
    writeUserData()
        setFieldError({
      invoiceTag: false,
      invoiceTagMsg: "",
      estimateTag: false,
      estimateTagMsg: ""
    })
  }


  async function handleToggle(e) {
    e.preventDefault();
    var val = e.currentTarget.value;
    if(val==="emailcopy")
    {
      if(notEmpty(email) || !e.currentTarget.checked)
      {
        processSwitchToggle(val);
      }
      else{
        // Show Dialog Box Indicating Email not set
        openDialog(true);
      }
    }
    else
    {
      if(val==="autosetduedate")
      {
        if(e.currentTarget.checked)
        {
          setAutoSetDateVisible(true)
        }
        else
        {
          setAutoSetDateVisible(false)
        }
      }
      processSwitchToggle(val);
    }

  };

  function processSwitchToggle(value){
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    checkedLive = newChecked;
    checkForChanges();
  }

async function handleSave(email) {
  setOpen(false);
  setLoading(true);

  try{
    await updateProfileEmail(email)
    setEmail(email)
    processSwitchToggle("emailcopy")
  }
  catch(error){
    // setSnackBarError("Failed to save data. "+error.code)
    props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)

  }
  setLoading(false);
}





function enterKeyDown(val){
  if (val && props.currentSaveState) {
    handleSaveData();
    }

}


  const updateText = (e)=> {

    console.log("HERE")

    switch(e.target.id) {
      case "daysFuture":
        setDaysFuture(e.target.value);
        daysFutureLive = e.target.value;

      break;
      case "invoiceTag":
        setInvoiceTag(e.target.value);
        invoiceTagLive = e.target.value;

        break;
      case "estimateTag":
        setEstimateTag(e.target.value);
        estimateTagLive = e.target.value;
        break;
    }

    checkForChanges();
  };


  return (
    <>

<GeneralViewContainer maxWidth="lg">
      <Grid container spacing={1}>

        <Grid item xs={12}>
        <Typography variant="h5" component="h5">Settings</Typography>
        </Grid>

        <Grid item xs={12}>
        <Box borderTop={1} borderColor="primary.main">
        </Box>
        </Grid>


      <Grid item xs={8} sm={6} md={4}>
      <Tooltip title={CONST_SETTINGS.RECEIVE_COPY_EMAILS_TOOLTIP}>
      <Typography>{CONST_SETTINGS.RECEIVE_COPY_EMAILS}</Typography>
      </Tooltip>
      <Collapse in={notEmpty(email) && checked.indexOf('emailcopy') !== -1}>
      <Typography variant="caption" display="block" gutterBottom>
        @ {email}
      </Typography>
      </Collapse>
        </Grid>
        <Grid item xs={4} sm={6} md={8}>

          <Switch
            disabled={notEmpty(error)}
            edge="end"
            value="emailcopy"
            onChange={handleToggle}
            checked={checked.indexOf('emailcopy') !== -1}
            inputProps={{ 'aria-labelledby': 'switch-list-label-emailcopy' }}
          />
        </Grid>

      <Grid item xs={8} sm={6} md={4}>
      <Tooltip title={CONST_SETTINGS.ENABLE_TEXT_SEARCH_TOOLTIP}>
      <Typography>{CONST_SETTINGS.ENABLE_TEXT_SEARCH}</Typography>
      </Tooltip>
        </Grid>
        <Grid item xs={4} sm={6} md={8}>
          <Switch
            disabled={notEmpty(error)}
            edge="end"
            value="livefieldsearch"
            onChange={handleToggle}
            checked={checked.indexOf('livefieldsearch') !== -1}
            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
          />
        </Grid>

    <Grid item xs={8} sm={6} md={4}>
    <Tooltip title={CONST_SETTINGS.AUTO_SET_DUE_DATE_TOOLTIP}>
    <Typography>{CONST_SETTINGS.AUTO_SET_DUE_DATE}</Typography>
    </Tooltip>
        </Grid>
        <Grid item xs={4} sm={6} md={8}>
          <Switch
            disabled={notEmpty(error)}
            edge="end"
            value="autosetduedate"
            onChange={handleToggle}
            checked={checked.indexOf('autosetduedate') !== -1}
            inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
          />
        </Grid>
        <Collapse in={checked.indexOf('autosetduedate') !== -1}>
        <Grid item xs={12}>
  <CustomTextField  disabled={notEmpty(error)} updateText={updateText} id="daysFuture" label="Days in future" value={daysFuture} enterKeyDown={enterKeyDown}/>
  </Grid>
  </Collapse>

      <Grid item xs={12}>
      <Tooltip title={CONST_SETTINGS.INVOICE_TAG_TOOLTIP}>
  <CustomTextField error={fieldError.invoiceTag} helperText={fieldError.invoiceTagMsg} disabled={notEmpty(error)} updateText={updateText}  id="invoiceTag" label={CONST_SETTINGS.INVOICE_TAG_LABEL} value={invoiceTag} enterKeyDown={enterKeyDown}/>
  </Tooltip>
  </Grid>

      <Grid item xs={12}>
      <Tooltip title={CONST_SETTINGS.ESTIMATE_TAG_TOOLTIP}>
  <CustomTextField error={fieldError.estimateTag}  helperText={fieldError.estimateTagMsg} disabled={notEmpty(error)} updateText={updateText} id="estimateTag" label={CONST_SETTINGS.ESTIMATE_TAG_LABEL} value={estimateTag} enterKeyDown={enterKeyDown}/>
  </Tooltip>

  </Grid>
      
    </Grid>
    </GeneralViewContainer>

{open &&
    <FormDialog handleCancel={closeDialog} handleSave={handleSave}/>
}
    </>
  )
}
