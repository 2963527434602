import React, { useState, useEffect } from "react"
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import useWindowDimensions from '../util/useWindowDimensions';
import FormatDate from '../util/FormatDate';
import FormatCurrencyCustom from '../util/FormatCurrencyCustom';
import valueEmpty from '../util/valueEmpty';
import returnZeroIfEmpty from '../util/returnZeroIfEmpty';
import ShowNoData from '../StandardComponents/ShowNoData';
import GeneralViewContainer from '../StandardComponents/GeneralViewContainer';



import { useHistory } from "react-router-dom"



import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';
import CheckMaxLength from '../../validate/CheckMaxLength';



import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';
import Edit from '@material-ui/icons/Edit';


import { Typography } from "@material-ui/core";
import * as database from '../../constants/database'


const useStyles = makeStyles((theme) => ({
  root: {
    spacing:2

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  },
  list_color: {
    backgroundColor: '#ffffff',
  }

  
}));





var itemId = [];
var itemDescription = [];
var itemAdditionalDetails = [];
var itemQuantity = [];
var itemUnitCost = [];
var itemSubTotal = [];




export default function Items(props) {

    const history = useHistory()

    const [itemRecords, updateItemRecords] = useState({
        itemCount: 0,
        itemId: [],
        itemDescription: [],
        itemAdditionalDetails: [],
        itemQuantity: [],
        itemUnitCost: [],
        itemSubTotal: [],

    });

    const { height, width } = useWindowDimensions();

    console.log("WINDOW HEIGHT: " +height);
    console.log("WINDOW width: " +width);



  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var invoiceRecordsRef = userRef.collection(database.TABLE_NAME_CUSTOM_ITEMS);
  var query = invoiceRecordsRef.orderBy("customItemsDateCreated", "asc").limit(200);

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    

  var [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };






  const fetchData=async()=>{
    setLoading(true)




    query.onSnapshot((querySnapshot) => {

      var counter = 0; 
      itemId = [];
      itemDescription = [];
      itemAdditionalDetails = [];
      itemQuantity = [];
      itemUnitCost = [];
      itemSubTotal = [];

    querySnapshot.forEach((doc) => {
        counter += 1;

        console.log(doc.data());

        itemId.push(valueEmpty(doc.data().customItemListDocumentId));
        itemDescription.push(valueEmpty(doc.data().customItemsDescription));
        itemAdditionalDetails.push(valueEmpty(doc.data().customItemsAdditionalDetails));
        itemQuantity.push(returnZeroIfEmpty(valueEmpty(doc.data().customItemsQuantity)));
        itemUnitCost.push(FormatCurrencyCustom(valueEmpty(doc.data().customItemsUnitCost)));
        itemSubTotal.push(FormatCurrencyCustom(valueEmpty(doc.data().customItemsSubTotal)));


    });


    updateItemRecords({
      itemCount: counter,
      itemId: itemId,
      itemDescription: itemDescription,
      itemAdditionalDetails: itemAdditionalDetails,
      itemQuantity: itemQuantity,
      itemUnitCost: itemUnitCost,
      itemSubTotal: itemSubTotal,
  });
  setLoading(false)

}, (error) => {
    props.startSnackBar(true, 20000,"Problem getting data. "+error.code,"error",true)
    setLoading(false)
});
}


useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleOnClick()
  }

}, [props.activateSave])


useEffect(async () => {
  // Update the document title using the browser API  // props.setSaveState(true);
  // props.pageDisplayStatus({backButton:false, mainTab:true, chosenMainTab:"items",invoiceEstimateTab:false,chosenInvoicesEstimatesTab:""})

  props.pageDisplayStatus((pageState) => ({...pageState, backButton:false, mainTab:true, chosenMainTab:"items",invoiceEstimateTab:false, edPrHiTab: false}))


  setError("")
  fetchData();

}, [])

  const classes = useStyles();

  const [error, setError] = useState("")

  function handleOnClick(e){
    e.preventDefault();
    var clickedId = e.currentTarget.id;
    if(clickedId)
    {
        localStorage.setItem('itId', clickedId);
        localStorage.setItem('itCu', "true");

        history.push("/dashboard/edititem")
    }
    else
    {
        props.startSnackBar(true, 20000,"Invalid Item Id","error",true)
    }
  }


  
  function renderRow(props) {
    const { index, style } = props;
  
    return (

        <Fade timeout={700} in={true}>
      <ListItem button style={style} key={index} id={itemId[index]} onClick={handleOnClick}> 
    {/* <Paper elevation={5} square={false}> */}
    <Box width="100%"  boxShadow={1} >

    <div style={{ padding: 5 }} className={classes.list_color}>
        
    <Grid container spacing={0}>
    
        <Grid item xs={8}>
        <ListItemText primary={itemDescription[index]} />
        </Grid>
        <Grid item xs={4}>
        <ListItemText primary={itemQuantity[index] + ' x ' +  itemUnitCost[index]} />
        </Grid>
            <Grid item xs={8}>
            <ListItemText secondary={itemAdditionalDetails[index]}/>
            </Grid>
            <Grid item xs={4}>
            <ListItemText secondary={itemSubTotal[index]}/>
            </Grid>
        </Grid>
        </div>
        </Box>
      </ListItem>
      </Fade>
    );
  }
  
  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };
  

  return (
    <>
      <GeneralViewContainer maxWidth="md" disableGutters={true}>

{(itemRecords.itemCount!=0) &&
   
        <FixedSizeList height={itemRecords.itemCount*80} width={'100%'} itemSize={80} itemCount={itemRecords.itemCount} >
          {renderRow}
        </FixedSizeList>
    
}

<ShowNoData height={height} chosenImage="EmojiObjects" in={itemRecords.itemCount==0}/>
             </GeneralViewContainer>

    </>
  )
}
