import React, { useState, useEffect } from "react"
// import CustomLoading from '../StandardComponents/CustomLoading';
// import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import FormatDate from '../util/FormatDate';
import FormatCurrencyCustom from '../util/FormatCurrencyCustom';
import AddComma from '../util/AddComma';
import { FixedSizeList } from 'react-window';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";
import TextOnlyOne from "../StandardComponents/TextOnlyOne";
import CheckStringNotZero from "../util/CheckStringNotZero";

import valueEmpty from '../util/valueEmpty';
import returnZeroIfEmpty from '../util/returnZeroIfEmpty';
import { useHistory } from "react-router-dom"




import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';
import CheckMaxLength from '../../validate/CheckMaxLength';

import Menu from '@material-ui/core/Menu';

import MenuItem from '@material-ui/core/MenuItem';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';


import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';

import { Typography } from "@material-ui/core";
import * as database from '../../constants/database'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
    paper: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },


  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  }

  
}));



function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}



var customItemListDocumentIdBefore = "";
var customItemsDescriptionBefore  = "";
var customItemsQuantityBefore  = "";
var customItemsDiscountTypeBefore  = "";
var customItemsDiscountAmountBefore  = "";
var customItemsDiscountValueTotalBefore  = "";
var customItemsTaxableBefore  = "";
var customItemsTaxRateBefore = "";
var customItemsUnitCostBefore = "";
var customItemsUnitBefore = "";
var customItemsSubTotalBefore = "";
var customItemsAfterDiscountAmountBefore = "";
var customItemsAfterTaxAmountBefore = "";
var customItemsAdditionalDetailsBefore = "";
var customItemsDateCreatedBefore = "";

var customItemListDocumentIdLive= "";
var customItemsDescriptionLive = "";
var customItemsQuantityLive = "";
var customItemsDiscountTypeLive = "";
var customItemsDiscountAmountLive = "";
var customItemsDiscountValueTotalLive = "";
var customItemsTaxableLive = "";
var customItemsTaxRateLive = "";
var customItemsUnitCostLive = "";
var customItemsUnitLive = "";
var customItemsSubTotalLive = "";
var customItemsAfterDiscountAmountLive = "";
var customItemsAfterTaxAmountLive = "";
var customItemsAdditionalDetailsLive = "";
var customItemsDateCreatedLive = "";


var itemId = [];
var itemDescription = [];
var itemAdditionalDetails = [];
var itemQuantity = [];
var itemUnitCost = [];
var itemSubTotal = [];





export default function EditDocument(props) {

  const history = useHistory()


  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var documentsRecordsRef = userRef.collection(database.TABLE_NAME_INVOICES);
  var listItemRef = userRef.collection(database.TABLE_NAME_INVOICE_ITEMLIST)


  var specificItemRef;
  var listItemQuery;

  function setQuery(){

    var docId = localStorage.getItem('doId');
    console.log("WILL NOW SEARCH ID: " +localStorage.getItem('itId'));
    if(!docId)
    {
       props.startSnackBar(true, 20000,"No Contact Provided","error",true)
        return;
    }
    specificItemRef = documentsRecordsRef.doc(docId);
    
    listItemQuery = listItemRef.where("invoiceItemListDocumentOwner","==", docId).orderBy("invoiceItemListDateCreated", "asc").limit(200);

  }

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    
  const [autoSetDateVisible, setAutoSetDateVisible] = useState(false)

  var [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState([]);


  const [itemRecords, updateItemRecords] = useState({
    itemCount: 0,
    itemId: [],
    itemDescription: [],
    itemAdditionalDetails: [],
    itemQuantity: [],
    itemUnitCost: [],
    itemSubTotal: [],

});

  const [invoiceFirstCreatedDate, setinvoiceFirstCreatedDate] = useState("");
  const [invoiceDocumentType, setinvoiceDocumentType] = useState("");
  const [invoicePoNumber, setinvoicePoNumber] = useState("");
  const [invoiceBusinessInfo, setinvoiceBusinessInfo] = useState("");
  const [invoiceClient, setinvoiceClient] = useState("");
  const [invoiceCreatedDate, setinvoiceCreatedDate] = useState("");
  const [invoiceDocumentId, setinvoiceDocumentId] = useState("");
  const [invoiceDueDate, setinvoiceDueDate] = useState("");
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [invoiceStatus, setinvoiceStatus] = useState("");
  const [invoiceTerms, setinvoiceTerms] = useState("");
  const [invoiceUserId, setinvoiceUserId] = useState("");
  const [invoiceTotal, setinvoiceTotal] = useState("");

  const [invoiceClientName, setinvoiceClientName] = useState("");
  const [invoiceClientEmail, setinvoiceClientEmail] = useState("");
  const [invoiceClientPhone, setinvoiceClientPhone] = useState("");
  const [invoiceClientMobile, setinvoiceClientMobile] = useState("");
  const [invoiceClientFax, setinvoiceClientFax] = useState("");
  const [invoiceClientContact, setinvoiceClientContact] = useState("");
  const [invoiceClientAddressOne, setinvoiceClientAddressOne] = useState("");
  const [invoiceClientAddressTwo, setinvoiceClientAddressTwo] = useState("");
  const [invoiceClientAddressThree, setinvoiceClientAddressThree] = useState("");

  const [invoiceItemsSubTotal, setinvoiceItemsSubTotal] = useState("");
  const [invoiceItemsTaxTotal, setinvoiceItemsTaxTotal] = useState("");

  const [invoiceDiscountType, setinvoiceDiscountType] = useState("");
  const [invoiceDiscountValue, setinvoiceDiscountValue] = useState("");
  const [invoiceDiscountValueTotal, setinvoiceDiscountValueTotal] = useState("");
  const [invoiceAfterDiscountTotal, setinvoiceAfterDiscountTotal] = useState("");

  const [invoiceTaxType, setinvoiceTaxType] = useState("");
  const [invoiceTaxLabel, setinvoiceTaxLabel] = useState("");
  const [invoiceTaxRate, setinvoiceTaxRate] = useState("");
  const [invoiceTaxTotal, setinvoiceTaxTotal] = useState("");

  const [invoicePaymentListTotal, setinvoicePaymentListTotal] = useState("");

  const [invoiceBalanceDue, setinvoiceBalanceDue] = useState("");

  const [invoicePaymentInstructionsEmail, setinvoicePaymentInstructionsEmail] = useState("");
  const [invoicePaymentInstructionsChequesPayable, setinvoicePaymentInstructionsChequesPayable] = useState("");
  const [invoicePaymentInstructionsBankTransfer, setinvoicePaymentInstructionsBankTransfer] = useState("");
  const [invoicePaymentInstructionsOther, setinvoicePaymentInstructionsOther] = useState("");

  const [invoiceSignatureFileName, setinvoiceSignatureFileName] = useState("");
  const [invoiceSignatureFilePath, setinvoiceSignatureFilePath] = useState("");

  const [invoiceSignatureDocumentOwner, setinvoiceSignatureDocumentOwner] = useState("");
  const [invoiceSignatureCreated, setinvoiceSignatureCreated] = useState("");

  const [invoiceOtherNotes, setinvoiceOtherNotes] = useState("");

  const [invoiceMarkAsPaid, setinvoiceMarkAsPaid] = useState("")

  // For Web Only
  const [invoiceMarkAsPaidText, setinvoiceMarkAsPaidText] = useState("");
  const [invoiceMarkAsPaidButton, setinvoiceMarkAsPaidButton] = useState("");
  const [invoicePaymentInstructionsText, setinvoicePaymentInstructionsText] = useState("");
  const [invoiceOtherNotesText, setinvoiceOtherNotesText] = useState("");
  const [invoiceSignatureCreatedText, setinvoiceSignatureCreatedText] = useState("");


  const [invoiceClientText, setinvoiceClientText] = useState("");





  // function enterKeyDown(val)
  // {
  //   if (val && props.currentSaveState) {
  //     handleSaveData();
  //     }
  // }


  
  

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  // async function writeUserData() {
  //   setLoading(true)
  //   return specificItemRef.set({
  //     customItemListDocumentId: customItemListDocumentId,
  //     customItemsDescription: customItemsDescription,
  //     customItemsQuantity: customItemsQuantity,
  //     customItemsDiscountType: customItemsDiscountType,
  //     customItemsDiscountAmount: customItemsDiscountAmount,
  //     customItemsDiscountValueTotal: customItemsDiscountValueTotal,
  //     customItemsTaxable: customItemsTaxable,
  //     customItemsTaxRate: customItemsTaxRate,
  //     customItemsUnitCost: customItemsUnitCost,
  //     customItemsUnit: customItemsUnit,
  //     customItemsSubTotal: customItemsSubTotal,
  //     customItemsAfterDiscountAmount: customItemsAfterDiscountAmount,
  //     customItemsAfterTaxAmount: customItemsAfterTaxAmount,
  //     customItemsAdditionalDetails:customItemsAdditionalDetails,
  //     customItemsDateCreated: customItemsDateCreated

  //   },{ merge: true })
  //   .then(() => {
  //     setLoading(false)
  //     setError("")
      
  //     props.setSaveState(false);
  //     // setSnackBar({show: true, time: 2000, message: "Saved", severit: "success"})
  //     props.startSnackBar(true, 2000,"Saved","success",true)

  //     customItemListDocumentIdBefore = customItemListDocumentIdLive;
  //     customItemsDescriptionBefore = customItemsDescriptionLive;
  //     customItemsQuantityBefore = customItemsQuantityLive;
  //     customItemsDiscountTypeBefore = customItemsDiscountTypeLive;
  //     customItemsDiscountAmountBefore = customItemsDiscountAmountLive;
  //     customItemsDiscountValueTotalBefore = customItemsDiscountValueTotalLive;
  //     customItemsTaxableBefore = customItemsTaxableLive;
  //     customItemsTaxRateBefore = customItemsTaxRateLive;
  //     customItemsUnitCostBefore = customItemsUnitCostLive;
  //     customItemsUnitBefore = customItemsUnitLive;
  //     customItemsSubTotalBefore = customItemsSubTotalLive;
  //     customItemsAfterDiscountAmountBefore = customItemsAfterDiscountAmountLive;
  //     customItemsAfterTaxAmountBefore = customItemsAfterTaxAmountLive;
  //     customItemsAdditionalDetailsBefore =customItemsAdditionalDetailsLive;
  //     customItemsDateCreatedBefore = customItemsDateCreatedLive;

  // })
  // .catch((error) => {
  //     // setSnackBarError()
  //     props.startSnackBar(true, 20000,"Failed to get data. "+error.code,"error",true)

  //     setLoading(false)
  // });
  // }






  function setInvoiceSection(doc){

    if(doc.data().invoiceFirstCreatedDate)
    {
      setinvoiceFirstCreatedDate(doc.data().invoiceFirstCreatedDate);
    }
    if(doc.data().invoiceDocumentType)
    {
      setinvoiceDocumentType(doc.data().invoiceDocumentType);
    }
    if(doc.data().invoicePoNumber)
    {
      setinvoicePoNumber(doc.data().invoicePoNumber);
    }

    if(doc.data().invoiceBusinessInfo)
    {
      setinvoiceBusinessInfo(doc.data().invoiceBusinessInfo);
    }
    if(doc.data().invoiceClient)
    {
      setinvoiceClient(doc.data().invoiceClient);
    }

    if(doc.data().invoiceCreatedDate)
    {
      setinvoiceCreatedDate(doc.data().invoiceCreatedDate);
    }

    if(doc.data().invoiceDocumentId)
    {
      setinvoiceDocumentId(doc.data().invoiceDocumentId);
    }
    if(doc.data().invoiceDueDate)
    {
      setinvoiceDueDate(doc.data().invoiceDueDate);
    }
    if(doc.data().invoiceNumber)
    {
      setinvoiceNumber(doc.data().invoiceNumber);
    }

    if(doc.data().invoiceStatus)
    {
      setinvoiceStatus(doc.data().invoiceStatus);
    }
    if(doc.data().invoiceTerms)
    {
      setinvoiceTerms(doc.data().invoiceTerms);
    }
    if(doc.data().invoiceUserId)
    {
      setinvoiceUserId(doc.data().invoiceUserId);
    }

    if(doc.data().invoiceTotal)
    {
      setinvoiceTotal(doc.data().invoiceTotal);
    }

  }


  function setClientSection(doc){


    if(doc.data().invoiceClientName)
    {
      setinvoiceClientName(doc.data().invoiceClientName);
      setinvoiceClientText("To:"+doc.data().invoiceClientName)
    }
    else{
      setinvoiceClientText("To:Client")
    }
    if(doc.data().invoiceClientEmail)
    {
      setinvoiceClientEmail(doc.data().invoiceClientEmail);
    }
    if(doc.data().invoiceClientPhone)
    {
      setinvoiceClientPhone(doc.data().invoiceClientPhone);
    }
    
    if(doc.data().invoiceClientMobile)
    {
      setinvoiceClientMobile(doc.data().invoiceClientMobile);
    }
    if(doc.data().invoiceClientFax)
    {
      setinvoiceClientFax(doc.data().invoiceClientFax);
    }
    if(doc.data().invoiceClientContact)
    {
      setinvoiceClientContact(doc.data().invoiceClientContact);
    }

    if(doc.data().invoiceClientAddressOne)
    {
      setinvoiceClientAddressOne(doc.data().invoiceClientAddressOne);
    }
    if(doc.data().invoiceClientAddressTwo)
    {
      setinvoiceClientAddressTwo(doc.data().invoiceClientAddressTwo);
    }
    if(doc.data().invoiceClientAddressThree)
    {
      setinvoiceClientAddressThree(doc.data().invoiceClientAddressThree);
    }
  }



  function setRemainingFields(doc){

    if(doc.data().invoiceItemsSubTotal)
    {
      setinvoiceItemsSubTotal(doc.data().invoiceItemsSubTotal);
    }
    if(doc.data().invoiceItemsTaxTotal)
    {
      setinvoiceItemsTaxTotal(doc.data().invoiceItemsTaxTotal);
    }
    if(doc.data().invoiceDiscountType)
    {
      setinvoiceDiscountType(doc.data().invoiceDiscountType);
    }

    if(doc.data().invoiceDiscountValue)
    {
      setinvoiceDiscountValue(doc.data().invoiceDiscountValue);
    }
    if(doc.data().invoiceDiscountValueTotal)
    {
      setinvoiceDiscountValueTotal(doc.data().invoiceDiscountValueTotal);
    }
    if(doc.data().invoiceAfterDiscountTotal)
    {
      setinvoiceAfterDiscountTotal(doc.data().invoiceAfterDiscountTotal);
    }

    
    if(doc.data().invoiceTaxType)
    {
      setinvoiceTaxType(doc.data().invoiceTaxType);
    }
    if(doc.data().invoiceTaxLabel)
    {
      setinvoiceTaxLabel(doc.data().invoiceTaxLabel);
    }
    if(doc.data().invoiceTaxRate)
    {
      setinvoiceTaxRate(doc.data().invoiceTaxRate);
    }

    if(doc.data().invoiceTaxTotal)
    {
      setinvoiceTaxTotal(doc.data().invoiceTaxTotal);
    }
    if(doc.data().invoicePaymentListTotal)
    {
      setinvoicePaymentListTotal(doc.data().invoicePaymentListTotal);
    }
    if(doc.data().invoiceBalanceDue)
    {
      setinvoiceBalanceDue(doc.data().invoiceBalanceDue);
    }

    var paymentText = "";
    if(doc.data().invoicePaymentInstructionsEmail)
    {
      setinvoicePaymentInstructionsEmail(doc.data().invoicePaymentInstructionsEmail);
      paymentText = AddComma(paymentText) + "Paypal email"
    }
    if(doc.data().invoicePaymentInstructionsChequesPayable)
    {
      setinvoicePaymentInstructionsChequesPayable(doc.data().invoicePaymentInstructionsChequesPayable);
      paymentText = AddComma(paymentText) + "Cheques Payable"
    }
    if(doc.data().invoicePaymentInstructionsBankTransfer)
    {
      setinvoicePaymentInstructionsBankTransfer(doc.data().invoicePaymentInstructionsBankTransfer);
      paymentText = AddComma(paymentText) + "Bank Transfer"

    }
    if(doc.data().invoicePaymentInstructionsOther)
    {
      setinvoicePaymentInstructionsOther(doc.data().invoicePaymentInstructionsOther);
      paymentText = AddComma(paymentText) + "Other"
    }

    if(paymentText)
    {
      setinvoicePaymentInstructionsText("Payments: "+paymentText)
    }
    else{
      setinvoicePaymentInstructionsText("Payment Instructions")
    }


    if(doc.data().invoiceSignatureFileName)
    {
      setinvoiceSignatureFileName(doc.data().invoiceSignatureFileName);
    }
    if(doc.data().invoiceSignatureFilePath)
    {
      setinvoiceSignatureFilePath(doc.data().invoiceSignatureFilePath);
    }

    if(doc.data().invoiceSignatureDocumentOwner)
    {
      setinvoiceSignatureDocumentOwner(doc.data().invoiceSignatureDocumentOwner);
    }
    if(doc.data().invoiceSignatureCreated)
    {
      setinvoiceSignatureCreated(doc.data().invoiceSignatureCreated);
    }
    if(doc.data().invoiceSignatureCreated && doc.data().invoiceSignatureFileName)
    {
      setinvoiceSignatureCreatedText("Signed On " +FormatDate(doc.data().invoiceSignatureCreated))
    }
    else{
      setinvoiceSignatureCreatedText("Signature")
    }

    if(doc.data().invoiceOtherNotes)
    {
      setinvoiceOtherNotes(doc.data().invoiceOtherNotes);
      setinvoiceOtherNotesText(doc.data().invoiceOtherNotes)
    }
    else{
      setinvoiceOtherNotesText("Notes")
    }

    if(doc.data().invoiceMarkAsPaid !== "")
    {
      setinvoiceMarkAsPaid(doc.data().invoiceMarkAsPaid);
      if(doc.data().invoiceMarkAsPaid === "true")
      {
        setinvoiceMarkAsPaidText("PAID")
        setinvoiceMarkAsPaidButton("Mark as Unpaid")

      }
      else{
        setinvoiceMarkAsPaidText("UNPAID")
        setinvoiceMarkAsPaidButton("Mark as Paid")

      }
    }

  }




  


  const fetchData=async()=>{
  return specificItemRef.get().then((doc) => {
    if (doc.exists) {

      console.log(doc.data())
 
      setInvoiceSection(doc)
      setClientSection(doc)
      setRemainingFields(doc)
      setLoading(false)

    } else {
      // setSnackBarError("No such data")
      props.startSnackBar(true, 20000,"No such data","error",true)
      setLoading(false)


    }
}).catch((error) => {
  props.startSnackBar(true, 20000,"Problem getting data. "+error.code,"error",true)
  setLoading(false)


});

}






const fetchListData=async()=>{

  listItemQuery.onSnapshot((querySnapshot) => {

    var counter = 0; 
    itemId = [];
    itemDescription = [];
    itemAdditionalDetails = [];
    itemQuantity = [];
    itemUnitCost = [];
    itemSubTotal = [];

  querySnapshot.forEach((doc) => {
      counter += 1;

      console.log("ITEM DATA: "+doc.data());

      itemId.push(valueEmpty(doc.data().invoiceItemListDocumentId));
      itemDescription.push(valueEmpty(doc.data().invoiceItemListDescription));
      itemAdditionalDetails.push(valueEmpty(doc.data().invoiceItemListAdditionalDetails));
      itemQuantity.push(returnZeroIfEmpty(valueEmpty(doc.data().invoiceItemListQuantity)));
      itemUnitCost.push(FormatCurrencyCustom(valueEmpty(doc.data().invoiceItemListUnitCost)));
      itemSubTotal.push(FormatCurrencyCustom(valueEmpty(doc.data().invoiceItemListSubTotal)));


  });


  updateItemRecords({
    itemCount: counter,
    itemId: itemId,
    itemDescription: itemDescription,
    itemAdditionalDetails: itemAdditionalDetails,
    itemQuantity: itemQuantity,
    itemUnitCost: itemUnitCost,
    itemSubTotal: itemSubTotal,
});

}, (error) => {
  props.startSnackBar(true, 20000,"Problem getting data. "+error.code,"error",true)
});
}



// const fetchBusinessData=async()=>{

//   return businessRef.get().then((doc) => {
//     if (doc.exists) {
   
//         if(doc.data().businessInfoEmail)
//         {
//           setEmail(doc.data().businessInfoEmail)
//         }
//     } else {
//       // setSnackBarError("No such data")
//       props.startSnackBar(true, 20000,"No such data","error",true)

//     }
// });
// }




useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


// useEffect(async () => {
  
//   if(props.activateSave)
//   {
//     handleSaveData()
//   }

// }, [props.activateSave])


// useEffect(async () => {
//   // Update the document title using the browser API  // props.setSaveState(true);


//   props.pageDisplayStatus((pageState) => ({...pageState, backButton:false, mainTab:true, chosenMainTab:"documents", invoiceEstimateTab:true}))


//   setError("")
//   fetchData();

// }, [])

useEffect(async () => {
  // Update the document title using the browser API
  setLoading(true)

  props.pageDisplayStatus((pageState) => ({...pageState, backButton:true, mainTab:false, invoiceEstimateTab:false, edPrHiTab: true}))


  // props.setSaveState(true);


try{
  setError("")
  setQuery()
//   await fetchBusinessData();
  fetchData();
  fetchListData();
  props.setSaveState(false);
}
catch(error){
  props.startSnackBar(true, 20000,"Failed to get data. "+error.code,"error",true)
  setLoading(false)
}
}, [])








  // function checkForChanges(){

  //   if(
  //       (customItemListDocumentIdBefore!==customItemListDocumentIdLive) ||
  //       (customItemsDescriptionBefore!==customItemsDescriptionLive) ||
  //       (customItemsUnitCostBefore!==customItemsUnitCostLive) ||
  //       (customItemsQuantityBefore!==customItemsQuantityLive) ||
  //       (customItemsUnitBefore!==customItemsUnitLive) ||
  //       (customItemsSubTotalBefore!==customItemsSubTotalLive) ||
  //       (customItemsDiscountTypeBefore!==customItemsDiscountTypeLive) ||
  //       (customItemsDiscountAmountBefore!==customItemsDiscountAmountLive) ||
  //       (customItemsDiscountValueTotalBefore!==customItemsDiscountValueTotalLive) ||
  //       (customItemsAfterDiscountAmountBefore!==customItemsAfterDiscountAmountLive) ||
  //       (customItemsTaxableBefore!==customItemsTaxableLive) ||
  //       (customItemsTaxRateBefore!==customItemsTaxRateLive) ||
  //       (customItemsAfterTaxAmountBefore!==customItemsAfterTaxAmountLive) ||
  //       (customItemsAdditionalDetailsBefore!==customItemsAdditionalDetailsLive) ||
  //       (customItemsDateCreatedBefore!==customItemsDateCreatedLive)
  //       )
  //   {
  //     props.setSaveState(true);
  //   }
  //   else
  //   {
  //     props.setSaveState(false);
  //   }
  // }


  const classes = useStyles();

  const [error, setError] = useState("")

  // function handleSaveData(){
  
  //   setQuery();
  //   writeUserData()
  //       setFieldError({
  //     invoiceTag: false,
  //     invoiceTagMsg: "",
  //     estimateTag: false,
  //     estimateTagMsg: ""
  //   })
  // }

  const handleClick = (id) => {

    switch(id) {
      case "invoiceNumber":
        history.push("/dashboard/details")
        break;
      case "invoiceClientText":
        history.push("/dashboard/client")
        localStorage.setItem('ctCu', "false");
        break;
      case "invoiceDiscountValueTotal":
        history.push("/dashboard/discount")
        break;
      case "invoiceTaxTotal":
        history.push("/dashboard/tax")
        break;
      case "invoicePaymentInstructionsText":
        history.push("/dashboard/paymentinstructions")
        break;
      case "invoiceOtherNotesText":
        history.push("/dashboard/notes")
        break;
      case "invoiceSignatureCreatedText":
        history.push("/dashboard/signature")
        break;
      default:
        // code block
    }

  };

  const handleItemClick = (clickedId) => {


    if(clickedId)
    {
        localStorage.setItem('itId', clickedId);
        localStorage.setItem('itCu', "false");
        history.push("/dashboard/edititem")
    }
    else
    {
        props.startSnackBar(true, 20000,"Invalid Item Id","error",true)
    }

    console.log("Item"+clickedId)
  };





  function renderItemRow(props) {
    const { index, style } = props;
  
    return (
        <Fade timeout={700} in={true}>
      <ListItem button style={style} key={index} disableGutters={true} > 
<TextOnlyOne id={itemId[index]} passButtonId={handleItemClick} isButton={true} icon="Edit" text1={itemDescription[index]} text1Bold={true} text2Bold={true} text2={itemQuantity[index] + ' x ' +  itemUnitCost[index]} text3={itemAdditionalDetails[index]} text4={itemSubTotal[index]} elevation={3}></TextOnlyOne>
      </ListItem>
      </Fade>
    );
  }
  
  renderItemRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };




  return (
    <>
    <GeneralViewContainer maxWidth="sm">

      <Paper className={classes.paper}>

      <Grid container spacing={1}>
          <Grid item xs={12} >
          <Typography variant="h6" component="h5">Details</Typography>
          </Grid>
      </Grid>

      <Grid container spacing={1}>
     
        <Grid item xs={12} >
        <TextOnlyOne passButtonId={handleClick} id="invoiceNumber" isButton={true} text1={invoiceNumber} text2={invoiceMarkAsPaidText} icon2={invoiceMarkAsPaid === "true"} elevation={3}></TextOnlyOne>
        </Grid>


        <Grid item xs={12} >
        <TextOnlyOne passButtonId={handleClick} id="invoiceDueDate" isButton={false} text1="Quilts Etc." text2={FormatDate(invoiceDueDate)} elevation={1}></TextOnlyOne>
        </Grid>

      </Grid>


        </Paper>
        </GeneralViewContainer>


        <GeneralViewContainer maxWidth="sm">

        <Paper className={classes.paper}>

        <Grid container spacing={1}>
          <Grid item xs={12} >
          <Typography passButtonId={handleClick} id="invoiceDueDate" variant="h6" component="h5">Client</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1}>

        <Grid item xs={12} >
        <TextOnlyOne passButtonId={handleClick} id="invoiceClientText" isButton={true} text1={invoiceClientText} elevation={1}></TextOnlyOne>
        </Grid>
        </Grid>

        </Paper>
        </GeneralViewContainer>



        <GeneralViewContainer maxWidth="sm">

          <Paper className={classes.paper}>

          <Grid container spacing={1}>
          <Grid item xs={12} >
          <Typography variant="h6" component="h5">Items</Typography>
          </Grid>
          </Grid>

          <Grid container spacing={1}>

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="addItem" isButton={true} icon="Add" text1="Add Item" text2="0 x $0.00" elevation={3}></TextOnlyOne>
          </Grid>


  
{(itemRecords.itemCount!=0) &&       
        <Grid item xs={12} p={3}>
   <FixedSizeList height={itemRecords.itemCount*70} width={'100%'} itemSize={70} itemCount={itemRecords.itemCount} >
     {renderItemRow}
   </FixedSizeList>
   </Grid>
}


{/* } */}
          {/* <TextOnlyOne passButtonId={handleClick} id="invoiceDueDate" isButton={true} icon="Edit" text1="Down Pillow" text1Bold={true} text2Bold={true} text2="1 x $100.00" text3="My Description Here" text4="$100.00" elevation={1}></TextOnlyOne> */}

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="invoiceItemsSubTotal" isButton={false} text1="Subtotal" text2={FormatCurrencyCustom(invoiceItemsSubTotal)} elevation={1} backgroundGrey={true}></TextOnlyOne>
          </Grid>


          </Grid>

          </Paper>
          </GeneralViewContainer>



          <GeneralViewContainer maxWidth="sm">

          <Paper className={classes.paper}>

          <Grid container spacing={1}>
          <Grid item xs={12} >
          <Typography variant="h6" component="h5">Charges</Typography>
          </Grid>
          </Grid>

          <Grid container spacing={1}>

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="invoiceDiscountValueTotal" isButton={true} text1="Discount" text2={CheckStringNotZero(invoiceDiscountValueTotal)? "-"+FormatCurrencyCustom(invoiceDiscountValueTotal) : FormatCurrencyCustom(invoiceDiscountValueTotal)} elevation={1}></TextOnlyOne>
          </Grid>

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="invoiceTaxTotal" isButton={true} text1="Tax" text2={FormatCurrencyCustom(invoiceTaxTotal)} elevation={1}></TextOnlyOne>
          </Grid>

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="invoiceTotal" isButton={false} backgroundGrey={true} text1="Total" text2={FormatCurrencyCustom(invoiceTotal)} elevation={1}></TextOnlyOne>
          </Grid>

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="invoicePaymentListTotal" isButton={true} text1="Payments" text2={FormatCurrencyCustom(invoicePaymentListTotal)} elevation={1}></TextOnlyOne>
          </Grid>

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="invoiceBalanceDue" isButton={false} backgroundGrey={true} text1="Balance Due" text2={FormatCurrencyCustom(invoiceBalanceDue)} elevation={1}></TextOnlyOne>
          </Grid>

          </Grid>

          </Paper>
          </GeneralViewContainer>





          <GeneralViewContainer maxWidth="sm">

          <Paper className={classes.paper}>

          <Grid container spacing={1}>
          <Grid item xs={12} >
          <Typography variant="h6" component="h5">Attachments</Typography>
          </Grid>
          </Grid>

          <Grid container spacing={1}>

          <Grid item xs={12} >
          <TextOnlyOne passButtonId={handleClick} id="addPhoto" isButton={true} icon="Add" text1="Add Photo" elevation={3}></TextOnlyOne>
          </Grid>

          </Grid>

          </Paper>
          </GeneralViewContainer>




          <GeneralViewContainer maxWidth="sm">

        <Paper className={classes.paper}>

        <Grid container spacing={1}>
        <Grid item xs={12} >
        <Typography variant="h6" component="h5">Additional Details</Typography>
        </Grid>
        </Grid>

        <Grid container spacing={1}>

        <Grid item xs={12} >
        <TextOnlyOne passButtonId={handleClick} id="invoicePaymentInstructionsText" isButton={true} text1={invoicePaymentInstructionsText} elevation={1}></TextOnlyOne>
        </Grid>

        <Grid item xs={12} >
        <TextOnlyOne passButtonId={handleClick} id="invoiceSignatureCreatedText" isButton={true} text1={invoiceSignatureCreatedText} elevation={1}></TextOnlyOne>
        </Grid>

        <Grid item xs={12} >
        <TextOnlyOne passButtonId={handleClick} id="invoiceOtherNotesText" isButton={true} text1={invoiceOtherNotesText} elevation={1}></TextOnlyOne>
        </Grid>

        </Grid>

        </Paper>
        </GeneralViewContainer>




        <GeneralViewContainer maxWidth="sm">

        <Paper className={classes.paper}>

  
        <Grid container spacing={1}>

        <Grid item xs={12} >
        <TextOnlyOne passButtonId={handleClick} id="invoiceMarkAsPaidButton" isButton={true} textCenter={invoiceMarkAsPaidButton} elevation={0}></TextOnlyOne>
        </Grid>

        </Grid>

        </Paper>
        </GeneralViewContainer>

    </>
  )
}
