import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';


export default function CopyRight() {
    return (
      <Fade timeout={1000} in={true}>
        <Box mt={8}>
      <Typography variant="body2" color="textSecondary" align="center">
          InvoiceGo {' '}
        {new Date().getFullYear()}
      </Typography>
      </Box>
      </Fade>
    );
  }
  