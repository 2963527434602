
import { functions } from "../firebase"

export default async function checkUser(email) {


    var checkEmail = functions.httpsCallable('verifyEmailAccess');

   var result = await checkEmail({ fieldValueOne: email })

   return result.data.emailAllowed

  }