import React, { useState, useEffect } from "react"
import CustomTextField from '../../StandardComponents/CustomTextField';
import BasicDatePicker from '../../StandardComponents/BasicDatePicker';

import {CONST_ERROR, CONST_SUCCESS, CONST_DETAILS} from '../../../constants/string'
import * as database from '../../../constants/database'
import * as localstorage from '../../../constants/localstorage'


import { makeStyles } from '@material-ui/core/styles';
import { db } from "../../../firebase"
import { useAuth } from "../../../contexts/AuthContext"

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import { Typography } from "@material-ui/core";
import GeneralViewContainer from "../../StandardComponents/GeneralViewContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  }

  
}));



function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}



var invoiceNumberBefore = "";
var invoiceNumberLive = "";

var invoiceDateCreatedBefore = "";
var invoiceDateCreatedLive = "";

var invoiceTermsBefore = "";
var invoiceTermsLive = "";

var invoiceDueDateBefore = "";
var invoiceDueDateLive = "";

var invoicePONumberBefore = "";
var invoicePONumberLive = "";



export default function Details(props) {



  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var documentsRecordsRef = userRef.collection(database.TABLE_NAME_INVOICES);


  var specificItemRef;

  function setQuery(){

    var docId = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_DOCUMENTID);
    if(!docId)
    {
       props.startSnackBar(true, 20000,"No Contact Provided","error",true)
        return;
    }
    specificItemRef = documentsRecordsRef.doc(docId);
    
  }


  var [loading, setLoading] = useState(false)


  const [invoiceNumber, setInvoiceNumber] = useState("")

  const [invoiceDateCreated, setInvoiceDateCreated] = useState("")

  const [invoiceTerms, setInvoiceTerms] = useState("")

  const [invoiceDueDate, setInvoiceDueDate] = useState("")

  const [invoicePONumber, setInvoicePONumber] = useState("")


  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  async function writeUserData() {
    setLoading(true)
    return specificItemRef.set({
      [database.COLUMN_NAME_INVOICE_NUMBER]: invoiceNumber,
      [database.COLUMN_NAME_INVOICE_CREATEDDATE]: invoiceDateCreated,
      [database.COLUMN_NAME_INVOICE_TERMS]: invoiceTerms,
      [database.COLUMN_NAME_INVOICE_DUEDATE]: invoiceDueDate,
      [database.COLUMN_NAME_INVOICE_PONUMBER]: invoicePONumber,
    },{ merge: true })
    .then(() => {
      setLoading(false)
      setError("")

      
      props.setSaveState(false);

      props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)

      invoiceNumberBefore = invoiceNumberLive
      invoiceDateCreatedBefore = invoiceDateCreatedLive
      invoiceTermsBefore = invoiceTermsLive
      invoiceDueDateBefore = invoiceDueDateLive
      invoicePONumberBefore = invoicePONumberLive

  })
  .catch((error) => {
      props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)

      setLoading(false)
  });
  }


 
  const fetchData=async()=>{
  return specificItemRef.get().then((doc) => {
    if (doc.exists) {

        var invoiceNumber = doc.data()[database.COLUMN_NAME_INVOICE_NUMBER]
        var invoiceCreatedDate = doc.data()[database.COLUMN_NAME_INVOICE_CREATEDDATE]
        var invoiceTerms = doc.data()[database.COLUMN_NAME_INVOICE_TERMS]
        var invoiceDueDate = doc.data()[database.COLUMN_NAME_INVOICE_DUEDATE]
        var invoicePoNumber = doc.data()[database.COLUMN_NAME_INVOICE_PONUMBER]

 
        if(invoiceNumber)
        {
          setInvoiceNumber(invoiceNumber);
          invoiceNumberBefore = invoiceNumber;
          invoiceNumberLive = invoiceNumber;
        }
        if(invoiceCreatedDate)
        {
          setInvoiceDateCreated(invoiceCreatedDate);
          invoiceDateCreatedBefore = invoiceCreatedDate;
          invoiceDateCreatedLive = invoiceCreatedDate;
        }
        if(invoiceTerms)
        {
          setInvoiceTerms(invoiceTerms);
          invoiceTermsBefore = invoiceTerms;
          invoiceTermsLive = invoiceTerms;
        }
        if(invoiceDueDate)
        {
          setInvoiceDueDate(invoiceDueDate);
          invoiceDueDateBefore = invoiceDueDate;
          invoiceDueDateLive = invoiceDueDate;
        }
        if(invoicePoNumber)
        {
          setInvoicePONumber(invoicePoNumber);
          invoicePONumberBefore = invoicePoNumber;
          invoicePONumberLive = invoicePoNumber;
        }

       

    } else {
      props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)

    }
}).catch((error) => {
  props.startSnackBar(true, 20000,CONST_ERROR.PROBLEM_GETTING_DATA+" "+error.code,"error",true)

});

}



useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleSaveData()
  }

}, [props.activateSave])


useEffect(async () => {
  // Update the document title using the browser API
  props.pageDisplayStatus((pageState) => ({...pageState, backButton:true, mainTab:false, invoiceEstimateTab:false, edPrHiTab: false}))

  setLoading(true)
  
try{
  setError("")
  setQuery()
  fetchData();
  props.setSaveState(false);
}
catch(error){
  props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)
}
  setLoading(false)
}, [])

  function checkForChanges(){

    if((invoiceNumberLive!==invoiceNumberBefore)||
    (invoiceDateCreatedLive!==invoiceDateCreatedBefore)||
    (invoiceTermsLive!==invoiceTermsBefore)||
    (invoiceDueDateLive!==invoiceDueDateBefore)||
    (invoicePONumberLive!==invoicePONumberBefore))
    {
      props.setSaveState(true);
    }
    else
    {
      props.setSaveState(false);
    }
  }


  const classes = useStyles();

  const [error, setError] = useState("")

  function handleSaveData(){
 
    setQuery()
    writeUserData()
 
  }


function enterKeyDown(val){
  if (val && props.currentSaveState) {
    handleSaveData();
    }
}

  const updateText = (e)=> {

    switch(e.target.id) {
      case "invoiceNumber":
        setInvoiceNumber(e.target.value);
        invoiceNumberLive = e.target.value;
      break;
      case "invoiceTerms":
        setInvoiceTerms(e.target.value);
        invoiceTermsLive = e.target.value;
        break;
      case "invoicePONumber":
        setInvoicePONumber(e.target.value);
        invoicePONumberLive = e.target.value;
        break;
    }

    checkForChanges();
  };



  const updateDate = (id, epoch)=> {

    switch(id) {
      case "invoiceDueDate":
        setInvoiceDueDate(epoch);
        invoiceDueDateLive = epoch;
        break;
      case "invoiceDateCreated":
        setInvoiceDateCreated(epoch);
        invoiceDateCreatedLive = epoch;
        break;

    }

    checkForChanges();
  };


  return (
    <>

<GeneralViewContainer maxWidth="lg">
      <Grid container spacing={1}>

        <Grid item xs={12}>
        <Typography variant="h5" component="h5">{CONST_DETAILS.TITLE}</Typography>
        </Grid>

        <Grid item xs={12}>
        <Box borderTop={1} borderColor="primary.main">
        </Box>
        </Grid>


  <Grid item xs={12}>
      <Tooltip title={CONST_DETAILS.INVOICE_NUMBER_TITLE}>
  <CustomTextField disabled={notEmpty(error)} updateText={updateText}  id="invoiceNumber" label={CONST_DETAILS.INVOICE_NUMBER_LABEL} value={invoiceNumber} enterKeyDown={enterKeyDown}/>
  </Tooltip>
  </Grid>

  <Grid item xs={12}>
      <Tooltip title={CONST_DETAILS.CREATED_DATE_TITLE}>
  <BasicDatePicker disabled={notEmpty(error)} date={invoiceDateCreated} updateDate={updateDate} id="invoiceDateCreated"/>
  </Tooltip>
  </Grid>

  <Grid item xs={12}>
      <Tooltip title={CONST_DETAILS.TERMS_TITLE}>
  <CustomTextField disabled={notEmpty(error)} updateText={updateText}  id="invoiceTerms" label={CONST_DETAILS.TERMS_LABEL} value={invoiceTerms} enterKeyDown={enterKeyDown}/>
  </Tooltip>
  </Grid>

  <Grid item xs={12}>
      <Tooltip title={CONST_DETAILS.DUE_DATE_TITLE}>
  <BasicDatePicker disabled={notEmpty(error)} date={(!notEmpty(invoiceDueDate)) ? null : invoiceDueDate} updateDate={updateDate} id="invoiceDueDate"/>
  </Tooltip>
  </Grid>

      <Grid item xs={12}>
      <Tooltip title={CONST_DETAILS.PO_NUMBER_TITLE}>
  <CustomTextField disabled={notEmpty(error)} updateText={updateText} id="invoicePONumber" label={CONST_DETAILS.PO_NUMBER_LABEL} value={invoicePONumber} enterKeyDown={enterKeyDown}/>
  </Tooltip>

  </Grid>
      
    </Grid>
    </GeneralViewContainer>

    </>
  )
}
