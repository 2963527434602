import React, {useState} from 'react';
import clsx from 'clsx';
import { useAuth } from "../../contexts/AuthContext"
import { useHistory } from "react-router-dom"

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Business from '@material-ui/icons/Business';
import Settings from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import ListSubheader from '@material-ui/core/ListSubheader';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function CustomDrawer(props) {
  const classes = useStyles();
  const history = useHistory()
  const { currentUser, logout } = useAuth()
  const [error, setError] = useState("")
  const [open, setOpen] = useState(false);



  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  async function handleLogout() {
    setError("")
  
    try {
      await logout()
      history.push("/login")
    } catch {
      setError("Failed to log out")
    }
  }


  const handleClick = (e) => {
    e.preventDefault();
    console.log(e.currentTarget.id);

    switch(e.currentTarget.id) {
      case "1":
        history.push("/profile");
        break;
      case "2":
        history.push("/settings");
        break;
      case "33":
        history.push("/account");
        break;
      case "4":
        history.push("/support");
        break;
      default:
        // code block
    }
    // setComp(val);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
          <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>

        <ListItem id="0" button onClick={handleClick}>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <ListItemText primary="Main"  />
    </ListItem>
    <ListItem id="1" button onClick={handleClick}>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <ListItemText primary="Profile"  />
    </ListItem>
    <ListItem id="2" button onClick={handleClick}>
      <ListItemIcon>
        <Settings />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItem>
    <ListItem id="3" button onClick={handleClick}>
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      <ListItemText primary="Account" />
    </ListItem>
        </List>
        <Divider />
        <List>
        <div>
    <ListSubheader inset>Help</ListSubheader>
    <ListItem id="4" button onClick={handleClick}>
      <ListItemIcon>
        <ContactSupport />
      </ListItemIcon>
      <ListItemText primary="Support" />
    </ListItem>

  </div>

        </List>
        <Divider />
        <List>
        <div>
    <ListSubheader inset>Acccess</ListSubheader>
    <ListItem button onClick={handleLogout}>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText primary="Logout"  />
    </ListItem>

  </div>
        </List>
    </div>
  );

  return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
