import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ValidateEmail from '../../validate/ValidateEmail';


export default function FormDialog(props) {
  var email = "";


  const[emailError, setEmailError] = useState({error: false, message: ""});

  async function handleOnChange(e){
    email = e.currentTarget.value;
  }







  async function handleOnClick(e){
    e.preventDefault()
    var id = e.currentTarget.id;
    
    switch(id) {
      case "cancel":
        setEmailError({error: false, message: ""})
        props.handleCancel()
        break;
      case "save":
        if(ValidateEmail(email))
        {
          setEmailError({error: false, message: ""})
          props.handleSave(email)
        }
        else
        {
          setEmailError({error: true, message: "Invalid Please Try Again"})
        }
        break;
      default:
        // code block
    }

  }

  return (
    <div>
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Email Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the email address that duplicate emails will be sent to
          </DialogContentText>
          <TextField
            error={emailError.error}
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleOnChange}
            helperText={emailError.message}
          />
        </DialogContent>
        <DialogActions>
          <Button id="cancel" onClick={handleOnClick} color="primary">
            Cancel
          </Button>
          <Button id="save" onClick={handleOnClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
