import React, { useState, useEffect } from "react"
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import CustomTextField from '../StandardComponents/CustomTextField';
import GeneralViewContainer from "../StandardComponents/GeneralViewContainer";


import {CONST_ERROR, CONST_SUCCESS, CONST_CONTACT} from '../../constants/string'
import * as database from '../../constants/database'
import * as localstorage from '../../constants/localstorage'

import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';
import CheckMaxLength from '../../validate/CheckMaxLength';


import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';

import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  }

  
}));



function notEmpty(val){
  if(val)
  {
    return true;
  }
  return false;
}

var customContactsNameBefore = "";
var customContactsNameLive = "";

var customContactsEmailBefore = "";
var customContactsEmailLive = "";

var customContactsPhoneBefore = "";
var customContactsPhoneLive = "";

var customContactsMobileBefore = "";
var customContactsMobileLive = "";

var customContactsFaxBefore = "";
var customContactsFaxLive = "";

var customContactsContactBefore = "";
var customContactsContactLive = "";

var customContactsAddressOneBefore = "";
var customContactsAddressOneLive = "";

var customContactsAddressTwoBefore = "";
var customContactsAddressTwoLive = "";

var customContactsAddressThreeBefore = "";
var customContactsAddressThreeLive = "";




var contactId;
var docId;
var isCus;

export default function EditContact(props) {



  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var contactsRecordsRef = userRef.collection(database.TABLE_NAME_CUSTOM_CONTACTS);
  var contactsDocRecordsRef = userRef.collection(database.TABLE_NAME_INVOICES);

  var specificContactRef;

  function setQuery(){

    contactId = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_CONTACTID);
    isCus = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_CONTACTISCUSTOM);
    docId = localStorage.getItem(localstorage.CONST_LOCALSTORAGE_DOCUMENTID);

    if(!contactId)
    {
       props.startSnackBar(true, 20000,CONST_ERROR.NO_CONTACT_PROVIDED,"error",true)
        return;
    }


    if(isCus === "true")
    {
      specificContactRef = contactsRecordsRef.doc(contactId);
    }
    else
    {
      specificContactRef = contactsDocRecordsRef.doc(docId);      
    }

  }

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    
  const [autoSetDateVisible, setAutoSetDateVisible] = useState(false)

  var [loading, setLoading] = useState(false);

  const [checked, setChecked] = useState([]);

  const [email, setEmail] = useState("");


  const [customName, setCustomName] = useState("");
  const [customEmail, setCustomEmail] = useState("");
  const [customPhone, setCustomPhone] = useState("");
  const [customMobile, setCustomMobile] = useState("");
  const [customFax, setCustomFax] = useState("");
  const [customContact, setCustomContact] = useState("");
  const [customAddressOne, setCustomAddressOne] = useState("");
  const [customAddressTwo, setCustomAddressTwo] = useState("");
  const [customAddressThree, setCustomAddressThree] = useState("");




function clearAll(){

  customContactsNameLive = "";
  customContactsEmailLive = "";
  customContactsPhoneLive = "";
  customContactsMobileLive = "";
  customContactsFaxLive = "";
  customContactsContactLive = "";
  customContactsAddressOneLive = "";
  customContactsAddressTwoLive = "";
  customContactsAddressThreeLive = "";

}



  function enterKeyDown(val)
  {
    if (val && props.currentSaveState) {
      handleSaveData();
      }
  }


  
  

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  async function writeUserData() {


    
    if(isCus === "true")
    {
    setLoading(true)
    return specificContactRef.set({
      [database.COLUMN_NAME_CUSTOM_CONTACTS_NAME]: customContactsNameLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_EMAIL]: customContactsEmailLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_PHONE]: customContactsPhoneLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_MOBILE]: customContactsMobileLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_FAX]: customContactsFaxLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_CONTACT]: customContactsContactLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSONE]: customContactsAddressOneLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSTWO]: customContactsAddressTwoLive,
      [database.COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSTHREE]: customContactsAddressThreeLive,
    },{ merge: true })
    .then(() => {
      setLoading(false)
      setError("")
      
      props.setSaveState(false);
      // setSnackBar({show: true, time: 2000, message: "Saved", severit: "success"})
      props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)


customContactsNameBefore = customContactsNameLive;
customContactsEmailBefore = customContactsEmailLive;
customContactsPhoneBefore = customContactsPhoneLive;
customContactsMobileBefore = customContactsMobileLive;
customContactsFaxBefore = customContactsFaxLive;
customContactsContactBefore = customContactsContactLive;
customContactsAddressOneBefore = customContactsAddressOneLive;
customContactsAddressTwoBefore = customContactsAddressTwoLive;
customContactsAddressThreeBefore = customContactsAddressThreeLive;

  })
  .catch((error) => {
      // setSnackBarError()
      props.startSnackBar(true, 20000,"Failed to get data. "+error.code,"error",true)

      setLoading(false)
  });
}




else
{

  setLoading(true)
  return specificContactRef.set({
    [database.COLUMN_NAME_INVOICE_CLIENTNAME]: customContactsNameLive,
    [database.COLUMN_NAME_INVOICE_CLIENTEMAIL]: customContactsEmailLive,
    [database.COLUMN_NAME_INVOICE_CLIENTPHONE]: customContactsPhoneLive,
    [database.COLUMN_NAME_INVOICE_CLIENTMOBILE]: customContactsMobileLive,
    [database.COLUMN_NAME_INVOICE_CLIENTFAX]: customContactsFaxLive,
    [database.COLUMN_NAME_INVOICE_CLIENTCONTACT]: customContactsContactLive,
    [database.COLUMN_NAME_INVOICE_CLIENTADDRESSONE]: customContactsAddressOneLive,
    [database.COLUMN_NAME_INVOICE_CLIENTADDRESSTWO]: customContactsAddressTwoLive,
    [database.COLUMN_NAME_INVOICE_CLIENTADDRESSTHREE]: customContactsAddressThreeLive,
  },{ merge: true })
  .then(() => {
    setLoading(false)
    setError("")
    
    props.setSaveState(false);
    // setSnackBar({show: true, time: 2000, message: "Saved", severit: "success"})
    props.startSnackBar(true, 2000,CONST_SUCCESS.SAVED,"success",true)


customContactsNameBefore = customContactsNameLive;
customContactsEmailBefore = customContactsEmailLive;
customContactsPhoneBefore = customContactsPhoneLive;
customContactsMobileBefore = customContactsMobileLive;
customContactsFaxBefore = customContactsFaxLive;
customContactsContactBefore = customContactsContactLive;
customContactsAddressOneBefore = customContactsAddressOneLive;
customContactsAddressTwoBefore = customContactsAddressTwoLive;
customContactsAddressThreeBefore = customContactsAddressThreeLive;

})
.catch((error) => {
    // setSnackBarError()
    props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)

    setLoading(false)
});





}
  }






  const fetchData=async()=>{

   
 
  


  return specificContactRef.get().then((doc) => {
    if (doc.exists) {
 
      if(isCus === "true")
      {

        var customContactsName = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_NAME]
        var customContactsEmail = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_EMAIL]
        var customContactsPhone = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_PHONE]
        var customContactsMobile = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_MOBILE]
        var customContactsFax = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_FAX]
        var customContactsContact = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_CONTACT]
        var customContactsAddressOne = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSONE]
        var customContactsAddressTwo = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSTWO]
        var customContactsAddressThree = doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_ADDRESSTHREE]


        if(customContactsName)
        {
          setCustomName(customContactsName);
          customContactsNameBefore = customContactsName;
          customContactsNameLive = customContactsName;
        }
        if(customContactsEmail)
        {
          setCustomEmail(customContactsEmail);
          customContactsEmailBefore = customContactsEmail;
          customContactsEmailLive = customContactsEmail;
        }
        if(customContactsPhone)
        {
          setCustomPhone(customContactsPhone);
          customContactsPhoneBefore = customContactsPhone;
          customContactsPhoneLive = customContactsPhone;
        }
        if(customContactsMobile)
        {
          setCustomMobile(customContactsMobile);
          customContactsMobileBefore = customContactsMobile;
          customContactsMobileLive = customContactsMobile;
        }
        if(customContactsFax)
        {
          setCustomFax(customContactsFax);
          customContactsFaxBefore = customContactsFax;
          customContactsFaxLive = customContactsFax;
        }
        if(customContactsContact)
        {
          setCustomContact(customContactsContact);
          customContactsContactBefore = customContactsContact;
          customContactsContactLive = customContactsContact;
        }
        if(customContactsAddressOne)
        {
            setCustomAddressOne(customContactsAddressOne);
          customContactsAddressOneBefore = customContactsAddressOne;
          customContactsAddressOneLive = customContactsAddressOne;
        }
        if(customContactsAddressTwo)
        {
            setCustomAddressTwo(customContactsAddressTwo);
          customContactsAddressTwoBefore = customContactsAddressTwo;
          customContactsAddressTwoLive = customContactsAddressTwo;
        }
        if(customContactsAddressThree)
        {
          setCustomAddressThree(customContactsAddressThree);
          customContactsAddressThreeBefore = customContactsAddressThree;
          customContactsAddressThreeLive = customContactsAddressThree;
        }
      }




      else{

        var invoiceClientName = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTNAME]
        var invoiceClientEmail = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTEMAIL]
        var invoiceClientPhone = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTPHONE]
        var invoiceClientMobile = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTMOBILE]
        var invoiceClientFax = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTFAX]
        var invoiceClientContact = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTCONTACT]
        var invoiceClientAddressOne = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTADDRESSONE]
        var invoiceClientAddressTwo = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTADDRESSTWO]
        var invoiceClientAddressThree = doc.data()[database.COLUMN_NAME_INVOICE_CLIENTADDRESSTHREE]


        if(invoiceClientName)
        {
          setCustomName(invoiceClientName);
          customContactsNameBefore = invoiceClientName;
          customContactsNameLive = invoiceClientName;
        }
        if(invoiceClientEmail)
        {
          setCustomEmail(invoiceClientEmail);
          customContactsEmailBefore = invoiceClientEmail;
          customContactsEmailLive = invoiceClientEmail;
        }
        if(invoiceClientPhone)
        {
          setCustomPhone(invoiceClientPhone);
          customContactsPhoneBefore = invoiceClientPhone;
          customContactsPhoneLive = invoiceClientPhone;
        }
        if(invoiceClientMobile)
        {
          setCustomMobile(invoiceClientMobile);
          customContactsMobileBefore = invoiceClientMobile;
          customContactsMobileLive = invoiceClientMobile;
        }
        if(invoiceClientFax)
        {
          setCustomFax(invoiceClientFax);
          customContactsFaxBefore = invoiceClientFax;
          customContactsFaxLive = invoiceClientFax;
        }
        if(invoiceClientContact)
        {
          setCustomContact(invoiceClientContact);
          customContactsContactBefore = invoiceClientContact;
          customContactsContactLive = invoiceClientContact;
        }
        if(invoiceClientAddressOne)
        {
            setCustomAddressOne(invoiceClientAddressOne);
          customContactsAddressOneBefore = invoiceClientAddressOne;
          customContactsAddressOneLive = invoiceClientAddressOne;
        }
        if(invoiceClientAddressTwo)
        {
            setCustomAddressTwo(invoiceClientAddressTwo);
          customContactsAddressTwoBefore = invoiceClientAddressTwo;
          customContactsAddressTwoLive = invoiceClientAddressTwo;
        }
        if(invoiceClientAddressThree)
        {
          setCustomAddressThree(invoiceClientAddressThree);
          customContactsAddressThreeBefore = invoiceClientAddressThree;
          customContactsAddressThreeLive = invoiceClientAddressThree;
        }






      }

    } else {
      // setSnackBarError("No such data")
      props.startSnackBar(true, 20000,CONST_ERROR.NO_SUCH_DATA,"error",true)

    }
}).catch((error) => {
  props.startSnackBar(true, 20000,CONST_ERROR.PROBLEM_GETTING_DATA+" "+error.code,"error",true)

});

}



// const fetchBusinessData=async()=>{

//   return businessRef.get().then((doc) => {
//     if (doc.exists) {
   
//         if(doc.data().businessInfoEmail)
//         {
//           setEmail(doc.data().businessInfoEmail)
//         }
//     } else {
//       // setSnackBarError("No such data")
//       props.startSnackBar(true, 20000,"No such data","error",true)

//     }
// });
// }




useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])


useEffect(async () => {
  
  if(props.activateSave)
  {
    handleSaveData()
  }

}, [props.activateSave])


useEffect(async () => {
  // Update the document title using the browser API

  props.pageDisplayStatus((pageState) => ({...pageState, backButton:true, mainTab:false, chosenMainTab:"contacts",invoiceEstimateTab:false, edPrHiTab: false}))


  setLoading(true)
  // props.setSaveState(true);


try{
  setError("")
  setQuery()
  clearAll()
//   await fetchBusinessData();
  fetchData();
  props.setSaveState(false);
}
catch(error){
  props.startSnackBar(true, 20000,CONST_ERROR.FAILED_TO_GET_DATA+" "+error.code,"error",true)
}
  setLoading(false)
}, [])





  function checkForChanges(){

    if(
        (customContactsNameBefore!==customContactsNameLive) ||
        (customContactsEmailBefore!==customContactsEmailLive) ||
        (customContactsPhoneBefore!==customContactsPhoneLive) ||
        (customContactsMobileBefore!==customContactsMobileLive) ||
        (customContactsFaxBefore!==customContactsFaxLive) ||
        (customContactsContactBefore!==customContactsContactLive) ||
        (customContactsAddressOneBefore!==customContactsAddressOneLive) ||
        (customContactsAddressTwoBefore!==customContactsAddressTwoLive) ||
        (customContactsAddressThreeBefore!==customContactsAddressThreeLive))
    {
      props.setSaveState(true);
    }
    else
    {
      props.setSaveState(false);
    }
  }


  const classes = useStyles();

  const [error, setError] = useState("")

  function handleSaveData(){
    // if(!CheckMaxLength(invoiceTagLive,5))
    // {
    //   setFieldError({
    //     invoiceTag: true,
    //     invoiceTagMsg: "Must be under 5 characters in length"
    //   })
    //   return
    // }
    // if(!CheckMaxLength(estimateTagLive,5))
    // {
    //   setFieldError({
    //     estimateTag: true,
    //     estimateTagMsg: "Must be under 5 characters in length"
    //   })
    //   return
    // }
    setQuery();
    writeUserData()
        setFieldError({
      invoiceTag: false,
      invoiceTagMsg: "",
      estimateTag: false,
      estimateTagMsg: ""
    })
  }


//   async function handleToggle(e) {
//     e.preventDefault();
//     var val = e.currentTarget.value;
//     if(val==="emailcopy")
//     {
//       if(notEmpty(email) || !e.currentTarget.checked)
//       {
//         processSwitchToggle(val);
//       }
//       else{
//         // Show Dialog Box Indicating Email not set
//         openDialog(true);
//       }
//     }
//     else
//     {
//       if(val==="autosetduedate")
//       {
//         if(e.currentTarget.checked)
//         {
//           setAutoSetDateVisible(true)
//         }
//         else
//         {
//           setAutoSetDateVisible(false)
//         }
//       }
//       processSwitchToggle(val);
//     }

//   };

//   function processSwitchToggle(value){
//     const currentIndex = checked.indexOf(value);
//     const newChecked = [...checked];

//     if (currentIndex === -1) {
//       newChecked.push(value);
//     } else {
//       newChecked.splice(currentIndex, 1);
//     }

//     setChecked(newChecked);
//     checkedLive = newChecked;
//     checkForChanges();
//   }

// async function handleSave(email) {
//   setOpen(false);
//   setLoading(true);

//   try{
//     // await updateProfileEmail(email)
//     // setEmail(email)
//     // processSwitchToggle("emailcopy")
//   }
//   catch(error){
//     // setSnackBarError("Failed to save data. "+error.code)
//     props.startSnackBar(true, 20000,"Failed to get data. "+error.code,"error",true)

//   }
//   setLoading(false);
// }



  const updateText = (e)=> {



    switch(e.target.id) {
      case "contactName":
        setCustomName(e.target.value);
        customContactsNameLive = e.target.value;
      break;
      case "contactEmail":
        setCustomEmail(e.target.value);
        customContactsEmailLive = e.target.value;
        break;
      case "contactPhone":
        setCustomPhone(e.target.value);
        customContactsPhoneLive = e.target.value;
        break;
    case "contactMobile":
        setCustomMobile(e.target.value);
        customContactsMobileLive = e.target.value;
        break;
    case "contactFax":
        setCustomFax(e.target.value);
        customContactsFaxLive = e.target.value;
        break;
    case "contactContact":
        setCustomContact(e.target.value);
        customContactsContactLive = e.target.value;
        break;
    case "contactAddressOne":
        setCustomAddressOne(e.target.value);
        customContactsAddressOneLive = e.target.value;
        break;
    case "contactAddressTwo":
        setCustomAddressTwo(e.target.value);
        customContactsAddressTwoLive = e.target.value;
        break;
    case "contactAddressThree":
        setCustomAddressThree(e.target.value);
        customContactsAddressThreeLive = e.target.value;
        break;
    }

    checkForChanges();
  };


  return (
    <>

    
        <GeneralViewContainer maxWidth="lg">

<Paper className={classes.paper}>


      <Grid container spacing={1}>

<Grid item xs={12} >
        <Typography variant="h5" component="h5">Contact</Typography>
        </Grid>
        </Grid>




      <Grid container spacing={1}>
        <Grid item xs={12} >
        <Box borderTop={1} borderColor="primary.main">
        </Box>
        </Grid>


        <Grid item xs={12} >
        {/* <TextField placeholder="Name" onChange={updateText} id="contactName" label="Name" value={customName}  onKeyDown={handleKeyDown} /> */}
        <CustomTextField placeholder={CONST_CONTACT.NAME_PLACEHOLDER} updateText={updateText} id="contactName" label={CONST_CONTACT.NAME_LABEL} value={customName}  enterKeyDown={enterKeyDown} />

        </Grid>

        
        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.EMAIL_PLACEHOLDER} updateText={updateText} id="contactEmail" label={CONST_CONTACT.EMAIL_LABEL}value={customEmail}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.PHONE_PLACEHOLDER} updateText={updateText} id="contactPhone" label={CONST_CONTACT.PHONE_LABEL} value={customPhone}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.MOBILE_PLACEHOLDER} updateText={updateText} id="contactMobile" label={CONST_CONTACT.MOBILE_LABEL} value={customMobile}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.FAX_PLACEHOLDER} updateText={updateText} id="contactFax" label={CONST_CONTACT.FAX_LABEL} value={customFax}  enterKeyDown={enterKeyDown} />
        </Grid>

        </Grid>


        <Grid container spacing={1}>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.CONTACT_PLACEHOLDER} updateText={updateText} id="contactContact" label={CONST_CONTACT.CONTACT_LABEL} value={customContact}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.ADDRESS_ONE_PLACEHOLDER}updateText={updateText} id="contactAddressOne" label={CONST_CONTACT.ADDRESS_ONE_LABEL} value={customAddressOne}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.ADDRESS_TWO_PLACEHOLDER} updateText={updateText} id="contactAddressTwo" label={CONST_CONTACT.ADDRESS_TWO_LABEL} value={customAddressTwo}  enterKeyDown={enterKeyDown} />
        </Grid>

        <Grid item xs={12}>
        <CustomTextField placeholder={CONST_CONTACT.ADDRESS_THREE_PLACEHOLDER} updateText={updateText} id="contactAddressThree" label={CONST_CONTACT.ADDRESS_THREE_LABEL} value={customAddressThree}  enterKeyDown={enterKeyDown} />
        </Grid>

        </Grid>
        </Paper>
        </GeneralViewContainer>

    </>
  )
}
