import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import SignatureCanvas from 'react-signature-canvas'

// import styles from './styles.cssm'

class SignatureApp extends Component {
  state = {trimmedDataURL: null}
  sigPad = {}
  clear = () => {
    this.sigPad.clear()
  }


  trim = () => {
    this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
      .toDataURL('image/png')})
  }
  render () {
    let {trimmedDataURL} = this.state
    return (
        <div>
        <Grid container spacing={1}>
        <Grid item xs={12}>
        <SignatureCanvas canvasProps={{}}
          ref={(ref) => { this.sigPad = ref }} />
           </Grid>
      <Grid item xs={6}>
      <Button id="save" onClick={this.clear} fullWidth variant="contained" color="primary">
        SAVE
      </Button>
      </Grid>
      <Grid item xs={6}>
      <Button id="clear" onClick={this.trim} fullWidth variant="contained" color="primary">
        CLEAR
      </Button>
      </Grid>
    </Grid>
    </div>
    )
  }
}

export default SignatureApp;