import toFloat from './toFloat';


export default function CheckStringNotZero(inputString){
    var floatVal;
  if(inputString===null)
  {
    inputString = "";
  }
floatVal = toFloat(inputString);
if(floatVal !== 0.0)
{
  return true;
}
else{
  return false;
}

}