import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Check from '@material-ui/icons/Check';
import ButtonBase from '@material-ui/core/ButtonBase';




const useStyles = makeStyles((theme) => ({
  fab: {
    transform: 'translateZ(0px)',
    position: 'absolute',
    bottom: '12%',
    right: theme.spacing(2),
  },
  center_vertical: {
    height: 35,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  grey_color: {
      backgroundColor: "#f2f2f2"
  },
  purple_color: {
    color: "#6d2eff"
  },
  button_base: {
      width: "100%",
      height: "100%",
      textAlign:"left",
      padding: 0,
  }


}));




export default function TextOnlyOne(props) {
  const classes = useStyles();


  

const handleClick = (e) => {
  e.preventDefault()
  props.passButtonId(e.currentTarget.id)
};


  return (
    
          <ButtonBase id={props.id} onClick={handleClick} disabled={!props.isButton} className={classes.button_base} pl={0}>
        <Container disableGutters={true} maxWidth="sm" >

              <Paper elevation={props.elevation} square={true} className={props.backgroundGrey && classes.grey_color}>
        <Box p={1} pt={2} pb={2} >
      
        {props.textCenter &&
         <Grid container spacing={1} >
        <Grid item xs={12} className={classes.center_vertical}>    
        <Typography variant="body2" align="center">
       {props.textCenter}
      </Typography>
      </Grid>
      </Grid>
}


{/* One row no Icon at front */}
{!props.icon && !props.icon2 && props.text2 &&
         <Grid container spacing={1}>
        <Grid item xs={7} sm={7} className={classes.center_vertical}>    
        <Typography variant="body2">
       {props.text1}
      </Typography>
      </Grid>
      <Grid item xs={5} sm={5} className={classes.center_vertical}>
  
      <Typography variant="body2">
       {props.text2}
      </Typography>
      </Grid>
      </Grid>
}


{/* One row no Icon at front and no second column*/}
{!props.icon && !props.icon2 && !props.text2 && !props.textCenter &&
         <Grid container spacing={1}>
        <Grid item xs={12} sm={12} className={classes.center_vertical}>    
        <Typography variant="body2">
       {props.text1}
      </Typography>
      </Grid>
 
      </Grid>
}



{/* One row Icon at second column */}
{!props.icon && props.icon2 &&
         <Grid container spacing={1}>
        <Grid item xs={7} sm={7} className={classes.center_vertical}>    
        <Typography variant="body2">
       {props.text1}
      </Typography>
      </Grid>

      <Grid item xs={1} sm={1} className={classes.center_vertical}>
<Check fontSize="small" className={classes.purple_color}/>

  </Grid>
      <Grid item xs={4} sm={4}>
  
      <Typography variant="body2">
       {props.text2}
      </Typography>
      </Grid>
      </Grid>
}

{/* One Row with icon at front */}
{props.icon && !props.text3 &&
                 <Grid container spacing={1}>
             <Grid item xs={1} sm={1} className={classes.center_vertical}> 
             {props.icon==="Add" &&
             <Add fontSize="small"/>}  
              {props.icon==="Edit" &&
             <Edit fontSize="small"/>}  
             </Grid>
        <Grid item xs={6} sm={6} className={classes.center_vertical}>    
        <Typography variant="body2">
       {props.text1}
      </Typography>
      </Grid>
      <Grid item xs={5} sm={5} className={classes.center_vertical}>
      <Typography variant="body2">
       {props.text2}
      </Typography>
      </Grid>
      </Grid>

}


{/* Two rows with icon at front */}
{props.icon && props.text3 &&
                 <Grid container spacing={1}>
             <Grid item xs={1} sm={1} className={classes.center_vertical}> 
             {props.icon==="Add" &&
             <Add fontSize="small" />}  
              {props.icon==="Edit" &&
             <Edit fontSize="small"/>}  
             </Grid>
        <Grid item xs={6} sm={6} className={classes.center_vertical}>    
        <Typography variant="body2">
            {props.text1Bold && <b>
       {props.text1}
       </b>}
       {!props.text1Bold && 
       props.text1
       }
      </Typography>
      <Typography variant="body2">
      {props.text3Bold && <b>
       {props.text3}
       </b>}
       {!props.text3Bold &&
       props.text3
       }
      </Typography>
      </Grid>
      <Grid item xs={5} sm={5} className={classes.center_vertical}>
      <Typography variant="body2">
      {props.text2Bold && <b>
       {props.text2}
       </b>}
       {!props.text2Bold && 
       props.text2
       }
      </Typography>
      <Typography variant="body2">
      {props.text4Bold && <b>
       {props.text4}
       </b>}
       {!props.text4Bold && 
       props.text4
       }
      </Typography>
      </Grid>
      </Grid>

}





      </Box>
  </Paper>
  </Container>
  </ButtonBase>

  
  );
}
