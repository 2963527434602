import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
paper: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
    // color: green[500]
  },


}));

export default function GeneralViewContainer(props){


    const classes = useStyles();


return (
    <>
  <Container {...props} className={classes.container}>
   
        {
        props.children
        }



</Container>
        </>
)

    }