export default function formatDate(epoch) {

  var month = new Array();
  month[0] = "Jan.";
  month[1] = "Feb.";
  month[2] = "Mar.";
  month[3] = "Apr.";
  month[4] = "May.";
  month[5] = "Jun.";
  month[6] = "Jul.";
  month[7] = "Aug.";
  month[8] = "Sep.";
  month[9] = "Oct.";
  month[10] = "Nov.";
  month[11] = "Dec.";

  var date = new Date(parseInt(epoch));
  var day = date.getDate();
  var selectedMonth = month[date.getMonth()];
  var year = date.getFullYear();

  if(day.length!==2)
  {
  day = ("0" + day).slice(-2);
  }

  year = date.getFullYear();

  return selectedMonth + " " + day + ", " + year;
}