import React, { useState, useEffect } from "react"
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import useWindowDimensions from '../util/useWindowDimensions';
import FormatDate from '../util/FormatDate';
import FormatCurrencyCustom from '../util/FormatCurrencyCustom';
import valueEmpty from '../util/valueEmpty';
import returnZeroIfEmpty from '../util/returnZeroIfEmpty';

import { useHistory } from "react-router-dom"
import ShowNoData from '../StandardComponents/ShowNoData';
import GeneralViewContainer from '../StandardComponents/GeneralViewContainer';


import {CONST_ERROR} from '../../constants/string'
import * as database from '../../constants/database'
import * as localstorage from '../../constants/localstorage'


import Switch from '@material-ui/core/Switch';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { db } from "../../firebase"
import { useAuth } from "../../contexts/AuthContext"
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';
import CheckMaxLength from '../../validate/CheckMaxLength';



import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import FormDialog from './Dialog';
import SaveIcon from '@material-ui/icons/Save';
import Edit from '@material-ui/icons/Edit';


import { Typography, withMobileDialog } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    spacing:2

  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  snack: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  progressbar: {
    position: 'absolute',
    zIndex : '1000',
    width: '10%',
    height: '100%',
  },
  list_color: {
    backgroundColor: '#ffffff',
  }

  
}));





var contactsId = [];
var contactsName = [];
var contactsPhone = [];
var contactsMobile = [];
var contactsEmail = [];

var contactsSecondRow = [];




export default function Contacts(props) {

    const history = useHistory()


    const [contactsRecord, updateContactsRecords] = useState({
        contactsCount: 0,
        contactsName: [],
        contactsId: [],
        contactsPhone: [],
        contactsMobile: [],
        contactsEmail: [],
        contactsSecondRow: []
    });

    const { height, width } = useWindowDimensions();

    console.log("WINDOW HEIGHT: " +height);
    console.log("WINDOW width: " +width);



  const { currentUser } = useAuth()

  var userRef = db.collection("users").doc(currentUser.uid);
  var contactsRecordsRef = userRef.collection(database.TABLE_NAME_CUSTOM_CONTACTS);
  var query = contactsRecordsRef.orderBy(database.COLUMN_NAME_CUSTOM_CONTACTS_DATECREATED, "asc").limit(200);

  var businessRef = userRef.collection(database.TABLE_NAME_BUSINESS_INFO).doc("1");

  const [fieldError, setFieldError] = useState({
    invoiceTag: false,
    invoiceTagMsg: "",
    estimateTag: false,
    estimateTagMsg: ""
  })
    

  var [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  function determineSecondRowText(phone, mobile, email){

    if(phone)
    {
        console.log("WOWZA===1");
        return phone;
    }    
    else if(mobile)
    {
        console.log("WOWZA===2");
        return mobile;
    }
    else if(email)
    {
        console.log("WOWZA===3");
        return email; 
    }
    else{
        console.log("WOWZA===4");
        return "";
    }
  }



  const fetchData=async()=>{
    setLoading(true)



  query.onSnapshot((querySnapshot) => {
    var counter = 0; 
    contactsId = [];
    contactsName = [];
    contactsPhone = [];
    contactsMobile = [];
    contactsEmail = [];
    
    contactsSecondRow = [];

    querySnapshot.forEach((doc) => {
        counter += 1;

        console.log(doc.data());

 

        contactsId.push(valueEmpty(doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_DOCUMENTID]));
        contactsName.push(valueEmpty(doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_NAME]));
        contactsPhone.push(valueEmpty(doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_PHONE]));
        contactsMobile.push(valueEmpty(doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_MOBILE]));
        contactsEmail.push(returnZeroIfEmpty(valueEmpty(doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_EMAIL])));

        var returned = determineSecondRowText(doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_PHONE], doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_MOBILE], doc.data()[database.COLUMN_NAME_CUSTOM_CONTACTS_EMAIL])
        contactsSecondRow.push(returned)     

    });


    console.log("COUNTER"+counter)

    updateContactsRecords({
        contactsCount: counter,
        contactsId: contactsId,
        contactsName: contactsName,
        contactsPhone: contactsPhone,
        contactsMobile: contactsMobile,
        contactsEmail: contactsEmail,
        contactsSecondRow: contactsSecondRow
    });
    setLoading(false)

}, (error) => {
    props.startSnackBar(true, 20000,CONST_ERROR.PROBLEM_GETTING_DATA+" "+error.code,"error",true)
    setLoading(false)
});
}


useEffect(async () => {
  props.handleLoadingState(loading)
}, [loading])




  



useEffect(async () => {
  // Update the document title using the browser API  // props.setSaveState(true);
  
  props.pageDisplayStatus((pageState) => ({...pageState, backButton:false, mainTab:true, chosenMainTab:"contacts",invoiceEstimateTab:false, edPrHiTab: false}))

  setError("")
  fetchData();

}, [])

  const classes = useStyles();

  const [error, setError] = useState("")

  function handleOnClick(e){
    e.preventDefault();
    var clickedId = e.currentTarget.id;
    if(clickedId)
    {
        localStorage.setItem(localstorage.CONST_LOCALSTORAGE_CONTACTID, clickedId);
        localStorage.setItem(localstorage.CONST_LOCALSTORAGE_CONTACTISCUSTOM, "true");
        history.push("/dashboard/editcontact")
    }
    else
    {
        props.startSnackBar(true, 20000,CONST_ERROR.INVALID_CONTACT_ID,"error",true)
    }
  }


  
  function renderRow(props) {
    const { index, style } = props;
  
    return (
        <Fade timeout={700} in={true}>
      <ListItem button style={style} key={index} id={contactsId[index]} onClick={handleOnClick}> 
    {/* <Paper elevation={5} square={false}> */}
    <Box width="100%"  boxShadow={1} >

    <div style={{ padding: 5 }} className={classes.list_color}>
        
    <Grid container spacing={0}>
    
        <Grid item xs={8}>
        <ListItemText primary={contactsName[index]} />
        </Grid>
        <Grid item xs={4}>
        <ListItemText primary={""} />
        </Grid>
            <Grid item xs={8}>
            <ListItemText secondary={contactsSecondRow[index]}/>
            </Grid>
            <Grid item xs={4}>
            <ListItemText secondary={""}/>
            </Grid>
        </Grid>
        </div>
        </Box>
      </ListItem>
      </Fade>
    );
  }
  
  renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };
  

  return (
    <>
          <GeneralViewContainer maxWidth="md" disableGutters={true}>

    {(contactsRecord.contactsCount!=0) &&

        <FixedSizeList height={contactsRecord.contactsCount*80} width={'100%'} itemSize={80} itemCount={contactsRecord.contactsCount} >
          {renderRow}
        </FixedSizeList>
}


<ShowNoData height={height} chosenImage="Contacts" in={contactsRecord.contactsCount==0}/>
</GeneralViewContainer>
    </>
  )
}
