import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';


import Folder from '@material-ui/icons/Folder';
import EmojiObjects from '@material-ui/icons/EmojiObjects';
import Contacts from '@material-ui/icons/Contacts';

import { Typography } from "@material-ui/core";




export default function ShowNoData(props){

  // const [chosenEmptyImage, setEmptyImage] = useState("")


  // useEffect(async () => {
  
  // if(props.switch)
  // {
  //   switch(expression) {
  //     case x:
  //       // code block
  //       break;
  //     case y:
  //       // code block
  //       break;
  //     default:
  //       // code block
  //   }
  //   else
  //   {

  //   }
  // }
  
  // }, [])




return (
<Fade timeout={1000} in={props.in}>
<div style={{ width: '100%' }}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignContent="flex-start"
        justifyContent="center"
        p={1}
        m={1}
        bgcolor="background.paper"
        css={{ width: '100%', height: props.height-220 }}
      >
 



    <Box justifyContent="center" css={{ height: props.height-220, display: "flex", flexDirection: "column" }} >

    <Box display="flex" justifyContent="center">
        <Box>
        {props.chosenImage=="EmojiObjects" && <EmojiObjects/>}
        {props.chosenImage=="Folder" && <Folder/>}
        {props.chosenImage=="Contacts" && <Contacts/>}
        </Box>
        </Box>
        <Box display="flex" justifyContent="center">
        <Box>
        <Typography variant="caption" display="block" gutterBottom>
          EMPTY
        </Typography>
        </Box>
        </Box>
        </Box>
      </Box>
          </div>
          </Fade>

)
}