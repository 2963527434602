import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore";
import "firebase/functions";

const emulator = process.env.REACT_APP_EMULATOR_STATUS;

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID
})

var authent = firebase.auth();
var dbemu = firebase.firestore();
var fbfunctions = firebase.functions();
if(emulator==="true")
{

authent.useEmulator("http://localhost:9099");
fbfunctions.useEmulator("localhost", 5001);
dbemu.useEmulator("localhost", 8080);

}


export const auth = authent;
export const db = dbemu;
export const functions = fbfunctions;
export default app
