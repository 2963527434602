import React, {useState, useEffect} from 'react';
import { useAuth } from "../../contexts/AuthContext"
import MainTab from "../StandardComponents/MainTab"
import InvoicesEstimatesTab from "../StandardComponents/InvoicesEstimatesTab"
import EditPreviewHistoryTab from "../StandardComponents/EditPreviewHistoryTab"
import CustomMainFab from '../StandardComponents/CustomMainFab';
import CustomAddFab from '../StandardComponents/CustomAddFab';
import purple from '@material-ui/core/colors/purple';

import Avatar from '@material-ui/core/Avatar';

import clsx from 'clsx';
import { makeStyles, fade } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import CustomSnackBar from '../StandardComponents/CustomSnackBar';
import CustomLoading from '../StandardComponents/CustomLoading';
import CustomDocumentSpeedDial from '../StandardComponents/CustomDocumentSpeedDial';

import InputBase from '@material-ui/core/InputBase';
import FilterList from '@material-ui/icons/FilterList';


import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom"
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Save from '@material-ui/icons/Save';
// import { MainListItems, secondaryListItems, thirdListItems } from './listItems';
import Chart from './Chart';
import Profile from './Profile';
import Deposits from './Deposits';
import Orders from './Orders';

import { AuthProvider } from "../../contexts/AuthContext"
import PrivateRoute from "../PrivateRoute"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import Slide from '@material-ui/core/Slide';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Business from '@material-ui/icons/Business';
import Settings from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import GeneralViewContainer from '../StandardComponents/GeneralViewContainer';
import CopyRight from '../StandardComponents/CopyRight';
import App from '../App';
import { Tab } from '@material-ui/core';




// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//         InvoiceGo
//       {' '}
//       {new Date().getFullYear()}
//     </Typography>
//   );
// }






const drawerWidth = 240;



// import React from 'react';
// import Button from '@material-ui/core/Button';







const useStyles = makeStyles((theme) => ({


  root: {
    display: 'flex',
  },
grow: {
flexGrow: 1,
},
menuButton: {
marginRight: theme.spacing(2),
},







title: {
  flexGrow: 1,
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
},
search: {
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: fade(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: fade(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
},
searchIcon: {
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
inputRoot: {
  color: 'inherit',
},
inputInput: {
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  transition: theme.transitions.create('width'),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '12ch',
    '&:focus': {
      width: '20ch',
    },
  },
},





  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarBottom: {
    top: 'auto',
    bottom: 0,
  },

  appBarTop: {
    top: 60,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  // title: {
  //   flexGrow: 1,
  // },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  paper: {
    // padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  purple: {
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF"
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  
 
}));


// const theme = createMuiTheme({

// });







export default function Dashboard(props) {
  const [width, setWindowSize] = useState(0);

  // const size = useWindowSize();


// Hook
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/


//   return width;
// }

  const [open, setOpen] = useState(false)
  const [chosenCompt, setComp] = useState(1)
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const [showMainTab, setMainTab] = useState(true)
  const [chosenMaintab, setChosenMainTab] = useState(0)
  const [chosenInvoicesEstimatesTab, setChosenInvoicesEstimatesTab] = useState(0)
  const [chosenEdPrHiTab,setChosenEdPrHiTab] = useState(0)

  const history = useHistory()




  
  
  async function handleLogout() {
    setError("")
  
    try {
      history.push("/login")
      await logout()
    } catch {
      setError("Failed to log out")
    }
  }


  
  useEffect(async () => {
    console.log("------------"+props.pageDisplayStatus.chosenMainTab)
    switch(props.pageDisplayStatus.chosenMainTab) {
      case "documents":
        setChosenMainTab(0)
        break;
      case "items":
        setChosenMainTab(1)
        break;
      case "contacts":
        setChosenMainTab(2)
        break;
    }
  
  }, [props.pageDisplayStatus.chosenMainTab])



  
// On change of the chosen Invoices Estimates Tab, update App.js
  useEffect(async () => {
    var myString = "";

    switch(chosenInvoicesEstimatesTab) {
      case 0:
        myString = "invoices"
        break;
      case 1:
        myString = "estimates"
        break;
    }

    console.log("GOES HERE")

    props.updatePageDisplayStatus((pageState) => ({...pageState, chosenInvoicesEstimatesTab:myString}))

  }, [chosenInvoicesEstimatesTab])


  // On change of the chosen Document Edit Tab, update App.js
  useEffect(async () => {
    var myString = "";

    switch(chosenInvoicesEstimatesTab) {
      case 0:
        myString = "edit"
        break;
      case 1:
        myString = "preview"
        break;
      case 2:
        myString = "history"
        break;
    }

    props.updatePageDisplayStatus((pageState) => ({...pageState, chosenEdPrHiTab:myString}))
      
  }, [chosenEdPrHiTab])



  // On Change from App.js, set local chosenInvoicesEstimatesTab State
  useEffect(async () => {
    switch(props.chosenInvoicesEstimatesTab) {
      case "edit":
        setChosenEdPrHiTab(0)
        break;
      case "preview":
        setChosenEdPrHiTab(1)
        break;
      case "history":
        setChosenEdPrHiTab(2)
        break;
    }

    console.log("IN DASHBOARD:"+props.chosenInvoicesEstimatesTab)
  
  }, [props.chosenEdPrHiTab])



  // On Change from App.js, set local chosenInvoicesEstimatesTab State
  useEffect(async () => {
    switch(props.chosenInvoicesEstimatesTab) {
      case "invoices":
        setChosenInvoicesEstimatesTab(0)
        break;
      case "estimates":
        setChosenInvoicesEstimatesTab(1)
        break;
    }

    console.log("IN DASHBOARD:"+props.chosenInvoicesEstimatesTab)
  
  }, [props.chosenInvoicesEstimatesTab])


  
  


  const [custSnackBar, setSnackBar] = useState({
    show: false,
    time: 0,
    message: "",
    severity: "success",
    bottomState: false
  })



  useEffect(async () => {
    // Update the document title using the browser API
 
      setSnackBar({show: props.snackBarShow, time: props.snackBarTime, message:props.snackBarMessage, severity:props.snackBarSeverity, bottomState: props.snackBarBottomTabState })
      console.log(props.snackBarBottomTabState )
      console.log("MSG"+props.snackBarMessage )

  
  }, [props.snackBarShow])






  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log(e.currentTarget.id);

    switch(e.currentTarget.id) {
      case "0":
        history.push("/dashboard/main");
        setMainTab(true);
        break;
      case "1":
        history.push("/dashboard/profile");
        setMainTab(false);
        break;
      case "2":
        history.push("/dashboard/settings");
        setMainTab(false);
        break;
      case "3":
        history.push("/dashboard/account");
        setMainTab(false);
        break;
      case "4":
        history.push("/dashboard/support");
        setMainTab(false);
        break;
      case "save-click":
        props.startSave()
        break;
      default:
        // code block
    }
    // setComp(val);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });





  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };




  function goBack(e){
    e.preventDefault();
    history.goBack()

  }





  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
  
          <div className={classes.toolbarIcon}>

          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />

      <Container>
        <Box display="flex" justifyContent="left" p={1} pt={2}>
        <Avatar variant="square" alt="Remy Sharp" src="https://cdn.logo.com/hotlink-ok/logo-social-sq.png" className={classes.large} />
        </Box>
        <Box display="flex" justifyContent="left" p={1}>
        </Box>

        </Container>
        <List>

        {/* <ListItem id="0"> */}
      {/* <ListItemIcon> */}
        {/* <Business /> */}
      {/* </ListItemIcon>
      <ListItemText primary="Main"  /> */}
    {/* </ListItem> */}
   
    <ListItem id="1" button onClick={handleClick}>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <ListItemText primary="Profile"  />
    </ListItem>
    <ListItem id="2" button onClick={handleClick}>
      <ListItemIcon>
        <Settings />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItem>
    <ListItem id="3" button onClick={handleClick}>
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      <ListItemText primary="Account" />
    </ListItem>
        </List>
        <Divider />
        <List>
        <div>
    <ListSubheader inset>Help</ListSubheader>
    <ListItem id="4" button onClick={handleClick}>
      <ListItemIcon>
        <ContactSupport />
      </ListItemIcon>
      <ListItemText primary="Support" />
    </ListItem>

  </div>

        </List>
        <Divider />
        <List>
        <div>
    <ListSubheader inset>Acccess</ListSubheader>
    <ListItem button onClick={handleLogout}>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText primary="Logout"  />
    </ListItem>
  </div>
        </List>
    </div>
  );






  return (

    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
        {!props.pageDisplayStatus.backButton &&
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer("left", true)}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
}

{props.pageDisplayStatus.backButton &&

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={goBack}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <ArrowBack />
          </IconButton>
}

          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>

            InvoiceGo
          </Typography>


          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <FilterList />
            </div>
            <InputBase
              placeholder="Filter.."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>


          <Fade in={props.currentSaveState}>
          <IconButton color="inherit" id="save-click"  onClick={handleClick} >
            <Badge color="secondary" variant="dot" invisible={false}>
              <Save />
            </Badge>
          </IconButton>
          </Fade>
        </Toolbar>
     
 
    <Box className={classes.purple}>
        <Fade timeout={10}in={props.pageDisplayStatus.invoiceEstimateTab} mountOnEnter unmountOnExit>

<AppBar position="relative" >

<InvoicesEstimatesTab chosenTab={chosenInvoicesEstimatesTab} setChosenInvoicesEstimatesTab={setChosenInvoicesEstimatesTab}/>

</AppBar>

</Fade> 
</Box>


<Box className={classes.purple}>
        <Fade timeout={10}in={props.pageDisplayStatus.edPrHiTab} mountOnEnter unmountOnExit>

<AppBar position="relative" >

<EditPreviewHistoryTab setChosenEdPrHiTab={setChosenEdPrHiTab}/>

</AppBar>

</Fade> 
</Box>

        {/* <InvoicesEstimatesTab/> */}
        {/* <EditPreviewHistoryTab/> */}

      </AppBar>




  

   


 
      <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
        

          <Drawer variant="temporary" anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>

         

        </React.Fragment>
      ))}
    </div>


    {/* <CustomDocumentSpeedDial/> */}
    {/* <CustomMainFab/> */}

    {/* <CustomAddFab /> */}



      <main className={classes.content}>
   


        <div className={classes.appBarSpacer} />


        {(props.pageDisplayStatus.invoiceEstimateTab || props.pageDisplayStatus.edPrHiTab) &&
        <div className={classes.appBarSpacer} />}




      {/* <Fade in={props.pageDisplayStatus.invoiceEstimateTab}> */}
      {/* <div className={classes.appBarSpacer} /> */}

   
        {/* </Fade> */}

        {/* <div className={classes.appBarSpacer} /> */}



     

        {/* <GeneralViewContainer maxWidth="md" includePaper={props.includePaper} disableGutters={true} className={classes.container}> */}
     

          {/* <Grid container>

            <Grid item xs={12}> */}
              {/* <Paper className={classes.paper}> */}
              {props.children}

              {/* </Paper> */}
            {/* </Grid>
          </Grid> */}
          {/* <Box pt={4}> */}
            <CopyRight />
          {/* </Box> */}


          
{/* <CustomLoading setLoading={loading}/>
<CustomSnackBar show={custSnackBar.show} time={custSnackBar.time} severity={custSnackBar.severity} message={custSnackBar.message} onStatusChange={handleFinishSnackBar}/> */}

<CustomLoading setLoading={props.loading}/>
<CustomSnackBar withTab={custSnackBar.bottomState} show={custSnackBar.show} time={custSnackBar.time} severity={custSnackBar.severity} message={custSnackBar.message} onStatusChange={props.snackBarFinished}/>      

      {/* Main Tab */}

   


          <Slide direction="up" in={props.pageDisplayStatus.mainTab} mountOnEnter unmountOnExit>
             <AppBar position="fixed" color="none" className={classes.appBarBottom}><MainTab chosenTab={chosenMaintab}/></AppBar>
        </Slide>

        {/* </GeneralViewContainer> */}

        {/* <div className={classes.appTabSpacer} /> */}

      </main>

   
    </div>
   

  );
}
