import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Link from '@material-ui/icons/Link';
import Email from '@material-ui/icons/Email';
import SaveAlt from '@material-ui/icons/SaveAlt';




const useStyles = makeStyles((theme) => ({
    root: {
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    exampleWrapper: {
      position: 'relative',
      marginTop: theme.spacing(3),
      height: 380,
    },
    radioGroup: {
      margin: theme.spacing(1, 0),
    },
    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        // bottom: theme.spacing(2),
        bottom: '12%',
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
    },
  }));




export default function CustomDocumentSpeedDial(){



      
  const actions = [
    { icon: <SaveAlt />, name: 'Save' },
    { icon: <Email />, name: 'Email' },
    { icon: <Link />, name: 'Link' },
    
   
  ];
  

    const classes = useStyles();
    const [direction, setDirection] = React.useState('up');
    const [open, setOpen] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
  
    const handleDirectionChange = (event) => {
      setDirection(event.target.value);
    };
  
    const handleHiddenChange = (event) => {
      setHidden(event.target.checked);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const toggleOpenClose = () => {
      if(open)
      {
        setOpen(false);
      }
      else{
        setOpen(true);
      }
    };
  
    const handleOpen = () => {
      setOpen(true);
    };


return(


<SpeedDial
ariaLabel="SpeedDial example"
className={classes.speedDial}
hidden={hidden}
icon={<SpeedDialIcon />}
onClick={toggleOpenClose}
open={open}
direction={direction}
>
{actions.map((action) => (
  <SpeedDialAction
    key={action.name}
    icon={action.icon}
    tooltipTitle={action.name}
    onClick={handleClose}
  />
))}
</SpeedDial>

)

}



