import React, {useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import AppBar from '@material-ui/core/AppBar';
import SwipeableViews from 'react-swipeable-views';
import { positions } from '@material-ui/system';


const useStyles = makeStyles({
  width: 500,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function InvoicesEstimatesTab(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("LOOK HERE"+newValue)
    props.setChosenInvoicesEstimatesTab(newValue)
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  useEffect(async () => {
    console.log("TAB CHOSEN"+ props.chosenTab)

    setValue(props.chosenTab)
   
  }, [props.chosenTab])


  return (
    <div className={classes.root}>
      

              {/* {props.children} */}

<Hidden smDown>
    <Paper elevation={0} className={classes.root}>
      <Tabs
        value={value}
        // variant="fullWidth"
        onChange={handleChange}
        centered
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab label="INVOICES"  />
        <Tab label="ESTIMATES"  />
      </Tabs>
      </Paper>
      </Hidden>


      <Hidden mdUp>
    <Paper elevation={0} className={classes.root}>
      <Tabs
        value={value}
        // variant="fullWidth"
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
         <Tab label="INVOICES"  />
        <Tab label="ESTIMATES"  />
      </Tabs>
      </Paper>
      </Hidden>
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          Item One
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
      </SwipeableViews> */}

    {/* </Paper>

    </Box> */}
    </div>
  );
}
